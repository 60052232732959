<link href="https://fonts.googleapis.com/css2?family=Muli:wght@200;300;400;500;700&display=swap" rel="stylesheet">
<button (click)="exportToPDF()">Export to PDF</button>
<button (click)="exportToExcel()">Export to Excel</button>
<button (click)="switchFormat('dashboard/transactions/accounting/sale-bill/tax-invoice3')">Change Format</button>
<div id="panel" class="invoice-box">
    <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb">
        <tbody>
            <!-- title -->
            <tr>
                <td height="20" colspan="4" align="center" class="txt">TAX INVOICE</td>
            </tr>
            <tr height="65">
                <!-- qr code starts -->
                <td align="start" class="txt" id="company_logo">
                    <img src="#" alt="company_logo">
                </td>
                <td align="start" class="txt cName">{{companyName}}<br><span class="tdData">{{companyAddress}}</span>
                </td>

                <table width="30%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                    <tbody>
                        <th width="100%" class="table_head">Original</th>
                        <tr>
                            <td width="50%"><strong><span class="tdData">PAN NO.</span></strong>
                            </td>
                            <td width="50%"><span class="tdData colon">{{editData.data.BillNo}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%"><strong><span class="tdData">GSTin No.</span></strong></td>
                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%"><strong><span class="tdData">State</span></strong>
                            </td>
                            <td width="50%"><span class="tdData colon">{{editData.data.paymentTrm}}</span></td>
                        </tr>
                        <tr>
                            <td width="50%"><strong><span class="tdData">State Code</span></strong>
                            </td>
                            <td width="50%"><span class="tdData colon">{{editData.data.place}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </tr>
            <tr>
                <!-- <td width="3%">&nbsp;</td> -->
                <td colspan="12">
                    <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
                        <tbody>
                            <!-- first table box starts -->
                            <tr class="outerTable bigLineHeight">
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">L.R. No</span></strong>
                                            </td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillNo}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Transport Name</span></strong></td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Date & Time</span></strong>
                                            </td>
                                            <td width="50%"><span
                                                    class="tdData colon">{{editData.data.paymentTrm}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Place of Supply</span></strong>
                                            </td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.place}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Payment Due</span></strong>
                                            </td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.place}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="40%"><strong><span class="tdData">Invoice No</span></strong></td>
                                            <td width="60%"><span class="tdData colon">{{editData.data.GST}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><strong><span class="tdData">Date</span></strong></td>
                                            <td width="60%"><span class="tdData colon">{{editData.data.state}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><strong><span class="tdData">P.O. No</span></strong></td>
                                            <td width="60%"><span
                                                    class="tdData colon">{{editData.data.stateCode}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="40%"><strong><span class="tdData">Date</span></strong></td>
                                            <td width="60%"><span class="tdData colon">{{editData.data.panNo}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- first table box ends -->
                            <!-- second table box starts -->
                            <tr class="outerTable">
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="100%"><span class="tdData">Details of Receiver(Billed To)</span></td>
                                        </tr>
                                        <tr>
                                            <td><span class="tdData">{{editData.data.company}}</span></td>
                                            <!-- <td><span class="tdData">S T ELECTRICAL PVT LTD</span></td> -->
                                        </tr>
                                        <tr>
                                            <!-- <td><span class="tdData">MIDC, BHOSARI, PUNE</span></td> -->
                                            <td><span class="tdData">{{editData.data.ADDRESS}}</span></td>
                                        </tr>
                                        <tr>
                                            <!-- <td width="50%"><strong><span class="tdData">State</span></strong>
                                            </td> -->
                                            <td width="50%"><span class="tdData">{{editData.data.state}}</span></td>
                                        </tr>
                                        <tr>
                                            <!-- <td width="50%"><strong><span class="tdData">State Code</span></strong>
                                            </td> -->
                                            <td width="50%"><span class="tdData">{{editData.data.place}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">GSTin No.</span></strong></td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">Vendor Code</span></strong></td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">PAN No.</span></strong></td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr>
                                            <td width="100%"><span class="tdData">Details of Consignee(Shipped To)</span></td>
                                        </tr>
                                        <tr>
                                            <td><span class="tdData">{{editData.data.company}}</span></td>
                                            <!-- <td><span class="tdData">S T ELECTRICAL PVT LTD</span></td> -->
                                        </tr>
                                        <tr>
                                            <!-- <td><span class="tdData">MIDC, BHOSARI, PUNE</span></td> -->
                                            <td><span class="tdData">{{editData.data.ADDRESS}}</span></td>
                                        </tr>
                                        <tr>
                                            <!-- <td width="50%"><strong><span class="tdData">State</span></strong>
                                            </td> -->
                                            <td width="50%"><span class="tdData">{{editData.data.state}}</span></td>
                                        </tr>
                                        <tr>
                                            <td width="50%"><strong><span class="tdData">GSTin No.</span></strong></td>
                                            <td width="50%"><span class="tdData colon">{{editData.data.BillDate}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- second table box ends -->
                            <tr>
                                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                                    <tbody>
                                        <tr class="tdData">
                                            <td width="3%" height="20" align="center" class="borderLeft borderBottom">
                                                <strong>NO.</strong>
                                            </td>
                                            <td width="30%" align="center" class="borderLeft borderBottom">
                                                <strong>Item</strong>
                                            </td>
                                            <td width="9%" align="center" class="borderLeft borderBottom">
                                                <strong>Package</strong>
                                            </td>
                                            <td width="9%" align="center" class="borderLeft borderBottom">
                                                <strong>HSN/SAC</strong>
                                            </td>
                                            <td width="8%" align="center" class="borderLeft borderBottom">
                                                <strong>GST%</strong>
                                            </td>
                                            <td width="10%" align="center" class="borderLeft borderBottom"><strong>Qty
                                                </strong></td>
                                            <td width="10%" align="center" class="borderLeft borderBottom"><strong>Unit
                                                </strong></td>
                                            <td width="15%" align="center" class="borderLeft borderBottom"><strong>Rate
                                                </strong></td>
                                            <td width="15%" align="center" class="borderLeft borderBottom">
                                                <strong>Amount</strong>
                                            </td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">1.</td>
                                            <td align="start" class="borderLeft">
                                                {{editData.data.INVData[0].Description}}</td>
                                                <td align="center" class="borderLeft">{{editData.data.INVData[0].package}}</td>
                                                <td align="center" class="borderLeft">{{editData.data.INVData[0].hsn}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].gst}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].ActualQty}}
                                            </td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Unit}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Rate}}</td>
                                            <td align="center" class="borderLeft">{{editData.data.INVData[0].Amount}}
                                            </td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                        <tr class="tdData">
                                            <td height="30" align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                            <td align="center" class="borderLeft">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>


                            <!-- third table box starts -->
                            <tr class="outerTable">
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr class="thirdTableData">
                                            <td><strong><span class="tdData">BILL</span></strong>
                                            </td>
                                            <td><span class="tdData colon">{{editData.data.Bill}}</span>
                                            </td>
                                        </tr>
                                        <tr class="thirdTableData">
                                            <td><strong><span class="tdData">SGST</span></strong>
                                            </td>
                                            <td><span class="tdData colon">{{editData.data.sgst}}</span>
                                            </td>
                                        </tr>
                                        <tr class="thirdTableData">
                                            <td class="borderBottom"><strong><span class="tdData">CGST</span></strong>
                                            </td>
                                            <td class="borderBottom"><span
                                                    class="tdData colon">{{editData.data.cgst}}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                                    <tbody>
                                        <tr height="25">
                                            <td width="40%" class="borderBottom"><strong><span
                                                        class="tdData">CGST</span></strong></td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData colon">{{editData.data.CGST}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%" class="borderBottom"><strong><span
                                                        class="tdData">SGST</span></strong></td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData colon">{{editData.data.SGST}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%" class="borderBottom"><strong><span class="tdData">
                                                        </span></strong></td>
                                            <td width="60%" class="borderBottom"><span
                                                    class="tdData">{{editData.data.roundOff}}</span></td>
                                        </tr>
                                        <tr height="25">
                                            <td width="40%"><strong><span class="tdData">Grand Total</span></strong>
                                            </td>
                                            <td width="60%"><span
                                                    class="tdData colon">{{editData.data.BillAmount}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- third table box ends -->


                            <!-- fourth table box starts -->
                            <tr>
                                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                                    <tbody>
                                        <tr class="tdData">
                                            <td width="45%" height="100" align="left" class="borderLeft lineHeight">
                                                <strong>I/We hereby certify that my/our registration certificate under
                                                    the goods and sercive tax act, 2017 is in force on the date on which
                                                    the sale of goods specified in this tax invoice made by me / us and
                                                    it will shall be accounted for in the turnover of sales while fiting
                                                    and retum and the due tax If any, payable on the sale has been paid
                                                    or shall be paid.</strong>
                                            </td>
                                            <td width="20%" align="center" class="borderLeft">
                                                <strong>Receiver's Sign</strong>
                                            </td>
                                            <td width="35%" align="center" class="borderLeft">
                                                <strong>For Anu Computer Consultancy <br><br>Authorised
                                                    Signature</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </tr>
                            <!-- fourth table box ends -->
                            <!-- Fifth table box starts -->
                            <tr>
                                <!-- qr code starts -->
                                <td align="start" class="txt" id="qrCode">
                                    <qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode>
                                </td>
                                <!-- qr code ends -->

                            </tr>
                            <!-- fifth table box ends -->

                            <tr>
                                <td colspan="4">&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>