import { Component, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-godown-master',
  templateUrl: './godown-master.component.html',
  styleUrls: ['./godown-master.component.css']
})
export class GodownMasterComponent implements OnInit {
  
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  passData!: any;
  data!: any;
  goDownGroupData!: any;
  isLoading: boolean = false;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;
  @ViewChild('newGodownForm') newGodownForm!: TemplateRef<any>;

  godownForm = this.fb.group({
    'godownLabel': ['', Validators.required],
  });
  code: any;

  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.resetGodownForm();
    this.getGodownData();
    console.clear()
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }


  getGodownData() {
    this.isLoading = false;
    let listOfItems = ['Godown'];
    let optionValList = ['0'];
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message")
      this.goDownGroupData = response.godown0;
      this.passData = [this.appConstants.godownGroupFormColumnNames, this.goDownGroupData, 'godownGroupForm', this.appConstants.godownGroupExcelColumnNames];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.godownGroupFormColumnNames, false, 'godownGroupForm'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  resetGodownForm() {
    this.showErrorAlert = false;
    this.godownForm = this.fb.group({
      'godownLabel': ['', Validators.required],
    });
  }

  addGodown() {
    if (this.godownForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      const generatedData = {
        Name: this.godownForm.controls['godownLabel'].value,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updateGodown(generatedData).subscribe(response => {
          this.getGodownData();
          this.toastr.success("Data Updated Successfully", "Update Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          }));
      } else {
        this.masterService.addNewGodown(generatedData).subscribe(response => {
          this.getGodownData();
          this.toastr.success("Data added Successful", "Add Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add New Data", "Add Error");
          }));
      }
    }
  }

  addNewgodown(rowData?: any) {
    this.resetGodownForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newGodownForm, {
        disableClose: true,
        width: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.patchGodownForm(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newGodownForm, {
        disableClose: true,
        width: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteGodown(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        }));
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchGodownForm(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newGodownForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchGodownForm(row) {
    this.godownForm.patchValue({
      'godownLabel': row.name,
    });
  }
}


