import { Component, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { MasterService } from 'src/services/master.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-add-ledger',
  templateUrl: './add-ledger.component.html',
  styleUrls: ['./add-ledger.component.css']
})
export class AddLedgerComponent implements OnInit {
  @Output() public emitData = new EventEmitter();
  displayColumns!: any;
  data!:any;
  finalLedgerData: any;
  amount: Number =0;
  @ViewChild('newItemForm') newItemForm! : TemplateRef<any>;

  itemForm= this.fb.group({
    'ledgerLabel':['',Validators.required],
    'percentLabel':['',Validators.required],
  });;
  constructor(private fb: FormBuilder,
              private masterService: MasterService,
              private dialog: MatDialog, private router: Router,
              private dialogRef: MatDialogRef<AddLedgerComponent>,
              @Inject(MAT_DIALOG_DATA) public ledgerData) { }

  ngOnInit() {
    this.resetItemForm();
  }

  resetItemForm(){
    this.itemForm = this.fb.group({
      'ledgerLabel':['',Validators.required],
      'percentLabel':[''],
      'amountLabel':['']
    });
  }

  addItem(){
    if(this.itemForm.invalid){
    }else{
      const genepercentdData = {
        ledgerLabel : this.itemForm.controls['ledgerLabel'].value,
        percentLabel : this.itemForm.controls['percentLabel'].value,
        'amount': this.itemForm.controls['amountLabel'].value,
        'isRoundOff': this.itemForm.controls['ledgerLabel'].value.toLowerCase() === 'round off' ? true : false,
        'cgstLabel' : !this.ledgerData.isIGST ? ((this.itemForm.controls['amountLabel'].value < 0 ? -(this.itemForm.controls['amountLabel'].value) : this.itemForm.controls['amountLabel'].value ) * this.ledgerData.ledger.find(i => i.name === this.itemForm.controls['ledgerLabel'].value).cgst) /100 : 0,
        'sgstLabel' : !this.ledgerData.isIGST ? ((this.itemForm.controls['amountLabel'].value < 0 ? -(this.itemForm.controls['amountLabel'].value) : this.itemForm.controls['amountLabel'].value ) * this.ledgerData.ledger.find(i => i.name === this.itemForm.controls['ledgerLabel'].value).sgst) /100 : 0,
        'igstLabel' :  this.ledgerData.isIGST ? ((this.itemForm.controls['amountLabel'].value < 0 ? -(this.itemForm.controls['amountLabel'].value) : this.itemForm.controls['amountLabel'].value )  * this.ledgerData.ledger.find(i => i.name === this.itemForm.controls['ledgerLabel'].value).igst) /100 : 0,
      }
      this.dialogRef.close(genepercentdData);    }
  }

  calculateAmount(event?){
    if(Number(this.itemForm.controls['percentLabel'].value) > 100 || Number(this.itemForm.controls['percentLabel'].value) < -100){
      console.log("incorrect percentage entered");
    }else{
      this.amount = 0;
      if(this.itemForm.controls['ledgerLabel'].value != null || this.itemForm.controls['ledgerLabel'].value != undefined){
        if(this.itemForm.controls['ledgerLabel'].value.toLowerCase() === 'round off'){
          this.amount = Math.round(this.ledgerData.subTotal);
          this.itemForm.controls['percentLabel'].setValue(0);
        }else{
          this.amount = (this.ledgerData.subTotal * ((this.itemForm.controls['percentLabel'].value != null || this.itemForm.controls['percentLabel'].value != undefined ? this.itemForm.controls['percentLabel'].value : 0))) / 100;
        }
      }
    }
  }

  openMasterLedger(){
    this.dialog.closeAll();
    this.router.navigateByUrl('dashboard/master/accounting/ledger');
  }

  openComponent(component: string){
    this.router.navigate(['/'+component]);    
  }
}