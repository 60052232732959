import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.css']
})
export class ConfirmationBoxComponent implements OnInit {
  title : string = '';
  message : string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data) {    
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit(): void {
    
    console.log("dialog data-->",this.data);
    
  }

}
