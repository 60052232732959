import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from 'src/services/AppConstants';
import { HeaderUpdateService } from 'src/services/header-update.service';

import { MasterService } from 'src/services/master.service';
import { CompanyListComponent } from '../../company-list/company-list.component';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { ConfirmDialogModel } from '../confirmation-box/confirmDialog.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  companyDetail: any;
  _hCompanyDetail : any;
  Serial: string;
  CompanyCode: string;
  companyName: string;
  customerLogin:boolean;
  @Output() sideNavToggled = new EventEmitter<boolean>();
  menuStatus: boolean =false;

  constructor(
    private masterService: MasterService,
    private router: Router,
    public dialog: MatDialog,
    public appConstants: AppConstants,
    private headerUpdate : HeaderUpdateService
  ) {}

  ngOnInit() {

    this.headerUpdate.companyDetails$.subscribe((response)=>{
      this._hCompanyDetail  = response;
      this.CompanyCode = this._hCompanyDetail.companyCode;
      this.companyName  = this._hCompanyDetail.companyName;
    })

    this.Serial = sessionStorage.getItem('Serial');
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
    // this.getClistData();
  }
  getClistData() {
    let listOfItems = ['CompanyList'];
    let optionValList = ['0'];
    this.masterService
      .getCompany(listOfItems, optionValList)
      .subscribe((response) => {
        this.companyDetail = response;
      });
  }
  changeCompany() {
    const dialogRef = this.dialog.open(CompanyListComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.UpdateFunction();
    });
  }
  UpdateFunction() {
    this.CompanyCode = sessionStorage.getItem('CompanyCode');
    this.companyName = sessionStorage.getItem('companyName');
  }

  logOut() {
    let dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      disableClose: true,
      width: "30%",
      height: "40%",
      data : ConfirmDialogModel.ForLogout()
    });

    dialogRef.afterClosed()
      .subscribe(confirmed => {
        if(confirmed)
          this.logOutConfirmed();
      });
  }

  private logOutConfirmed()
  {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  
  sidenavToggle() {
    this.menuStatus = !this.menuStatus;
    this.sideNavToggled.emit(this.menuStatus)
  }

}
