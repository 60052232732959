<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<mat-dialog-content>
    <div class="container">
        <p class="addNew" mat-button (click)="openCompany()">
            <mat-icon class="addNew" matTooltip="Add new">add_box</mat-icon>
        </p>
        <p class="actionIcon" (click)="closeCompany()">
            <mat-icon class="cross" matTooltip="close">clear</mat-icon>
        </p>
        <h1 mat-dialog-title class="companytitle">COMPANY LIST</h1>
        <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
        <div *ngIf="isLoading">
            <mat-card>
                <div class="search_div col-sm-12">
                    <span class="msg col-sm-4">Please Select Company</span>
                    <input type="text" class="search_input col-sm-6" placeholder="Search..." [(ngModel)]="companySearch"
                        (keyup)="searchCompany()">
                    <button type="button" class="btn search_btn col-sm-2">Search</button>
                </div><!-- search_div -->
                <table class="table" width="100%">
                    <thead>
                        <tr class="tableHeading">
                            <th>Company Name</th>
                            <th>Company Code</th>
                            <th></th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let company of companies">
                            <td class="company-data">{{company.companyName}}</td>
                            <td class="company-data">{{company.companyCode}}</td>
                            <td><button class="selectBtn" mat-raised-button color="primary"
                                    (click)="selectCompany(company)" mat-dialog-close>Select</button></td>
                            <td>
                                <button class="selectBtn" mat-button (click)="viewCompany(company)">
                                    <mat-icon class="editIcon" matTooltip="View">visibility</mat-icon>
                                </button>
                                <button class="selectBtn" mat-button (click)="editCompany(company)">
                                    <mat-icon class="editIcon" matTooltip="edit">edit</mat-icon>
                                </button><button class="selectBtn" mat-button color="warn"
                                    (click)="deleteCompany(company)">
                                    <mat-icon matTooltip="delete">delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </mat-card>
        </div>
    </div>
</mat-dialog-content>