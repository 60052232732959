import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ReportsService } from 'src/services/reports.service';
import { FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/services/AppConstants';
import { Router } from '@angular/router';
import { TableFilter } from 'src/app/shared/tableFilter';
import { MatPaginator } from '@angular/material/paginator';

export interface Transaction {
  inQty: number;
  outQty: number;
  balance: number;
  name: string;
  opening: number

}

@Component({
  selector: 'app-stock-summary',
  templateUrl: './stock-summary.component.html',
  styleUrls: ['./stock-summary.component.css']
})
export class StockSummaryComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key=event.key.toUpperCase();
    if(event.key=="Escape"){
      this.openComponent('dashboard/reports')
    }
  }
  disableSelect = new FormControl(false);

  isLoading: boolean = false;
  ItemData: any;
  passData: any[];
  dataSource: MatTableDataSource<any>;
  headTitle: string;
  columnName:any;
  selectedColumn:string;
  searchValue:string;
  selectedCondtion:string;
  filterConditionsList:string[] = [];
  disableSelectionDropDown:boolean = true;
  unfilteredData:any=[];
  filterDataCount:number=0;
  totalRowCount:number=0;


  constructor(
    private fb: FormBuilder,
    private reportService: ReportsService,
    private appConstants: AppConstants,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.getStockData();
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }

  }

  valueSearchEvent(value:string){
    let filterObject:any= {column:this.selectedColumn,condition:this.selectedCondtion,value:value}
    let filteredData=TableFilter.tableFilter(this.unfilteredData,filterObject)
    this.dataSource = new MatTableDataSource(filteredData.data);
    this.filterDataCount=filteredData.count
    this.dataSource.paginator = this.paginator;
  }

  openComponent(component: string){
    this.router.navigate(['/'+component]); }

  getTotalCost() {
    return this.passData[1].map(t => t.balance).reduce((acc, value) => acc + value, 0);
  }

  columnSelectionFn(value:any){
    this.dataSource.data.filter(item =>{
      if(typeof item[value]!== 'string'){
        this.disableSelectionDropDown=false;
      }
      else{
        this.disableSelectionDropDown=true;
      }
    })
  }

  getStockData(){

    this.columnName = [{
      name: 'Name',
      value: 'name'
    },
    {
      name: 'Opening',
      value: 'opening'
    },
    {
      name: 'In Qty',
      value: 'in Qty'
    },
    {
      name: 'Out Qty',
      value: 'out Qty'
    },
    {
      name: 'Balance',
      value: 'balance'
    },
  ]

    this.selectedColumn=this.columnName[0].value;
    this.filterConditionsList = this.appConstants.filterCondtions;
    this.selectedCondtion=this.appConstants.filterCondtions[0];

    this.isLoading=false;
    this.headTitle="Stock Summary"
    this.reportService.getStockSummary().subscribe( response => {
      this.ItemData = response;
      this.passData=[this.appConstants.StockSummaryColumnNames, this.ItemData];
        this.dataSource = new MatTableDataSource(this.passData[1]);
        console.log(this.dataSource);
        this.isLoading=true;
        this.unfilteredData = this.dataSource.data;
        this.totalRowCount = this.dataSource.data.length;
        this.columnSelectionFn(this.selectedColumn);
        this.dataSource.paginator = this.paginator;
    })
}

}
