<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
    crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
    crossorigin="anonymous"></script>
<div class="container">
  <mat-dialog-content>
    <p class="actionIcon" mat-dialog-close>
        <mat-icon class="cross" matTooltip="close">clear</mat-icon>
    </p>
    <h1 mat-dialog-title align="start">Upload File</h1>
    <mat-card>
        <mat-card-content>
          <h4>
           Before uploading the file, please ensure that the file has following:
          </h4>
          <ul>

          <li>
            .csv extension
          </li>
          <li>
            <b>
              Headers:
            </b>
            {{headerFields}}
          </li>
          <li>
            Should not contain other fields other than above mentioned fields
          </li>
        </ul>

        </mat-card-content>
    </mat-card>
</mat-dialog-content>

    <mat-dialog-actions align='center'>
        <button class="btn btn-primary col-sm-5" (click) = "downloadFile()">Download Template File</button>
        <hr>
        <input type="file" class="add-btn" multiple (change)="upload($event)">
    </mat-dialog-actions>
</div>
