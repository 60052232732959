export class AppConstants {
    Serial: any;
    CompanyCode: any;
    companyName:any;
    code: any;
    ServerVal: any = 1 ;
    companyStateCode : any = 27;
    itemFormColumnNames = ['name', 'stock_Group', 'unit', 'hsn', 'sgst', 'cgst', 'igst', 'opening', 'action'];
    hsnFormColumnNames= ['name' , 'igst' , 'cgst' , 'sgst' , 'action'];
    stockGroupFormColumnNames=['name','hsn','sgst','cgst','igst','action'];
    godownGroupFormColumnNames=['name', 'action'];
    salesMenGroupFormColumnNames=['name', 'action'];
    ledgerGroupFormColumnNames = ['name', 'group', 'igst', 'cgst', 'sgst', 'action'];
    voucherGroupFormColumnNames = ['name', 'voucher_Type', 'currentNo', 'numbering', 'action'];
    groupFormColumnNames=['groupBase' , 'name' ,'action'];
    salesBillItemTableColumnNames = ['Sr. No.', 'Item Name', 'HSN' ,'Quantity' , 'Rate', 'Discount' , 'Unit', 'Amount', '' ,'Action'];
    materialInTableColumnNames = ['Sr. No.', 'Item Name', 'HSN' ,'Quantity' , 'Rate', 'Unit', 'Amount', '' ,'Action']
    salesFormName=[ 'billDate','voucherName', 'voucherNo','name', 'billAmount','action'];
    partyWiseColumnsNames=['billDate', 'voucherName','voucherNo','name', 'qty', 'rate', 'amount'];
    itemWiseColumnsNames=['billDate', 'voucherName','voucherNo','name', 'qty', 'rate', 'amount'];
    LedgerReportsColumnsNames=['date', 'voucher','bill No', 'debit','credit'];
    itemReportColumnsNames=['Bill Date', 'Name','Voucher Name','Voucher No', 'Quantity', 'Rate', 'Amount'];
    PartyWiseColumnarName=[ 'name', 'voucherNo', 'billDate', 'billAmount'];
    StockSummaryColumnNames=['name','opening','in Qty','out Qty','balance'];
    costCenterFormColumnNames= ['name', 'action'];
    salesPriceFormColumnNames=['aCode','discountPer', 'iCode','partNo', 'rate','action'];
    purchaseCostFormColumnNames=['aCode','discountPer', 'iCode','partNo', 'rate','action'];
    ExcelSalesColumnNames=['BillNo','BillDate','CustomerName','BillState','ShipState','Email','PinCode','VoucherType','ItemName','unit','hsn','billedQty','rate','subTot','cgstPer','cgst','sgstPer','sgst','igstPer','igst','billAmount','narration'];
    ExcelReceiptColumnNames=['BillNo','BillDate','VoucherType','Particular','Cash','TotalAmount','CheqNo','CheqDate','Banker','ClearDate','Reference','Narration'];
    billAdjustmentColumnNames=['aCode','adjAmt','balance','recPay','billNo','action'];
    ledgerGroupExcelColumnNames = ['name','alias','address','city','pinCode','email','mobile','contactNo','state','stateCode','gstNo','gsT_Type','group', 'igst', 'cgst', 'sgst','billBy','days','drCr','opening'];
    voucherGroupExcelColumnNames = ['name', 'voucher_Type', 'currentNo', 'numbering','billDigit','billPrint','prefix','start_With','suffix'];
    groupExcelColumnNames=['groupBase' , 'name'];
    costCenterExcelColumnNames= ['name'];
    salesMenGroupExcelColumnNames=['name'];
    billAdjustmentExcelColumnNames=['aCode','adjAmt','balance','recPay','billNo'];
    itemExcelColumnNames = ['name', 'stock_Group', 'unit', 'hsn', 'sgst', 'cgst', 'igst', 'qty','description','discount','opening','rate'];
    stockGroupExcelColumnNames=['name','hsn','sgst','cgst','igst'];
    godownGroupExcelColumnNames=['name'];
    salesPriceExcelColumnNames=['aCode','discountPer', 'iCode','partNo', 'rate'];
    purchaseCostExcelColumnNames=['aCode','discountPer', 'iCode','partNo', 'rate'];
    hsnExcelColumnNames= ['name' , 'igst' , 'cgst' , 'sgst'];
    stockItemColumnsNames=['Bill Date', 'Voucher Name', 'Voucher No.','Particulars','Opening', 'In', 'Out', 'Balance', 'Rate', 'Amount'];
    clientFormColumnNames=['urLedgerCodeName','emailId', 'action'];
    clientExcelColumnNames: any;
    filterCondtions:string[] = ["Equal", "Not equal", "Greater than", "Less than", "Greater than equal to", "Less than equal to"]


}
