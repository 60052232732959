<mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
<div *ngIf="isLoading">
  <button (click)="exportToExcel()">Export to Excel</button>

  <div id="panel">

    <!-- Sale type excel table -->
      <table mat-table [dataSource]="this.excelColumnData[1]" style="width: 100%;" *ngIf="ShowLabel1">
        <ng-container matColumnDef="BillNo">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Bill No </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.documentNo}} </td>
        </ng-container>
        <ng-container matColumnDef="BillDate">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Bill Date </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeDateFormat(element.billDate)}} </td>
        </ng-container>
        <ng-container matColumnDef="CustomerName">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Customer Name </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.name}} </td>
        </ng-container>
        <ng-container matColumnDef="BillState">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Bill State </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToBillState(element.aCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="ShipState">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Ship State </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToShipState(element.bCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Email </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToEmail(element.aCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="PinCode">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> PinCode </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToPinCode(element.aCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="VoucherType">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Voucher Type </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.voucherName}} </td>
        </ng-container>
        <ng-container matColumnDef="ItemName">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Item Name </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToItemName(element.iCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="unit">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Unit </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.unit}} </td>
        </ng-container>
        <ng-container matColumnDef="hsn">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> HSN </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.hsn}} </td>
        </ng-container>
        <ng-container matColumnDef="billedQty">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Quantity </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.billedQty}} </td>
        </ng-container>
        <ng-container matColumnDef="rate">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Rate </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.rate}} </td>
        </ng-container>
        <ng-container matColumnDef="subTot">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Basic Value </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.subTot}} </td>
        </ng-container>
        <ng-container matColumnDef="cgstPer">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Cgst Per </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToCgst(element.iCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="cgst">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Cgst Amt </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.cgst}} </td>
        </ng-container>
        <ng-container matColumnDef="sgstPer">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Sgst Per </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToSgst(element.iCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="sgst">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Sgst Amt </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.sgst}} </td>
        </ng-container>
        <ng-container matColumnDef="igstPer">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Igst Per </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeToIgst(element.iCode)}} </td>
        </ng-container>
        <ng-container matColumnDef="igst">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Igst Amt </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.igst}} </td>
        </ng-container>
        <ng-container matColumnDef="billAmount">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Total Amount </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.billAmount}} </td>
        </ng-container>
        <ng-container matColumnDef="narration">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Narration </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.narration}} </td>
        </ng-container>
        <tr mat-header-row  *matHeaderRowDef="this.excelColumnData[0]"></tr>
        <tr mat-row  *matRowDef="let row; columns: this.excelColumnData[0];"></tr>
      </table>


      <!-- Receipt Type excel table -->

      
      <table mat-table [dataSource]="this.excelColumnData[1]" style="width: 100%;" *ngIf="ShowLabel2">
        <ng-container matColumnDef="BillNo">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Bill No </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.documentNo}} </td>
        </ng-container>
        <ng-container matColumnDef="BillDate">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Bill Date </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeDateFormat(element.billDate)}} </td>
        </ng-container>
        <ng-container matColumnDef="VoucherType">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Voucher Type </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.voucherName}} </td>
        </ng-container>
        <ng-container matColumnDef="Particular">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Particular </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.particular}} </td>
        </ng-container>
        <ng-container matColumnDef="Cash">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Cash/Bank/Online </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.cash}} </td>
        </ng-container>
        <ng-container matColumnDef="TotalAmount">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Total Amount </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.billAmount}} </td>
        </ng-container>
        <ng-container matColumnDef="CheqNo">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> cheque No </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.chequeNo}} </td>
        </ng-container>
        <ng-container matColumnDef="CheqDate">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Cheque Date </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{ChangeDateFormat(element.chequeDate)}} </td>
        </ng-container>
        <ng-container matColumnDef="Banker">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Banker </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.banker}} </td>
        </ng-container>
        <ng-container matColumnDef="ClearDate">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Clear Date </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.clear}} </td>
        </ng-container>
        <ng-container matColumnDef="Reference">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Reference </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.reference}} </td>
        </ng-container>
        <ng-container matColumnDef="Narration">
          <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;"> Narration </th>
          <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;"> {{element.narration}} </td>
        </ng-container>
        <tr mat-header-row  *matHeaderRowDef="this.excelColumnData[0]"></tr>
        <tr mat-row  *matRowDef="let row; columns: this.excelColumnData[0];"></tr>
      </table>

      <table mat-table [dataSource]="this.excelColumnData[1]" style="width: 100%;" *ngIf="ShowLabel3">
        <ng-container *ngFor="let col of this.excelColumnData[0]">
          <ng-container matColumnDef="{{col}}">
            <th mat-header-cell *matHeaderCellDef style="min-width: 100px; padding:10px; text-align: center;">{{renameColumn(col)}}</th>
            <td mat-cell *matCellDef="let element" style="min-width: 100px; padding:10px; text-align: center;">{{renameData(element[col])}}</td>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="this.excelColumnData[0]"></tr>
        <tr mat-row *matRowDef="let row; columns: this.excelColumnData[0];"></tr>
      </table>

    </div>
</div>