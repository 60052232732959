<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
    <mat-drawer-container class="example-container">
        <mat-drawer #drawer mode="push">
        </mat-drawer>
        <mat-drawer-content style="min-height: 90vh;">
            <div class="main-container component-class">
                <mat-button-toggle-group class="ts-btn" #group="matButtonToggleGroup" value={{this.value}}>
                    <mat-button-toggle class="t-btn" #toggleBtn1 (click)="toggleButton1()" value="accounting">ACCOUNTING <span class="highlighter">T</span>RANSACTION
                    </mat-button-toggle>
                    <mat-button-toggle class="t-btn" #toggleBtn2 (click)="toggleButton2()" value="inventory">INVENTORY <span class="highlighter">T</span>RANSACTION
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <div [hidden]="toggleBtn" class="btn-group">
                    <app-accounting-transaction></app-accounting-transaction>
                </div>
                <div [hidden]="!toggleBtn" class="btn-group">
                    <app-inventory-transaction></app-inventory-transaction>
                </div>
            </div>
            <!-- Body content Ends -->

            
        </mat-drawer-content>
    </mat-drawer-container>
    </div>
    <app-footer></app-footer>
</div>