<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <mat-drawer-container class="example-container">
        <mat-drawer-content style="height: 100vh;">

            <!-- Head part -->
            <div class="main-containers page-header">
                <mat-toolbar>
                    <nav class="headTitle">mis</nav>
                </mat-toolbar>
            </div>
            <div class="btns-container">
                <div class="btn-container">

                    <button mat-raised-button (click)="openComponent('dashboard/report/mis-report/item-wise-sales')"><span class="highlighter">I</span>tem Wise Sale</button>
                    <button mat-raised-button (click)="openComponent('dashboard/report/mis-report/party-wise-sales')"><span class="highlighter">P</span>arty Wise Sales</button>
                    <button mat-raised-button (click)="openComponent('dashboard/report/register/item-wise-columnar')">Item Wise Party <span class="highlighter">W</span>ise Columnar</button>
                    <button mat-raised-button (click)="openComponent('dashboard/report/register/party-wise-columnar')">Party Wise Item Wise <span class="highlighter">C</span>olumnar</button>
                </div>    
            </div>
        </mat-drawer-content>
    </mat-drawer-container>
    <app-footer></app-footer>
</div>