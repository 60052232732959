export class SortByOrder {

  static sortByOrder(array: any[], sortfields: any[]): void {


    array.sort((a: any, b: any) => {
      for (let i = 0; i <= sortfields.length; i++) {

        const orderValue = sortfields[i]?.order === 'desc' ? -1 : 1;

        if (a[sortfields[i]?.orderBy] < b[sortfields[i]?.orderBy]) {
          return -1 * orderValue;
        } else if (a[sortfields[i]?.orderBy] > b[sortfields[i]?.orderBy]) {
          return 1 * orderValue;
        }
      }
      return 0;
    });
  }

}
