<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
    <mat-drawer-container class="example-container">
        <mat-drawer-content style="min-height: 90vh;width: 100%;padding: 0 0 0 3rem; overflow: hidden;">

            <div class="main-container">
                <mat-button-toggle-group class="ts-btn" #group="matButtonToggleGroup" value={{this.value}}>
                    <mat-button-toggle class="t-btn" #toggleBtn1 (click)="toggleButton1()" value="accounting">ACCOUNTING
                        MASTER</mat-button-toggle>
                    <mat-button-toggle class="t-btn" #toggleBtn2 (click)="toggleButton2()" value="inventory">INVENTORY
                        MASTER</mat-button-toggle>
                </mat-button-toggle-group>
                <div [hidden]="toggleBtn" class="btn-group" id="accountingMaster">
                    <br><br>
                    <app-accounting-master></app-accounting-master>
                </div>
                <div [hidden]="!toggleBtn" class="btn-group" id="inventoryMaster">
                    <br><br>
                    <app-inventory-master></app-inventory-master>
                </div>
            </div>            
        </mat-drawer-content>
    </mat-drawer-container>
</div>
    <app-footer></app-footer>
</div>