<li>
    <a (click)="expandOn(item)" (dblclick)="onItemSelected(item)">
        <i class="icon fa-2x fa">
            <img *ngIf="item?.icon" [src]="item?.icon" alt="img">
            <img *ngIf="!item?.icon && item?.child?.length" class="arrowImage"
                [src]=" expanded ? 'assets/img/down_arrow.svg' : 'assets/img/right_arrow.svg'" alt="">
        </i>
        <span class="nav-text"> {{item.name}} </span>
    </a>
</li>

<div *ngIf=" expanded">
    <app-menu *ngFor="let child of item.child" [item]="child">
    </app-menu>
</div>