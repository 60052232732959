import { Component, ElementRef, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cost-center',
  templateUrl: './cost-center.component.html',
  styleUrls: ['./cost-center.component.css']
})
export class CostCenterComponent implements OnInit {
  sideNavStatus:boolean =false;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @ViewChild('newCostCenterForm') newCostCenterForm!: TemplateRef<any>;
  @ViewChild('content') content: ElementRef;
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;
  passData: any;
  isLoading: boolean = false;
  data!: any;
  CostCenterFormData: any;


  CostCenterForm = this.fb.group({
    'CostCenterLabel': ['', Validators.required]
  });
  CostCenterData: any;
  code: any;

  ;
  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.resetCostCenterForm();
    this.getCostCenterData();
    console.clear()
  }
  getCostCenterData() {
    this.isLoading = false;
    let listOfItems = ['CostCenter'];
    let optionValList = ['0'];
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message")
      this.CostCenterFormData = response.costCenter0;
      this.passData = [this.appConstants.costCenterFormColumnNames, this.CostCenterFormData, 'CostCenterForm', this.appConstants.costCenterExcelColumnNames];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.costCenterFormColumnNames, false, 'CostCenterForm'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      }));
  }

  resetCostCenterForm() {
    this.showErrorAlert = false;
    this.CostCenterForm = this.fb.group({
      'CostCenterLabel': ['', Validators.required]
    });
  }

  addCostCenter(rowData?: any) {
    if (this.CostCenterForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      this.isLoading = false;
      const generatedData = {
        Name: this.CostCenterForm.controls['CostCenterLabel'].value,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updateCostCenter(generatedData).subscribe(response => {
          this.getCostCenterData();
          this.toastr.success("Data Updated Successfully", "Update Success", {
            closeButton: true,
            
          })
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error", {
              closeButton: true,
              
            });
          })
        );
      } else {
        this.masterService.addNewCostCenter(generatedData).subscribe(response => {
          this.getCostCenterData()
          this.toastr.success("Data Submitted Successful", "Add Success", {
            closeButton: true,
            
          });
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Submit Data", "Add Error", {
              closeButton: true,
              
            });
          })
        );
      }
    }
  }

  addNewCostCenter(rowData?: any) {
    this.resetCostCenterForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true;
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newCostCenterForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true;
      this.patchCostCenterFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newCostCenterForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteCostCenter(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchCostCenterFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newCostCenterForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchCostCenterFormValues(row) {
    this.CostCenterForm.patchValue({
      'CostCenterLabel': row.name,
    });
  }
}
