import { AfterViewInit, Component, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { MasterService } from 'src/services/master.service';

import { Router } from '@angular/router';

@Component({
  selector: 'app-add-detail',
  templateUrl: './add-detail.component.html',
  styleUrls: ['./add-detail.component.css']
})
export class AddDetailComponent implements OnInit, AfterViewInit {
  @Output() public emitData = new EventEmitter();
  displayColumns!: any;
  data!: any;
  finalAdditionalDetails: any;
  url: any = window.location.href;
  showLabel: boolean = true;
  @ViewChild('newItemForm') newItemForm!: TemplateRef<any>;
  
  itemForm = new FormGroup({
    ponoLabel: new FormControl(""),
    poDateLabel: new FormControl(""),
    challanNoLabel: new FormControl(""),
    challanDateLabel: new FormControl(""),
    refNoLabel: new FormControl(""),
    refDateLabel: new FormControl(""),
    salesmenLabel: new FormControl(""),
    consigneeLabel: new FormControl(""),
  });

  constructor(private fb: FormBuilder,
    private masterService: MasterService, private dialogRef: MatDialogRef<AddDetailComponent>,
    private dialog: MatDialog, private router: Router,
    @Inject(MAT_DIALOG_DATA) public inputData) { }

  ngOnInit() {
    if (this.url.endsWith("transactions/accounting/saleOrder")) {
      this.showLabel = false
    } else if (this.url.endsWith("transactions/accounting/purchaseOrder")) {
      this.showLabel = false
    }
    // console.log("dialog-->", this.inputData);
    // console.log("consigneeData-->", this.inputData?.consigneeData);
  }

  ngAfterViewInit(): void {
    if(this.inputData?.previousAdditionalData != undefined){
      this.itemForm.patchValue(this.inputData?.previousAdditionalData);
      this.finalAdditionalDetails = this.inputData.previousAdditionalData;
    }
    // else{
    //   this.itemForm.reset();
    // }
  }

  addItem() {
    if (this.itemForm.invalid) {
    } else {
      const generatedData = {
        ponoLabel: this.itemForm.controls['ponoLabel'].value,
        poDateLabel: this.itemForm.controls['poDateLabel'].value,
        challanNoLabel: this.itemForm.controls['challanNoLabel'].value,
        challanDateLabel: this.itemForm.controls['challanDateLabel'].value,
        refNoLabel: this.itemForm.controls['refNoLabel'].value,
        refDateLabel: this.itemForm.controls['refDateLabel'].value,
        salesmenLabel: this.itemForm.controls['salesmenLabel'].value,
        consigneeLabel: this.itemForm.controls['consigneeLabel'].value,
      }
      this.dialogRef.close(generatedData);
    }
  }

  // addNewitem(){
  //   let dialogRef = this.dialog.open(this.newItemForm,{
  //     width: '60%',
  //     height : '80%'
  // });
  //   dialogRef.afterClosed().subscribe(result => {});
  // }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
}
