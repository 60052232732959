import { Component, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { EmailValidator, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { CompanyService } from 'src/services/company.service';
import { Validator } from '@angular/forms';
import { from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';
import { decimalDigest } from '@angular/compiler/src/i18n/digest';
import { Router } from '@angular/router';
import { DataUpload } from 'src/app/shared/dataUpload';
import { UploadMasterDialogComponent } from '../../upload-master-dialog/upload-master-dialog.component';



@Component({
  selector: 'app-ledger-form',
  templateUrl: './ledger-form.component.html',
  styleUrls: ['./ledger-form.component.css']
})
export class LedgerFormComponent implements OnInit {
  sideNavStatus:boolean =false;
  disableSelect = new FormControl(false);
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @ViewChild('newLedgerForm') newLedgerForm!: TemplateRef<any>;
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  passData!: any;
  isLoading: boolean = false;
  ledgerGroupData!: any;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;
  data!: any;
  stateData: any;
  groupData!: any;
  // nrSelect = "NOT APPLICABLE";
  editable = false;
  code: any;
  sum: number = 0;

  gstTypes : { name: string; value: string }[] = [
    { name: "NOT APPLICABLE", value: "NOT APPLICABLE" },
    { name: "REGULAR", value: "REGULAR" },
    { name: "UNREGISTERED", value: "UNREGISTERED" },
    { name: "COMPOSITION", value: "COMPOSITION" },
    { name: "EXTRA", value: "EXTRA" },
  ];

  ledgerForm = this.fb.group({

    'nameLabel': ['', Validators.required],
    'aliasLabel': [''],/*""*/
    'addressLabel': [''],/*""*/
    'stateLabel': [''],/*""*/
    'cityLabel': [''],/*""*/
    'pinLabel': [''],/*0*/
    'groupLabel': [''],/*""*/
    contactLabel: ['', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],/*0*/
    mobileLabel: ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],/*0 */
    emailLabel: ['', [Validators.email]],/*""*/
    'openingLabel': [''],/*0*/
    'dcLabel': [''],/*""*/
    'gstTypeLabel': ['', Validators.required],
    'stateCodeLabel': [''],/*0*/
    'gstNoLabel': [''],/*0*/
    'panNoLabel': [''],/*0*/
    //'igstLabel': ['', Validators.pattern("^[0-9][0-9]?$|^100$")],/*0.0*/
    //'cgstLabel': ['', Validators.pattern("^[0-9][0-9]?$|^100$")],/*0.0*/
    //'sgstLabel': ['', Validators.pattern("^[0-9][0-9]?$|^100$")],/*0.0*/
    'igstLabel': ['', Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")],/*0.0*/ //Added by Priyanka
    'cgstLabel': ['', Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")],/*0.0*/ //Added by Priyanka
    'sgstLabel': ['', Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")],/*0.0*/ //Added by Priyanka
    'billLabel': [''],/*""*/
    'dayLabel': [''],/*0*/
  });

  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService,
    private companyService: CompanyService) { }

  ngOnInit() {
    this.resetLedgerForm();
    this.getLedgerData();
    this.getStateData();
    // console.clear();
    this.stateData = JSON.parse(sessionStorage.getItem('StateData'));
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  getStateData() {
    this.isLoading = false;
    let listOfItems = ['stateList'];
    let optionValList = ['0'];
    this.masterService.getStateData(listOfItems, optionValList).subscribe((response) => {
      sessionStorage.setItem('StateData', JSON.stringify(response));
      this.stateData = JSON.parse(sessionStorage.getItem('StateData'));
    },
      (error => {
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve State Data", "Data Retrieve Error");
      })
    );
  }
  getLedgerData() {
    this.isLoading = false;
    let listOfItems = ['Ledger', 'Group'];
    let optionValList = ['0', '0'];
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrieved Successfully", "Data Retrieve Message");
      this.ledgerGroupData = response.ledger0;
      this.groupData = response.group0;
      this.passData = [this.appConstants.ledgerGroupFormColumnNames, this.ledgerGroupData, 'ledgerGroupForm', this.appConstants.ledgerGroupExcelColumnNames, this.groupData];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.ledgerGroupFormColumnNames, false, 'ledgerGroupForm', this.groupData];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  // Only AlphaNumeric
  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


  // Only Numbers with Decimals
  keyPressNumbersDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  // Only Integer Numbers
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  resetLedgerForm() {
    this.showErrorAlert = false;
    this.ledgerForm = this.fb.group({
      'nameLabel': ['', Validators.required],
      'aliasLabel': [''],
      'addressLabel': [''],
      'stateLabel': [''],
      'cityLabel': [''],
      'pinLabel': [''],
      'groupLabel': [''],
      'contactLabel': ['', Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      'mobileLabel': ['', [Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      'emailLabel': ['', [Validators.email]],
      'openingLabel': [''],
      'dcLabel': [''],
      'gstTypeLabel': [''],
      'stateCodeLabel': [''],
      'gstNoLabel': [''],
      'panNoLabel': [''],
      //'igstLabel': ['', Validators.pattern("^[0-9][0-9]?$|^100$")],
      //'cgstLabel': ['', Validators.pattern("^[0-9][0-9]?$|^100$")],
      //'sgstLabel': ['', Validators.pattern("^[0-9][0-9]?$|^100$")],
      'igstLabel': ['', Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")], //Added by Priyanka
      'cgstLabel': ['', Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")], //Added by Priyanka
      'sgstLabel': ['', Validators.pattern("^[0-9]*(\.[0-9]{0,1})?$")], //Added by Priyanka
      'billLabel': [''],
      'dayLabel': [''],
    });
  }

  addLedger() {
    if (this.ledgerForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      this.isLoading = false;
      const generatedData = {
        Name: this.ledgerForm.controls['nameLabel'].value,
        Alias: this.ledgerForm.controls['aliasLabel'].value,
        Address: this.ledgerForm.controls['addressLabel'].value,
        State: this.ledgerForm.controls['stateLabel'].value || null,
        City: this.ledgerForm.controls['cityLabel'].value || null,
        PinCode: Number(this.ledgerForm.controls['pinLabel'].value) || null,
        UnderGroup: this.ledgerForm.controls['groupLabel'].value,
        ContactNo: Number(this.ledgerForm.controls['contactLabel'].value) || null,
        Mobile: Number(this.ledgerForm.controls['mobileLabel'].value) || null,
        Email: this.ledgerForm.controls['emailLabel'].value || null,
        StateCode: Number(this.ledgerForm.controls['stateCodeLabel'].value) || null,
        Gstno: (this.ledgerForm.controls['gstNoLabel'].value) || null,
        PANNO: (this.ledgerForm.controls['panNoLabel'].value) || null,
        Igst: Number(this.ledgerForm.controls['igstLabel'].value),
        Cgst: Number(this.ledgerForm.controls['cgstLabel'].value),
        Sgst: Number(this.ledgerForm.controls['sgstLabel'].value),
        Opening: Number(this.ledgerForm.controls['openingLabel'].value),
        BillBy: this.ledgerForm.controls['billLabel'].value,
        DrCr: this.ledgerForm.controls['dcLabel'].value,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Days: (this.ledgerForm.controls['dayLabel'].value),
        Type: this.ledgerForm.controls['gstTypeLabel'].value,
        Code: !this.isEditFlow ? '' : this.code,

      }
      if (this.isEditFlow) {
        this.masterService.updateLedger(generatedData).subscribe(response => {
          this.dialog.closeAll();
          this.getLedgerData();
          this.toastr.success("Data Updated Successfully", "Update Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          })
        );
      } else {
        this.masterService.addNewLedger(generatedData).subscribe(response => {
          this.toastr.success("Data added Successfully", "Add Success");
          this.dialog.closeAll();
          this.getLedgerData();
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add New Data", "Add Error");
          })
        );
      }
    }
  }

  upload(){
    this.dialog.open(UploadMasterDialogComponent, {
      data: {
        headerFields: '"Name", "State", "GSTNO", "PANNO", "OpeningBalance", "Address", "City", "PinCode", "ContactNo", "GSTType", "IGST", "CGST", "SGST"',
        masterName: "Ledger"
      }
    });
  }

  addNewledger(rowData?: any) {
    this.resetLedgerForm();
    if (rowData === null || rowData === undefined) {
      this.forView = true;
      this.code = '';
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newLedgerForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true;
      this.patchLedgerFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newLedgerForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteLedger(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      // this.dialog.closeAll()
      this.forView = false
      this.patchLedgerFormValues(rowData.row);
      let dialogRef = this.dialog.open(this.newLedgerForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchLedgerFormValues(row) {
    console.log("patchRow-->", row);
    
    if (row.contactNo == 0) {
      row.contactNo = "";
    }
    if (row.mobile == 0) {
      row.mobile = "";
    }
    this.ledgerForm.patchValue({
      nameLabel: row.name,
      aliasLabel: row.alias,
      addressLabel: row.address,
      stateLabel: row.state,
      cityLabel: row.city,
      pinLabel: row.pinCode,
      groupLabel: row.group,
      contactLabel: row.contactNo,
      mobileLabel: row.mobile,
      emailLabel: row.email,
      openingLabel: row.opening,
      dcLabel: row.drCr,
      gstTypeLabel: row.gsT_Type,
      stateCodeLabel: row.stateCode,
      gstNoLabel: row.gstNo,
      panNoLabel: row.panNo,
      igstLabel: row.igst,
      cgstLabel: row.cgst,
      sgstLabel: row.sgst,
      billLabel: row.billBy,
      dayLabel: row.days,
    });

  }

  setStateCodeValues(event: Event) {
    let statename = (<HTMLInputElement>event.target).value;
    this.stateData.forEach((stateLabel: any) => {
      if (statename === stateLabel.stateName) {
        this.ledgerForm.patchValue({
          'stateCodeLabel': stateLabel.stateCode,
        })
      }
    })
  }
  setOneNumberDecimalForIGST(event) {

    if(this.ledgerForm.get('igstLabel').value && event.target.value !==""){
      event.target.value = parseFloat(event.target.value).toFixed(1);
      this.ledgerForm.patchValue({
        sgstLabel: (parseFloat(event.target.value)/2).toFixed(1),
        cgstLabel: (parseFloat(event.target.value)/2).toFixed(1)
      })
    }
  else{
      event.target.value = 0;
      }
  }

  setOneNumberDecimal(event) {
    if((this.ledgerForm.get('sgstLabel').value || this.ledgerForm.get('cgstLabel').value) && event.target.value !==""){
      event.target.value = parseFloat(event.target.value).toFixed(1);
    }
  else{
      event.target.value = 0;
      }
  }

  getCompanyDetails() {
    let gstin = '27ACIPK0613R1ZB'
    this.companyService.getCompanyDetails(gstin)
    .subscribe(
      data => {
        if (!data.error) {
          console.log(data.tpDetails);
        } else {
          console.error(data.message);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  openGroup():void{
    this.dialog.closeAll();
    this.router.navigateByUrl('dashboard/master/accounting/group');
  }

  get mobileLabel() {
    return this.ledgerForm.get('mobileLabel');
  }
  get contactLabel() {
    return this.ledgerForm.get('contactLabel');
  }
  get emailLabel() {
    return this.ledgerForm.get('emailLabel');
  }
  get igstLabel() {
    return this.ledgerForm.get('igstLabel');
  }
  get cgstLabel() {
    return this.ledgerForm.get('cgstLabel');
  }
  get sgstLabel() {
    return this.ledgerForm.get('sgstLabel');
  }
}
