import { Injectable } from "@angular/core";
import { itemData } from "src/app/model/itemData.model";
import { ledgerData } from "src/app/model/ledgerData.model";
import { salesData } from "src/app/model/salesData.model";
import { AppConstants } from "./AppConstants";


@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(private appConstants: AppConstants,) { }

    setSalesData(mName, preSalesFormGroup, additionalDataForBill, extraInfoSalesFormGroup, itemCardData, ledgerCardData,additionalItemData,extraDetails) {
        console.log("extraDetails",extraDetails);

        let finalSalesData = new salesData();
        finalSalesData.MName = mName;
        finalSalesData.BillNo = preSalesFormGroup.controls['billNo'].value != null ? preSalesFormGroup.controls['billNo'].value : '';
        finalSalesData.Vcode = preSalesFormGroup.controls['voucherNo'].value != null ? preSalesFormGroup.controls['voucherNo'].value : '';
        finalSalesData.Serial = sessionStorage.getItem("Serial");
        finalSalesData.CompanyCode = sessionStorage.getItem("CompanyCode");
        finalSalesData.ServerVal = Number(sessionStorage.getItem('ServerVal'));
        finalSalesData.BillDate = preSalesFormGroup.controls['billDate'].value != null ? preSalesFormGroup.controls['billDate'].value : '';
        finalSalesData.Acode = preSalesFormGroup.controls['partyName'].value != null && (preSalesFormGroup.controls['partyName'].value != '') && (preSalesFormGroup.controls['partyName'].value != undefined ) ? extraDetails.ledger1Data.find(i => i.name === preSalesFormGroup.controls['partyName'].value)?.code : null;
        finalSalesData.BillAmount = extraDetails.total.toFixed(2);
        finalSalesData.Bcode = additionalDataForBill && additionalDataForBill.consigneeLabel != null && additionalDataForBill.consigneeLabel != '' ? additionalDataForBill.consigneeLabel : null;
        finalSalesData.Consignee = additionalDataForBill && additionalDataForBill.consigneeLabel != null && additionalDataForBill.consigneeLabel != '' ? extraDetails.ledger1Data.find(i => i.code === additionalDataForBill.consigneeLabel) : null;
        finalSalesData.Reference = additionalDataForBill && additionalDataForBill.refNoLabel != null ? additionalDataForBill.refNoLabel : '';
        finalSalesData.RefDate = additionalDataForBill && additionalDataForBill.refDateLabel != null ? additionalDataForBill.refDateLabel : null;
        finalSalesData.Pono = additionalDataForBill && additionalDataForBill.ponoLabel != null ? additionalDataForBill.ponoLabel : '';
        finalSalesData.Podate = additionalDataForBill && additionalDataForBill.poDateLabel != null ? additionalDataForBill.poDateLabel : null;
        finalSalesData.ChallanNo = additionalDataForBill && additionalDataForBill.challanNoLabel != null ? additionalDataForBill.challanNoLabel : '';
        finalSalesData.ChallanDate = additionalDataForBill && additionalDataForBill.challanDateLabel != null ? additionalDataForBill.challanDateLabel : null;
        finalSalesData.Vehicle = extraInfoSalesFormGroup.controls['vehicleNo'].value != null ? extraInfoSalesFormGroup.controls['vehicleNo'].value : '';
        finalSalesData.Transport = extraInfoSalesFormGroup.controls['transport'].value != null ? extraInfoSalesFormGroup.controls['transport'].value : '';
        finalSalesData.Desti = extraInfoSalesFormGroup.controls['destination'].value != null ? extraInfoSalesFormGroup.controls['destination'].value : '';
        finalSalesData.Dispatch = extraInfoSalesFormGroup.controls['dispatch'].value != null ? extraInfoSalesFormGroup.controls['dispatch'].value : '';
        finalSalesData.Sgst = !extraDetails.isIGST ? parseInt(extraDetails.sgst.toFixed(2)) : 0;
        finalSalesData.Cgst = !extraDetails.isIGST ? parseInt(extraDetails.cgst.toFixed(2)) : 0;
        finalSalesData.Igst = extraDetails.isIGST ? parseInt(extraDetails.igst.toFixed(2)) : 0;
        finalSalesData.Cess = 0.00;
        finalSalesData.Utgst = 0.00;
        finalSalesData.IsIGST = extraDetails.isIGST != null ? extraDetails.isIGST ? true : false : false;
        finalSalesData.DocumentNo = ''; // ask
        finalSalesData.UID = extraInfoSalesFormGroup.controls['uid'].value != null ? extraInfoSalesFormGroup.controls['uid'].value : '';;
        finalSalesData.KM = 2;
        finalSalesData.Ucode = null;
        finalSalesData.Narration = extraInfoSalesFormGroup.controls['narration'].value != null ? extraInfoSalesFormGroup.controls['narration'].value : '';
        finalSalesData.Salesmen = additionalDataForBill && additionalDataForBill.salesmenLabel != null && additionalDataForBill.salesmenLabel != '' ? extraDetails.salesMenData.find(i => i.name === additionalDataForBill.salesmenLabel)?.code : '';
        finalSalesData.Bdays = 1;
        finalSalesData.Roundoff = 1;
        finalSalesData.SubTot = extraDetails.subTotal.toFixed(2);
        //itemCardData.forEach((invData) =>
        for (let i = 0; i < itemCardData.length; i++)  {
            const invData = itemCardData[i];
            console.log("invData",invData);

            let inv = new itemData();
            inv.ActualQty = invData.quantityLabel; // 0 will later be changed to Free quantity
            inv.BillDate = preSalesFormGroup.controls['billDate'].value != null ? preSalesFormGroup.controls['billDate'].value : null;
            inv.BilledQty = invData.quantityLabel;
            inv.Rate = invData.rateLabel;
            inv.Amount = invData.amountLabel.toFixed(2);
            inv.Icode = extraDetails.itemList.find(i => i.name === invData.selectItemLabel).code;
            const cgstLabelAsNumber = parseFloat(invData.cgstLabel);
            inv.Cgst = Number(cgstLabelAsNumber.toFixed(2)) || 0;
            const igstLabelAsNumber = parseFloat(invData.igstLabel);
            inv.Igst = Number(igstLabelAsNumber.toFixed(2)) || 0;
            const sgstLabelAsNumber = parseFloat(invData.sgstLabel);
            inv.Sgst = Number(sgstLabelAsNumber.toFixed(2)) || 0;
            inv.DiscPer = 0;
            inv.HSN = invData.hsnLabel;
            inv.Unit = invData.unit;
            inv.Description = invData.description != null ? invData.description :   "";

            inv.Godown = additionalItemData[i] && additionalItemData[i].godownLabel != null ? additionalItemData[i].godownLabel : '' ;
            inv.Pono = additionalItemData[i] && additionalItemData[i] .ponoLabel!= null ? additionalItemData[i].ponoLabel : '' ;
            inv.PoDate = additionalItemData[i] && additionalItemData[i].poDateLabel != null ?additionalItemData[i].poDateLabel:null;
            inv.ChallanDate = additionalItemData[i] && additionalItemData[i].challanDateLabel != null ? additionalItemData[i].challanDateLabel:null;;
            inv.ChallanNo = additionalItemData[i] && additionalItemData[i].challanLabel  != null ? additionalItemData[i].challanLabel : '' ;

            finalSalesData.INVData.push(inv);
        }

        //mapping ledger Data
        ledgerCardData.forEach((item) => {
            let ledData = new ledgerData();
            ledData.BillDate = finalSalesData.BillDate;
            ledData.BillAmount = extraDetails.total.toFixed(2);
            ledData.Amount = item.amount.toFixed(2);
            ledData.Acode = extraDetails.ledger2Data.find(i => i.name === item.ledgerLabel).code;
            ledData.UnderGroup = extraDetails.ledger2Data.find(i => i.name === item.ledgerLabel).group.substring(0, 4);
            // ledData.Rate = 0.00;
            ledData.Rate = item.percentLabel;
            finalSalesData.LedData.push(ledData);
        });

        return finalSalesData;
    }
}
