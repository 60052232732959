import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';

import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';

@Component({
  selector: 'app-payables',
  templateUrl: './payables.component.html',
  styleUrls: ['./payables.component.css']
})
export class PayablesComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master/accounting/bill-adjustment')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @ViewChild('newPayablesForm') newPayablesForm!: TemplateRef<any>;
  @ViewChild('content') content: ElementRef;
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  isLoading: boolean = false;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;
  ledgerData: any;
  url: any = window.location.href;
  recpay: any;
  title: string;
  passData: any;
  billAdjustment: any = [];
  code: string;

  payablesForm = this.fb.group({
    'serialNoLabel': ['', Validators.required],
    'billToLabel': ['', Validators.required],
    'partyLabel': ['', Validators.required],
    'receiptNoLabel': ['', Validators.required],
    'receiptDtLabel': ['', Validators.required],
    'receiptAmountLabel': ['', Validators.required],
    'billNoLabel': ['', Validators.required],
    'billDateLabel': ['', Validators.required],
    'billAmountLabel': ['', Validators.required],
    'adjAmountLabel': ['', Validators.required],
    'creditDayLabel': ['', Validators.required],
    'dueDateLabel': ['', Validators.required],
    'balanceLabel': ['', Validators.required],
  });

  constructor(private masterService: MasterService, private dialog: MatDialog,
    private fb: FormBuilder,
    private appConstants: AppConstants, private toastr: ToastrService,
    private router: Router, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.resetPayablesForm()
    this.getData()
    if (this.url.endsWith("master/accounting/bill-adjustment/receivables")) {
      this.recpay = 'R'
      this.title = "Receivables"
    } else if (this.url.endsWith("master/accounting/bill-adjustment/payables")) {
      this.recpay = 'P'
      this.title = 'Payables'
    }
  }

  getData() {
    this.isLoading = false;
    let listOfItems = ['ledger', 'BillAdjustment'];
    let optionValList = ['0', '0'];
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.ledgerData = response.ledger0;
      if (this.url.endsWith("master/accounting/bill-adjustment/receivables")) {
        var j = 0;
        for (var i = 0; i < response.billAdjustment0.length; i++) {
          if (response.billAdjustment0[i].recPay == "R") {
            this.billAdjustment[j] = response.billAdjustment0[i];
            j = j + 1;
          }
        }
      } else if (this.url.endsWith("master/accounting/bill-adjustment/payables")) {
        var j = 0;
        for (var i = 0; i < response.billAdjustment0.length; i++) {
          if (response.billAdjustment0[i].recPay == "P") {
            this.billAdjustment[j] = response.billAdjustment0[i];
            j = j + 1;
          }
        }
      }
      this.passData = [this.appConstants.billAdjustmentColumnNames, this.billAdjustment, 'billAdjustment0', this.appConstants.billAdjustmentExcelColumnNames];
      this.isLoading = true
    },
      (error => {
        this.passData = [this.appConstants.billAdjustmentColumnNames, false, 'billAdjustment0'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  resetPayablesForm() {
    this.showErrorAlert = false;
    this.payablesForm = this.fb.group({
      'serialNoLabel': ['', Validators.required],
      'billToLabel': ['', Validators.required],
      'partyLabel': ['', Validators.required],
      'receiptNoLabel': ['', Validators.required],
      'receiptDtLabel': ['', Validators.required],
      'receiptAmountLabel': ['', Validators.required],
      'billNoLabel': ['', Validators.required],
      'billDateLabel': ['', Validators.required],
      'billAmountLabel': ['', Validators.required],
      'adjAmountLabel': ['', Validators.required],
      'creditDayLabel': ['', Validators.required],
      'dueDateLabel': ['', Validators.required],
      'balanceLabel': ['', Validators.required],
    });
  }
  addPayables(rowData?: any) {
    if (this.payablesForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      const generatedData = {
        BillTo: this.payablesForm.controls['billToLabel'].value,
        ACode: this.payablesForm.controls['partyLabel'].value,
        RcptNo: this.payablesForm.controls['receiptNoLabel'].value,
        RcptDate: this.payablesForm.controls['receiptDtLabel'].value,
        RcptAmt: this.payablesForm.controls['receiptAmountLabel'].value,
        BillNo: this.payablesForm.controls['billNoLabel'].value,
        BillDate: this.payablesForm.controls['billDateLabel'].value,
        BillAmt: this.payablesForm.controls['billAmountLabel'].value,
        AdjAmt: this.payablesForm.controls['adjAmountLabel'].value,
        CreditDay: this.payablesForm.controls['creditDayLabel'].value,
        DueDate: this.payablesForm.controls['dueDateLabel'].value,
        Balance: this.payablesForm.controls['balanceLabel'].value,
        RecPay: this.recpay,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code,
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updateBillAdjustment(generatedData).subscribe(response => {
          this.getData();
          this.toastr.success("Data Updated Successfully", "Update Success",{
            closeButton: true,
            
          });
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error", {
              closeButton: true,
              
            });
          })
        );
      } else {
        this.masterService.addNewBillAdjustment(generatedData).subscribe(response => {
          this.toastr.success("Data Submitted Successful", "Add Success",
          {
            closeButton: true,
            
          });
          this.getData();
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add Data", "Add Error",{
              closeButton: true,
              
            });
          })
        );
      }

    }

  }


  addNewpayables(rowData?: any) {
    this.resetPayablesForm();
    if (rowData === null || rowData === undefined) {
      this.forView = true;
      this.code = '';
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newPayablesForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true;
      this.patchPayablesFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newPayablesForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteBillAdjustment(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess",{
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert", {
        closeButton: true,
        
      })
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchPayablesFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newPayablesForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchPayablesFormValues(row) {
    this.payablesForm.patchValue({
      'serialNoLabel': sessionStorage.getItem('Serial'),
      'billToLabel': row.billTo,
      'partyLabel': row.aCode,
      'receiptNoLabel': row.rcptNo,
      'receiptDtLabel': this.datePipe.transform(row.rcptDate, 'yyyy-MM-dd'),
      'receiptAmountLabel': row.rcptAmt,
      'billNoLabel': row.billNo,
      'billDateLabel': this.datePipe.transform(row.billDate, 'yyyy-MM-dd'),
      'billAmountLabel': row.billAmt,
      'adjAmountLabel': row.adjAmt,
      'creditDayLabel': row.creditDay,
      'dueDateLabel': this.datePipe.transform(row.dueDate, 'yyyy-MM-dd'),
      'balanceLabel': row.balance,
    })
  }

}
