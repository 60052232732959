<mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>

<div style="width: 100%; height: 100%" *ngIf="isLoading">
  <div class="container" fxLayoutAlign="center center" fxFlexFill>
    <mat-card fxFlex="25">
      <mat-toolbar color="primary">Owner Login</mat-toolbar>
      <form
        fxLayoutAlign="Strech"
        fxLayout="column"
        class="form-class"
        [formGroup]="loginForm"
      >
        <p style="color: red">*All fields are required</p>
        <mat-form-field appearance="outline">
          <mat-label>Serial</mat-label>
          <input
            matInput
            placeholder="Serial"
            formControlName="serialLabel"
            id="serialLabel"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Email Id</mat-label>
          <input
            matInput
            placeholder="Email Id"
            formControlName="userLabel"
            id="userLabel"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input
            matInput
            placeholder="Password"
            [type]="hide ? 'password' : 'text'"
            formControlName="passwordLabel"
            id="passwordLabel"
            required
          />
          <mat-icon style="cursor: pointer" matSuffix (click)="hide = !hide">{{
            hide ? "visibility_off" : "visibility"
          }}</mat-icon>
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          type="submit"
          (click)="login('dashboard')"
        >
          Click Here To Login
        </button>
        <p class="register-msg">
          Don't have an account?
          <button mat-button color="warn" (click)="openComponent('register')">
            Register
          </button>
        </p>
        <div align="center">
          <button
            mat-raised-button
            color="accent"
            (click)="openComponent('customer-login')"
          >
            Customer Login
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</div>
