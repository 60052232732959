import { Component, HostListener, OnInit } from '@angular/core';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReportsService } from 'src/services/reports.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-party-wise-columnar',
  templateUrl: './party-wise-columnar.component.html',
  styleUrls: ['./party-wise-columnar.component.css']
})
export class PartyWiseColumnarComponent implements OnInit {
  sideNavStatus:boolean =false;
  headTitle: string;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key.toUpperCase();
    if (event.key == "Escape") {
      this.openComponent('dashboard/report/misReport')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  disableSelect = new FormControl(false);
  isLoading: boolean = false;
  SalesData: any;
  ledgerArr: any = [];
  itemArr: any = [];
  qtyArry: any = [];
  url: any = window.location.href;


  constructor(
    private fb: FormBuilder, private router: Router,
    private reportService: ReportsService,
    private masterService: MasterService,
    public appConstants: AppConstants) { }

  ngOnInit(): void {
    this.getPartyColumnSalesData();
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }


  getPartyColumnSalesData() {
    if (this.url.endsWith("report/register/party-wise-columnar")) {
      this.isLoading = false;
      let listOfItems = ['Sales'];
      this.headTitle = "Party Wise Columnar";
      this.reportService.getPartyWiseColmnSales(listOfItems).subscribe(response => {
        this.SalesData = response;
        for (let ledger of this.SalesData) {
          if (this.ledgerArr.indexOf(ledger['item Name']) === -1) {
            this.ledgerArr.push(ledger['item Name']);
          }
          if (this.itemArr.indexOf(ledger['ledger Name']) === -1) {
            this.itemArr.push(ledger['ledger Name']);
          }
        }
        // console.log(this.SalesData,this.ledgerArr,this.itemArr);
        
        for (let item of this.itemArr) {
          let currentItem = item;
          let qtyArray = new Array(this.ledgerArr.length);
          qtyArray.fill(0);
          for (let ledgerName of this.ledgerArr) {
            let currentLedger = ledgerName;
            for (let saleBill of this.SalesData) {
              if (saleBill['item Name'] === currentLedger && saleBill['ledger Name'] === currentItem) {
                console.log(saleBill);
                
                qtyArray[this.ledgerArr.indexOf(currentLedger)] += saleBill['qty'];
              }
            }
          }
          this.qtyArry.push({ 'ledgerName': currentItem, 'qty': qtyArray });
        }
        this.isLoading = true;
      })
    }

  }

}


export class keyValue {
  key: String;
  value: String;
}