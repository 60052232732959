import { Component, HostListener, OnInit } from '@angular/core';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReportsService } from 'src/services/reports.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-wise-columnar',
  templateUrl: './item-wise-columnar.component.html',
  styleUrls: ['./item-wise-columnar.component.css']
})
export class ItemWiseColumnarComponent implements OnInit {
  sideNavStatus:boolean =false;
  @HostListener('window:keydown', ['$event'])
  headTitle: string;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key.toUpperCase();
    if (event.key == "Escape") {
      this.openComponent('dashboard/report/misReport')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  disableSelect = new FormControl(false);
  isLoading: boolean = false;
  SalesData: any;
  ledgerArr: any = [];
  itemArr: any = [];
  qtyArry: any = [];
  url: any = window.location.href;


  constructor(
    private fb: FormBuilder, private router: Router,
    private reportService: ReportsService,
    private masterService: MasterService,
    public appConstants: AppConstants) { }

  ngOnInit(): void {
    this.getPartyColumnSalesData();
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  getPartyColumnSalesData() {
    if (this.url.endsWith("report/register/item-wise-columnar")) {
      this.isLoading = false;
      let listOfItems = ['Sales'];
      this.headTitle = "Item Wise Columnar";
      this.reportService.getPartyWiseColmnSales(listOfItems).subscribe(response => {
        this.SalesData = response;
        for (let ledger of this.SalesData) {
          if (this.itemArr.indexOf(ledger['item Name']) === -1) {
            this.itemArr.push(ledger['item Name']);
          }
          if (this.ledgerArr.indexOf(ledger['ledger Name']) === -1) {
            this.ledgerArr.push(ledger['ledger Name']);
          }
        }

        for (let item of this.itemArr) {
          let currentItem = item;
          let qtyArray = new Array(this.ledgerArr.length);
          qtyArray.fill(0);
          for (let ledgerName of this.ledgerArr) {
            let currentLedger = ledgerName;
            for (let saleBill of this.SalesData) {
              if (saleBill['ledger Name'] === currentLedger && saleBill['item Name'] === currentItem) {
                qtyArray[this.ledgerArr.indexOf(currentLedger)] += saleBill['qty'];
              }
            }
          }
          this.qtyArry.push({ 'itemName': currentItem, 'qty': qtyArray });
        }
        this.isLoading = true;
      })
    }
    this.isLoading = true;
  }
}

