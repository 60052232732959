import { AbstractControl, ValidatorFn } from "@angular/forms"

export class AutoCompleteValidator {
  static autocompleteValidator(exists: (value: any) => boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      let selectboxValue = control.value;

      if (selectboxValue !== '') {
        if (exists(selectboxValue)) {
          return null;
        } else {
          return { 'noMatch': true };
        }
      } else { return { 'noMatch': false }; }

    }
  }
}
