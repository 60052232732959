import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InProgressBoxComponent } from 'src/app/shared/in-progress-box/in-progress-box.component';
@Component({
  selector: 'app-final-account',
  templateUrl: './final-account.component.html',
  styleUrls: ['./final-account.component.css']
})
export class FinalAccountComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var key=event.key.toUpperCase();
    if(key == 'T'){
      this.openComponent('')
    }else if(key == 'P'){
      this.openComponent('')
    }else if(key=='B'){
      this.openComponent('')
    }else if(key=='A'){
      this.openComponent('')
    }else if(event.key=="Escape"){
      this.openComponent('dashboard/reports')
    }
  }

  constructor(private router:Router,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }

  InProgressDialog(){
  let dialogRef = this.dialog.open(InProgressBoxComponent,{
    disableClose: true,
    minWidth: '20%',
  minHeight: '15%'});
    dialogRef.afterClosed().subscribe(result => {});
  }
}
