export class ConfirmDialogModel {
  title : string;
  message : string;

  constructor(title : string, message : string) {
    this.title = title;
    this.message = message;
  }

  static ForDelete() {
    return new ConfirmDialogModel('Delete Confirmation', 'Do you want to DELETE this record?');
  }

  static ForLogout() {
    return new ConfirmDialogModel('Logout Confirmation', 'Do you want to logout?');
  }
  static ForConfirm() {
    return new ConfirmDialogModel('Confirmation', 'GST will not be calculated');
  }
}
