import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GodownMasterComponent } from 'src/app/dashboard/master/inventory-master/godown-master/godown-master.component';
import { HsnFormComponent } from './hsn-form/hsn-form.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-inventory-master',
  templateUrl: './inventory-master.component.html',
  styleUrls: ['./inventory-master.component.css']
})
export class InventoryMasterComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key=event.key?.toUpperCase();
    if(key == 'I'){
      this.openComponent('dashboard/master/inventory/item')
    }else if(key == 'K'){
      this.openComponent('dashboard/master/inventory/stock-group')
    }else if(key=='D'){
      this.openComponent('dashboard/master/inventory/godown')
    }else if(key == 'R'){
      this.getError()
    }else if(key == 'P'){
      this.getError()
    }else if(key=='H'){
      this.openComponent('dashboard/master/inventory/hsn')
    }
  }


  constructor(public dialog: MatDialog, private router: Router,private toastr:ToastrService) { }

  ngOnInit(): void {
  }
  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }

  openGodown() {
    const dialogRef = this.dialog.open(GodownMasterComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //Function for alert box
  getError(){
    this.toastr.info('This feature will be available soon!','',{
      timeOut:3000
    });
  }


  }
