import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReportsService } from 'src/services/reports.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-ledger',
  templateUrl: './ledger.component.html',
  styleUrls: ['./ledger.component.css']
})
export class LedgerComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    var key = event.key.toUpperCase();
    if (event.key == "Escape") {
      this.openComponent('dashboard/reports')
    }
  }

  disableSelect = new FormControl(false);

  partyForm = this.fb.group({
    'ledgerlable1': [''],
  });

  ledger1Data: any;
  partyData: any;
  DateFilterData: any = [];
  itemCardData: any = [];
  subTotal: number = 0;
  total: number = 0;
  isLoading: boolean = false;
  headTitle: string;
  salesGroupData: any;
  passData: any[];
  dataSource: MatTableDataSource<any>;
  listOfItems: string[];
  url: any = window.location.href;
  editurl: string;
  rName: string;
  dateRange: FormGroup;
  excelColumn: any = [];
  searchText;
  data: any = [];
  searchBar: FormGroup;
  selectedLevel: any;
  companycode: string;
  optionValList: string[];
  subTotalC: number;
  subTotalD: number;
  tablefillData: any = [];
  filteredOptions: Observable<any[]>;
  openingBalance:string;
  debit:string;
  credit:string;
  totalRowCount:number=0;




  constructor(
    private fb: FormBuilder,
    private masterService: MasterService,
    private reportsService: ReportsService,
    public appConstants: AppConstants,
    private router: Router,
    private datePipe: DatePipe,) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }

    let startYear:number = Number(sessionStorage.getItem('startYear'));
    let startMonth: number = Number(sessionStorage.getItem('startMonth'));


    this.dateRange = this.fb.group({
      'startDate': [new Date(startYear, (startMonth - 1), 1)],
      'endDate': [new Date((startYear + 1), (startMonth - 1), 1)]
    });
    this.getLedger1Data();

    this.filteredOptions = this.partyForm.controls['ledgerlable1'].valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );

  }

  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }

 getLedger1Data() {

    if (this.url.endsWith("report/ledger")) {
      this.isLoading = false;
      this.appConstants.code = this.selectedLevel;
      let listOfItems = ['Ledger'];
      let optionValList = ['0'];
      this.headTitle = "Ledger";
      this.masterService.getSalesDetails(listOfItems, optionValList).subscribe(response => {
        this.ledger1Data = response.ledger0;
        this.tableFill(this.ledger1Data[0]);
      });
    }
    else if (this.url.endsWith("report/cash-book")) {
      this.isLoading = false;
      let listOfItems = ['Ledger'];
      let optionValList = ['3'];
      this.headTitle = "Cash Book";
      this.masterService.getSalesDetails(listOfItems, optionValList).subscribe(response => {
        this.ledger1Data = response.ledger3;
        this.tableFill(this.ledger1Data[0]);
      });
    }
  }

  tableFill(ledgerObj : any) {
    console.log("iiii->", ledgerObj);
    
    this.partyForm.get('ledgerlable1').setValue(ledgerObj?.name)

    this.selectedLevel = ledgerObj.code

    if (this.url.endsWith("report/ledger")) {
      this.isLoading = false;
      this.appConstants.code = this.selectedLevel;
      let listOfItems = ['Ledger'];
      this.appConstants.code = this.selectedLevel;
      this.reportsService.getLedgerDetails(listOfItems).subscribe(response => {
        this.tablefillData = response;
        this.data = response;
        this.passData = [this.appConstants.LedgerReportsColumnsNames,this.generateDataForTable(this.tablefillData)];
        this.dataSource = new MatTableDataSource(this.passData[1]);
        this.openingBalance = ledgerObj.opening;
        this.debit = ledgerObj.debit;
        this.credit = ledgerObj.credit;
        this.filterBasedOnDates();
        this.isLoading = true;
      },
        (error => {
          console.log("error message:-", error);
          this.isLoading = true;
        })
      );

    } else if (this.url.endsWith("report/cash-book")) {
      this.isLoading = false;
      this.headTitle = "Cash Book"
      this.listOfItems = ['Ledger'];
      this.appConstants.code = this.selectedLevel;
      this.companycode = sessionStorage.getItem('CompanyCode');
      this.reportsService.getLedgerDetails(this.listOfItems).subscribe(response => {
        this.tablefillData = response;
        this.data = response;
        this.passData = [this.appConstants.LedgerReportsColumnsNames,this.generateDataForTable(this.tablefillData)];
        this.dataSource = new MatTableDataSource(this.passData[1]);
        this.openingBalance = ledgerObj.opening;
        this.debit = ledgerObj.debit;
        this.credit = ledgerObj.credit;
        this.isLoading = true;
      },
        (error => {
          console.log("error message:-", error);
          this.isLoading = true;
        })
      );
    }
  }

  displayFn(value?: any) {
    if(value === ''){
       let initialValue : string = (this.ledger1Data.find(item=>item.code === this.selectedLevel)).name;
       return initialValue;
    }
    else{
      return value?.name;
    }

  }

  private _filter(value: string): any[] {
    const filterValue = typeof value === "string" ? value.toLowerCase() : null;
    return this.ledger1Data.filter(option => (option.name).toLowerCase().includes(filterValue));
  }
  clearInput() {
    this.partyForm.get('ledgerlable1').reset('')
  }

  calculateSubTotal() {
    this.subTotalC = 0;
    this.subTotalD = 0;
    this.tablefillData.forEach((item) => {
      if (item.drcr == 'C') {
        this.subTotalC += item.amount;
      }
      else if (item.drcr == 'D') {
        this.subTotalD += item.amount;
      }
    });
  }
  applyFilter(event) {
    this.dataSource.filter = event.target.value.trim().toLowerCase();
  }
  calculateTotal() {
    this.total = 0;
    this.tablefillData.forEach((item) => {
      if (item.drcr == 'C') {
        this.total += item.amount;
      };
    });
  }

  AddToDebit(data) {
    if (data.drcr == 'D') {
      return data.amount;
    }
    else {
      return 0;
    }
  }

  AddToCredit(data) {
    if (data.drcr == 'C') {
      return data.amount;
    }
    else {
      return 0;
    }
  }

  filterBasedOnDates() {
    let startDate = null;
    let endDate = null;
    let updatedRows = [];
    this.DateFilterData = this.data;
    let varStore = this.DateFilterData;


    startDate = this.dateRange.controls['startDate'].value != null ? this.datePipe.transform(this.dateRange.controls['startDate'].value, 'MM-dd-yyyy').toString() : '';
    endDate = this.dateRange.controls['endDate'].value != null ? this.datePipe.transform(this.dateRange.controls['endDate'].value, 'MM-dd-yyyy').toString() : '';
    for (let element of varStore) {
      let elementDate = this.datePipe.transform(element.date, 'MM-dd-yyyy').toString();
      if (new Date(startDate) <= new Date(elementDate) && new Date(endDate) >= new Date(elementDate)) {
        updatedRows.push(element);
            }
    }
    if (updatedRows.length > 0) {
      this.tablefillData = this.generateDataForTable(updatedRows);
      this.dataSource = new MatTableDataSource(this.tablefillData);
      this.totalRowCount = this.tablefillData.length;
      this.dataSource.paginator = this.paginator;
    }
    else {
       this.tablefillData = this.generateDataForTable(updatedRows);
       this.dataSource = new MatTableDataSource(this.tablefillData);
      this.totalRowCount = this.tablefillData.length;
      this.dataSource.paginator = this.paginator;
    }
  }

  generateDataForTable(ObservableResponse : any):any{
    var generateDataSource: any[] = [];
    ObservableResponse.forEach((item: any) => {
    var generatedData: any = {};
    generatedData.date = item.date;
    generatedData.voucher = item.voucher;
    generatedData['bill No'] = item['bill No'];
    generatedData.debit = this.AddToDebit(item);
    generatedData.credit = this.AddToCredit(item);
    generateDataSource.push(generatedData);
  });
  return generateDataSource;
  }

}
