import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MasterService } from 'src/services/master.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-excel-download-table',
  templateUrl: './excel-download-table.component.html',
  styleUrls: ['./excel-download-table.component.css']
})
export class ExcelDownloadTableComponent implements OnInit {
  isLoading: boolean = false;
  ShowLabel1: boolean = false;
  ShowLabel2: boolean = false;
  ShowLabel3: boolean = false;
  excelData: any;
  responseInv: any;
  ledger1Data: any = [];
  excelColumnData: any;
  url: any = window.location.href;
  fileName: any;

  constructor(private route: ActivatedRoute, private datePipe: DatePipe,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private masterService: MasterService,) { }

  ngOnInit(): void {
    this.responseInv = JSON.parse(sessionStorage.getItem("responseInv"))
    this.excelColumnData = JSON.parse(sessionStorage.getItem("excelColumnData"));
    // Changing File name according the url while saving the excel page
    if (this.url.endsWith("report/register/sales/excelDownloadTable")) {
      this.fileName = 'saleBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/purchase/excelDownloadTable")) {
      this.fileName = 'purchaseBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/debit-note/excelDownloadTable")) {
      this.fileName = 'debitNoteBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/credit-note/excelDownloadTable")) {
      this.fileName = 'creditNoteBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/material-in/excelDownloadTable")) {
      this.fileName = 'materialinBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/material-out/excelDownloadTable")) {
      this.fileName = 'materialoutBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/production/excelDownloadTable")) {
      this.fileName = 'productionBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/consumption/excelDownloadTable")) {
      this.fileName = 'consumptionBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/sales-order/excelDownloadTable")) {
      this.fileName = 'saleOrderBillExcel.xlsx'
      this.ShowLabel1 = true
      this.getData()
    } else if (this.url.endsWith("report/register/receipt/excelDownloadTable")) {
      this.fileName = 'receiptBillExcel.xlsx'
      this.ShowLabel2 = true
      this.isLoading = true
    } else if (this.url.endsWith("report/register/payment/excelDownloadTable")) {
      this.fileName = 'paymentBillExcel.xlsx'
      this.ShowLabel2 = true
      this.isLoading = true
    } else if (this.url.endsWith("report/register/contra/excelDownloadTable")) {
      this.fileName = 'contraBillExcel.xlsx'
      this.ShowLabel2 = true
      this.isLoading = true
    } else if (this.url.endsWith("report/register/deposit/excelDownloadTable")) {
      this.fileName = 'depositBillExcel.xlsx'
      this.ShowLabel2 = true
      this.isLoading = true
    } else if (this.url.endsWith("report/register/withdraw/excelDownloadTable")) {
      this.fileName = 'withdrawBillExcel.xlsx'
      this.ShowLabel2 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/accounting/ledger/excelDownloadTable")) {
      this.fileName = 'ledgerExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/accounting/voucher/excelDownloadTable")) {
      this.fileName = 'voucherExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/accounting/group/excelDownloadTable")) {
      this.fileName = 'groupExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/accounting/cost-center/excelDownloadTable")) {
      this.fileName = 'cost-centerExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/accounting/salesmen/excelDownloadTable")) {
      this.fileName = 'salesmenExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/accounting/bill-adjustment/receivables/excelDownloadTable")) {
      this.fileName = 'receivablesExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/accounting/bill-adjustment/payables/excelDownloadTable")) {
      this.fileName = 'payablesExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/inventory/item/excelDownloadTable")) {
      this.fileName = 'itemExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/inventory/stock-group/excelDownloadTable")) {
      this.fileName = 'stock-groupExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/inventory/godown/excelDownloadTable")) {
      this.fileName = 'godownExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/inventory/sales-price/excelDownloadTable")) {
      this.fileName = 'sales-priceExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/inventory/purchase-cost/excelDownloadTable")) {
      this.fileName = 'purchase-costExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    } else if (this.url.endsWith("master/inventory/hsn/excelDownloadTable")) {
      this.fileName = 'hsnExcel.xlsx'
      this.ShowLabel3 = true
      this.isLoading = true
    }
  }


  getData() {
    let listOfLedgers = ['Ledger'];
    let optionValList = ['1'];
    this.masterService.getSalesDetails(listOfLedgers, optionValList).subscribe(response => {
      this.ledger1Data = response.ledger1;
      this.isLoading = true;
    })
  }
  exportToExcel() {
    /* table id is passed over here */
    let element = document.getElementById('panel');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  ChangeToBillState(data) {
    for (var i = 0; i < this.ledger1Data.length; i++) {
      if (this.ledger1Data[i].code == data) {
        return this.ledger1Data[i].state
      }
    }
  }
  ChangeToEmail(data) {
    for (var i = 0; i < this.ledger1Data.length; i++) {
      if (this.ledger1Data[i].code == data) {
        return this.ledger1Data[i].email
      }
    }
  }
  ChangeToPinCode(data) {
    for (var i = 0; i < this.ledger1Data.length; i++) {
      if (this.ledger1Data[i].code == data) {
        return this.ledger1Data[i].pinCode
      }
    }
  }
  ChangeToShipState(data) {
    for (var i = 0; i < this.ledger1Data.length; i++) {
      if (this.ledger1Data[i].code == data) {
        return this.ledger1Data[i].state
      }
    }
  }
  ChangeToItemName(data) {
    for (var i = 0; i < this.responseInv.length; i++) {
      if (this.responseInv[i].iCode == data) {
        return this.responseInv[i].name
      }
    }
  }
  ChangeToCgst(data) {
    for (var i = 0; i < this.responseInv.length; i++) {
      if (this.responseInv[i].iCode == data) {
        return this.responseInv[i].sgst
      }
    }
  }
  ChangeToSgst(data) {
    for (var i = 0; i < this.responseInv.length; i++) {
      if (this.responseInv[i].iCode == data) {
        return this.responseInv[i].sgst
      }
    }
  }
  ChangeToIgst(data) {
    for (var i = 0; i < this.responseInv.length; i++) {
      if (this.responseInv[i].iCode == data) {
        return this.responseInv[i].igst
      }
    }
  }
  ChangeDateFormat(data) {
    return this.datePipe.transform(data, 'dd/MM/yyyy')
  }

  renameColumn(columnName) {
    return columnName;
  }

  renameData(data) {
    return data
  }
}
