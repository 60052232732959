<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh; width: 96vw;padding: 0 2rem; overflow: scroll;">
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
                <mat-toolbar>
                    <nav class="headTitle">{{headTitle}}</nav>
                </mat-toolbar>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
                <div *ngIf="isLoading">
                    <div class="container">
                        <table id="thTable">
                            <tr>
                                <th></th>
                                <th *ngFor="let ledger of ledgerArr;">
                                    {{ledger || '-'}}
                                </th>
                                <th></th>
                            </tr>
                            <tr *ngFor="let item of qtyArry;">
                                <td>{{item?.ledgerName|| '-'}}</td>
                                <td *ngFor="let qty of item?.qty;">
                                    {{qty}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>