import { Component, ElementRef, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter } from 'events';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-cost',
  templateUrl: './purchase-cost.component.html',
  styleUrls: ['./purchase-cost.component.css']
})
export class PurchaseCostComponent implements OnInit {
  sideNavStatus:boolean =false;
  itemData: any;
  partyData: any;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @ViewChild('newPurchaseCostForm') newPurchaseCostForm!: TemplateRef<any>;
  @ViewChild('content') content: ElementRef;
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;
  passData: any;
  isLoading: boolean = false;
  data!: any;
  purchaseCostFormData: any;


  purchaseCostForm = this.fb.group({
    'partyLabel': ['', Validators.required],
    'itemNameLabel': ['', Validators.required],
    'purchaseRateLabel': ['', Validators.required],
    'discountLabel': ['', Validators.required],
    'partNoLabel': ['', Validators.required],
  });
  code: any;

  ;
  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.resetPurchaseCostForm();
    this.getPurchaseCostData();
    console.clear()
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  getPurchaseCostData() {
    this.isLoading = false;
    let listOfItems = ['Purchase Cost', 'Item', 'Ledger'];
    let optionValList = ['0', '0', '1'];
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message")
      this.purchaseCostFormData = response['purchase Cost0'];
      this.itemData = response.item0;
      this.partyData = response.ledger1;
      this.passData = [this.appConstants.purchaseCostFormColumnNames, this.purchaseCostFormData, 'purchaseCostForm', this.appConstants.purchaseCostExcelColumnNames];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.purchaseCostFormColumnNames, false, 'purchaseCostForm'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  resetPurchaseCostForm() {
    this.showErrorAlert = false;
    this.purchaseCostForm = this.fb.group({
      'partyLabel': ['', Validators.required],
      'itemNameLabel': ['', Validators.required],
      'purchaseRateLabel': ['', Validators.required],
      'discountLabel': ['', Validators.required],
      'partNoLabel': ['', Validators.required],
    });
  }

  addPurchaseCost(rowData?: any) {
    if (this.purchaseCostForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem("Serial"),
        Rate: this.purchaseCostForm.controls['purchaseRateLabel'].value,
        DiscountPer: this.purchaseCostForm.controls['discountLabel'].value,
        PartNo: this.purchaseCostForm.controls['partNoLabel'].value,
        CompanyCode: sessionStorage.getItem("CompanyCode"),
        ServerVal: sessionStorage.getItem("ServerVal"),
        Code: !this.isEditFlow ? '' : this.code,
        ACode: this.purchaseCostForm.controls['partyLabel'].value,
        ICode: this.purchaseCostForm.controls['itemNameLabel'].value,//need to confirm
        MName: 'Purchase Cost'
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        this.masterService.updatePurchaseCost(generatedData).subscribe(response => {
          this.getPurchaseCostData();
          this.toastr.success("Data Updated Successfully", "Update Success")
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          })
        );
      } else {
        this.masterService.addNewPurchaseCost(generatedData).subscribe(response => {
          this.getPurchaseCostData()
          this.toastr.success("Data added Successful", "Add Success");
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add New Data", "Add Error");
          })
        );
      }
    }
  }

  addNewPurchaseCost(rowData?: any) {
    this.resetPurchaseCostForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newPurchaseCostForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.patchPurchaseCostFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newPurchaseCostForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem("Serial"),
        CompanyCode: sessionStorage.getItem("CompanyCode"),
        ServerVal: sessionStorage.getItem("ServerVal"),
        Code: rowData.row.code,
        MName: 'Purchase Cost'
      }
      this.masterService.deletePurchaseCost(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
        this.getPurchaseCostData();
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
          this.getPurchaseCostData();
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchPurchaseCostFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newPurchaseCostForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchPurchaseCostFormValues(row) {
    this.purchaseCostForm.patchValue({
      'serialNoLabel': row.serial,
      'partyLabel': row.aCode,
      'itemNameLabel': row.iCode,
      'purchaseRateLabel': row.rate,
      'discountLabel': row.discountPer,
      'partNoLabel': row.partNo,
    })
  }
}


