<div style="height: 100vh; padding-left: 0%;">
  <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
  <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
  <div class="HomeMainDiv">
      <mat-drawer-container class="example-container">
          <mat-drawer-content style="min-height: 90vh;width: 97vw; padding: 0 0 0 3rem; overflow: hidden;">

              <div class="main-container">
                  <mat-toolbar>
                      <nav class="headTitle" style="margin-right: 80px">{{this.headTitle}}</nav>
                      <mat-icon class="pdfIcon" (click)="pdfWork()">
                          picture_as_pdf
                      </mat-icon>
                  </mat-toolbar>
              </div>

              <!-- ************* -->
              <!-- top input fieds -->
              <!-- ************* -->
              <mat-card class="divide">
                  <form [formGroup]="preSalesFormGroup">
                      <table>
                          <tr>
                              <td *ngIf="showLabel">
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Voucher No.</mat-label>
                                      <input type="text" [disabled]="showLabel" matInput formControlName="billNo">
                                      <mat-error *ngIf="preSalesFormGroup.get('billNo').hasError('duplicateValue')">Duplicate Voucher Number</mat-error>
                                  </mat-form-field>

                              </td>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Voucher</mat-label>
                                      <mat-select formControlName="voucherNo"
                                          (selectionChange)="updateBillDateAndNo($event)">
                                          <mat-option disabled>Select</mat-option>
                                          <mat-option *ngFor="let voucher of voucherData;" [value]="voucher.code">
                                              {{voucher.name}}</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                              </td>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Date</mat-label>
                                      <input type="date" [min]="minDate" [max]="maxDate" matInput
                                          formControlName="billDate">
                                  </mat-form-field>
                              </td>
                          </tr>
                      </table>
                      <table *ngIf="showLabel2">
                          <tr>
                              <!-- <td style="padding-right: 1%;"> -->
                                <!-- <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label id="partyName">Party Name</mat-label>
                                      <mat-select formControlName="partyName"
                                          (selectionChange)="saveStateCode($event.value)">
                                          <mat-option [value]=null disabled>Select</mat-option>
                                          <mat-option *ngFor="let ledger1 of ledger1Data" [value]="ledger1.name">
                                              {{ledger1.name}}</mat-option>
                                      </mat-select>
                                  </mat-form-field>
                              </td> -->

                              <td>
                                <mat-form-field class="example-full-width" appearance="fill">
                                  <mat-label>Party Name</mat-label>
                                <input type="text" placeholder="Pick one" aria-label="Number" class="form-control" id="partyName" formControlName="partyName" matInput
                                [matAutocomplete]="auto">
                                <mat-autocomplete  #auto="matAutocomplete" (optionSelected)="saveStateCode($event.option.value)">
                                  <mat-option [value]="" disabled>-Select Option-</mat-option>
                                  <mat-option *ngFor="let option of filteredOptions|async" [value]="option.name">{{option.name}}</mat-option>
                                  </mat-autocomplete>
                                  </mat-form-field>
                                  <mat-error>
                                    <div *ngIf="preSalesFormGroup.get('partyName').hasError('noMatch')">
                                      {{validationMsg}}
                                    </div>
                                  </mat-error>
                              </td>
                              <!-- <td style="padding-left: 1%;"> -->
                                <td>
                                  <span class="add" (click)="openLadger()">+</span>
                              </td>
                              <td style="width: 20.5%;">
                                  <p>State:<span style="padding-left: 15px;">{{stateSelected}}</span></p>
                              </td>
                              <td style="width: 20.5%;">
                                  <p>Days: <span style="padding-left: 15px;">{{daysSelected}}</span></p>
                              </td>
                          </tr>
                      </table>
                      <p class="additional-msg">To add more details click<button mat-button (click)="openAddDetail()"
                              class="click-here">here</button></p>
                  </form>
              </mat-card>

              <!-- ***************** -->
              <!-- *****item list*** -->
              <!-- ***************** -->
              <mat-card class="divide" style="background-color: rgba(236, 231, 225, 0.877); margin-bottom: 0px; padding-bottom: 0px;">
              <div class="row" style="display: flex;">
                <h3 class="item-list">Item List</h3>
                <mat-icon class="material-icons" (click)="openAddItem()">add_shopping_cart</mat-icon>
              </div>
                  <div id="itemTable" #itemTable>
                      <table class="itemTable" *ngIf="itemCardData.length > 0" >
                          <tr>
                              <th *ngFor="let col of appConstants.salesBillItemTableColumnNames">{{col}}</th>
                          </tr>
                          <tr *ngFor="let item of itemCardData ; let i = index">
                              <td>{{i+1}}<br><span class="itemName">Description :-</span></td>
                              <td class="itemNameLabel">{{item.selectItemLabel}}<br><span
                                      class="itemName">{{item.description}}</span></td>
                              <td>{{item.hsnLabel}}</td>
                              <td>{{item.quantityLabel}}</td>
                              <td>{{item.rateLabel}}</td>
                              <td>{{(item.discount != null && item.discount != undefined) ? item.discount : 0}}</td>
                              <td>{{item.unit}}</td>
                              <td>{{item.amountLabel}}</td>
                              <td>
                                  <button mat-icon-button (click)="openEachItemDetail(item)">
                                      <mat-icon>more_vert</mat-icon>
                                  </button>
                              </td>
                              <td>
                                  <button mat-icon-button (click)="editItem(item,i)">
                                      <mat-icon>edit</mat-icon>
                                  </button>
                                  <button mat-icon-button color="warn" (click)="deleteItem(item, i)">
                                      <mat-icon>delete</mat-icon>
                                  </button>
                              </td>
                          </tr>
                          <tr class="subTotal">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>Sub Total</td>
                              <td>{{subTotal | number: '1.2-2' }}</td>
                          </tr>
                      </table>
                  </div>
              </mat-card>

              <!--************ -->
              <!-- ledger list -->
              <!--*************-->
              <ng-container *ngIf="ledgerDisplayFlag">
              <mat-card class="divide" >
                <div class="row" style="display: flex;">
                  <h3 class="item-list">Ledger List</h3>
                  <mat-icon (click)="openAddLedger()">person_add_alt_1</mat-icon>
                </div>
                  <form [formGroup]="extraInfoSalesFormGroup" class="legerList">
                      <table class="leftSideTable">
                           <tr>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Transport</mat-label>
                                      <input type="text" placeholder="transport" matInput formControlName="transport">
                                  </mat-form-field>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Vehicle No.</mat-label>
                                      <input type="text" placeholder="vehicleNo" matInput formControlName="vehicleNo">
                                  </mat-form-field>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Destination</mat-label>
                                      <input type="text" placeholder="destination" matInput
                                          formControlName="destination">
                                  </mat-form-field>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Dispatch</mat-label>
                                      <input type="text" placeholder="dispatch" matInput formControlName="dispatch">
                                  </mat-form-field>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>Narration</mat-label>
                                      <input type="text" placeholder="narration" matInput formControlName="narration">
                                  </mat-form-field>
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <mat-form-field appearance="fill" class="example-full-width">
                                      <mat-label>KM</mat-label>
                                      <input type="text" placeholder="km" matInput formControlName="km">
                                  </mat-form-field>
                              </td>
                          </tr>
                      </table>

                      <table class="rightSideTable">

                          <tr *ngFor="let ledger of ledgerCardData">
                              <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label>{{ledger.ledgerLabel}}</mat-label>
                                  <input [disabled] ="ledgerInputDisable" type="text" matInput [value]="ledger.amount">
                              </mat-form-field>
                          </tr>
                          <tr
                              *ngIf="this.ledger1StateCode != null && this.ledger1StateCode != undefined && this.isIGST && this.itemCardData.length > 0">
                              <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label>IGST</mat-label>
                                  <input type="text" matInput placeholder="igst" formControlName="igst" (keyup)="calculateTotal()">
                              </mat-form-field>
                          </tr>
                          <tr
                              *ngIf="this.ledger1StateCode != null && this.ledger1StateCode != undefined && !this.isIGST && this.itemCardData.length > 0">
                              <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label>SGST</mat-label>
                                  <input type="text" matInput placeholder="sgst" formControlName="sgst" (keyup)="calculateTotal()">
                              </mat-form-field>
                          </tr>
                          <tr
                              *ngIf="this.ledger1StateCode != null && this.ledger1StateCode != undefined && !this.isIGST && this.itemCardData.length > 0">
                              <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label>CGST</mat-label>
                                  <input type="text" matInput placeholder="cgst" formControlName="cgst" (keyup)="calculateTotal()">
                              </mat-form-field>
                          </tr>
                          <tr>
                              <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label>Cess</mat-label>
                                  <input type="text" placeholder="cess" matInput>
                              </mat-form-field>
                          </tr>
                          <tr>
                              <mat-form-field appearance="fill" class="example-full-width">
                                  <mat-label>Utgst</mat-label>
                                  <input type="text" placeholder="utgst" matInput>
                              </mat-form-field>
                          </tr>
                      </table>
                  </form>
              </mat-card>
              </ng-container>

            <ng-container *ngIf="narrationDisplayFlag">
              <mat-card class="divide" >
                <form [formGroup]="extraInfoSalesFormGroup" class="legerList">
                    <table class="leftSideTable">
                        <tr>
                            <td>
                                <mat-form-field appearance="fill" class="example-full-width">
                                    <mat-label>Narration</mat-label>
                                    <input type="text" placeholder="narration" matInput formControlName="narration">
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>
                </form>
            </mat-card>
            </ng-container>


              <!-- *********** -->
              <!-- Button work -->
              <!-- *********** -->
              <mat-card class="divide total">
                  <button mat-flat-button  style="margin-left: 170px;" [disabled] = "disableSubmitButton" color="primary" class="sBtn {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="submitSalesData()" *ngIf="editFlow">Submit</button>
                  <button mat-flat-button  style="margin-left: 170px;" color="primary" class="sBtn" *ngIf="!editFlow" (click)="backClicked()">Back</button>
                  <p>Total : Rs. {{total | number: '1.2-2'}}</p>
              </mat-card>
              <!--<mat-card fxFlex='100' class="mBtn" *ngIf="editFlow">
                  <button mat-flat-button color="primary" class="sBtn" (click)="submitSalesData()">Submit</button>
              </mat-card>
              <mat-card fxFlex='100' class="mBtn" *ngIf="!editFlow">
                  <button mat-flat-button color="primary" class="sBtn" (click)="backClicked()">Back</button>
              </mat-card>-->
              <!-- <button mat-raised-button class="btn" color="primary" (click)="openAdditonalDetailOne()">additonal-detail-one</button> -->


          </mat-drawer-content>
      </mat-drawer-container>
  </div>
  <!-- <app-footer></app-footer> -->
</div>
