import { Component, ElementRef, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { AppConstants } from 'src/services/AppConstants';

import { MasterService } from 'src/services/master.service';
import * as jsPDF from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.css']
})
export class GroupFormComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key == "Escape") {
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string) {
    this.router.navigate(['/' + component]);
  }
  @ViewChild('newGroupForm') newGroupForm!: TemplateRef<any>;
  @ViewChild('content') content: ElementRef;
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView: boolean = true;
  passData!: any;
  isLoading: boolean = false;
  data!: any;
  groupFormData: any;
  groupData: any;
  code: any;
  pcode: any;
  prow: void;


  groupForm = this.fb.group({
    'groupNameLabel': ['', Validators.required],
    'newGroupLabel': ['', Validators.required],
  })

  constructor(private fb: FormBuilder, private router: Router,
    private masterService: MasterService,
    private dialog: MatDialog,
    private appConstants: AppConstants,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.resetGroupForm();
    this.getGroupData();
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  getGroupData() {
    this.isLoading = false;
    let listOfItems = ['Group', 'Group'];
    let optionValList = ['0', '1'];
    this.masterService.getItem(listOfItems, optionValList).subscribe(response => {
      this.toastr.info("Data Retrived Successfully", "Data Retrive Message")
      this.groupFormData = response.group0;
      this.groupData = response.group1;
      this.passData = [this.appConstants.groupFormColumnNames, this.groupFormData, 'groupForm', this.appConstants.groupExcelColumnNames];
      this.isLoading = true;
    },
      (error => {
        this.passData = [this.appConstants.groupFormColumnNames, false, 'groupForm'];
        this.isLoading = true;
        this.toastr.error("Unable To Retrieve Data", "Data Retrieve Error");
      })
    );
  }

  resetGroupForm() {
    this.showErrorAlert = false;
    this.groupForm = this.fb.group({
      'groupNameLabel': ['', Validators.required],
      'newGroupLabel': ['', Validators.required],
    });
  }

  addGroup(rowData?: any) {
    if (this.groupForm.invalid) {
      this.showErrorAlert = true;
    } else {
      this.showErrorAlert = false;
      this.isLoading = false;
      const generatedData = {
        Main: this.groupForm.controls['groupNameLabel'].value,
        Name: this.groupForm.controls['newGroupLabel'].value,
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: !this.isEditFlow ? '' : this.code
      }
      this.dialog.closeAll();
      if (this.isEditFlow) {
        for (var i = 0; i < this.groupData.length; i++) {
          if (this.prow == this.groupData[i].groupBase) {
            if (this.groupData[i].code != this.groupForm.controls['groupNameLabel'].value) {
              this.code = this.groupForm.controls['groupNameLabel'].value;
            }
          }
        }

        this.masterService.updateGroup(generatedData).subscribe(response => {
          this.getGroupData();
          this.toastr.success("Data Updated Successfully", "Update Success")
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Update Data", "Update Error");
          })
        );
      } else {
        this.masterService.addNewGroup(generatedData).subscribe(response => {
          this.toastr.success("Data added Successful", "Add Success");
          this.getGroupData()
        },
          (error => {
            this.isLoading = true;
            this.toastr.error("Unable To Add New Data", "Add Error");
          })
        );
      }
    }
  }

  addNewgroup(rowData?: any) {
    this.resetGroupForm();
    if (rowData === null || rowData === undefined) {
      this.code = '';
      this.forView = true
      this.isEditFlow = false;
      let dialogRef = this.dialog.open(this.newGroupForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain == 0)) {
      this.isEditFlow = rowData.editFlow ? true : false;
      this.forView = true
      this.patchGroupFormValues(rowData.row);
      this.prow = rowData.row.groupBase;
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newGroupForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } else if ((rowData != null || rowData != undefined) && rowData.deleteFlow) {
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading = false;
      const generatedData = {
        Serial: sessionStorage.getItem('Serial'),
        CompanyCode: sessionStorage.getItem('CompanyCode'),
        ServerVal: sessionStorage.getItem('ServerVal'),
        Code: rowData.row.code
      }
      this.masterService.deleteGroup(generatedData).subscribe(response => {
        this.isLoading = true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
        (error => {
          this.isLoading = true;
          this.toastr.error("Unable to Delete Data", "Delete Unsuccess", {
            closeButton: true,
            
          });
        })
      );
    } else if ((rowData != null || rowData != undefined) && rowData.editFlow && (rowData.row.isMain != 0)) {
      this.toastr.warning("You don't have sufficient privilege to Edit this Data", "Edit Alert")
    } else if ((rowData != null || rowData != undefined) && rowData.viewFlow) {
      this.forView = false
      this.patchGroupFormValues(rowData.row);
      this.code = rowData.row.code;
      let dialogRef = this.dialog.open(this.newGroupForm, {
        disableClose: true,
        minWidth: '60%'
      });
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  patchGroupFormValues(row) {
    for (var i = 0; i < this.groupData.length; i++) {
      if (row.groupBase == this.groupData[i].groupBase) {
        this.pcode = this.groupData[i].code
      }
    }
    this.groupForm.patchValue({
      'groupNameLabel': this.pcode,
      'newGroupLabel': row.name,
    })
  }
}


