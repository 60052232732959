<button mat-raised-button class="btn" color="primary"
    (click)="openComponent('dashboard/master/accounting/ledger')"><span
        class="highlighter">L</span>edger</button><br><br>
<button mat-raised-button class="btn" color="primary"
    (click)="openComponent('dashboard/master/accounting/voucher')"><span
        class="highlighter">V</span>oucher</button><br><br>
<button mat-raised-button class="btn" color="primary" (click)="openComponent('dashboard/master/accounting/group')"><span
        class="highlighter">G</span>roup</button><br><br>
<button mat-raised-button class="btn" color="primary" (click)="openComponent('dashboard/master/accounting/cost-center')"><span class="highlighter">C</span>ost
    Center</button><br><br>
<button mat-raised-button class="btn" color="primary"
    (click)="openComponent('dashboard/master/accounting/salesmen')"><span
        class="highlighter">S</span>ales Person</button><br><br>
<button mat-raised-button class="btn" color="primary"
    (click)="openComponent('dashboard/master/accounting/bill-adjustment')"><span class="highlighter">B</span>ill
    Adjustment</button><br><br>