<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh;width: 100vw; padding: 0 0 0 3rem; overflow: hidden;">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous">
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>

            <!-- Head part -->
            <div class="main-containers">
                <mat-toolbar>
                    <nav class="headTitle">Group</nav>
                </mat-toolbar>
            </div>
            <div *ngIf="isLoading">

                <button mat-button class="add-btn" (click)="addNewgroup()">Add New</button>
                <ng-template #newGroupForm>
                    <mat-dialog-content>
                        <p class="cross" mat-dialog-close>&#10005;</p>
                        <h1 mat-dialog-title class="companytitle">GROUP</h1>
                        <mat-card>
                            <mat-card-content>
                                <form [formGroup]="groupForm" class="mainForm">
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="groupNameLabel"><span class="required">*</span>Group Name</label>
                                        </div>
                                        <div class="col-sm-8">
                                            <!-- <input type="text" class="form-control"
                                formControlName="groupNameLabel"
                                placeholder="LG"
                                id="groupNameLabel"> -->
                                            <select formControlName="groupNameLabel" id="groupNameLabel"
                                                class="form-control">
                                                <option [value]=null>Select</option>
                                                <option *ngFor="let groupNameLabel of groupData"
                                                    [value]="groupNameLabel.code">{{groupNameLabel.name}}</option>
                                            </select>
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && groupForm.get('groupNameLabel').hasError('required')">
                                                Group Name is required
                                            </mat-error>
                                        </span>

                                    </div>
                                    <div class="form-group row">
                                        <div class="col-sm-4">
                                            <label for="newGroupLabel"><span class="required">*</span>New Group Name </label>
                                        </div>
                                        <div class="col-sm-8">
                                            <input class="form-control" formControlName="newGroupLabel" placeholder="LG"
                                                id="newGroupLabel" [readonly]="!forView">
                                        </div>
                                        <span id="spn">
                                            <mat-error
                                                *ngIf="showErrorAlert && groupForm.get('newGroupLabel').hasError('required')">
                                                New Group Name is required
                                            </mat-error>
                                        </span>

                                    </div>
                                </form>
                            </mat-card-content>
                        </mat-card>
                        <mat-dialog-actions align="center" *ngIf="forView">
                            <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addGroup($event)">Submit</button>
                            <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                        </mat-dialog-actions>
                    </mat-dialog-content>
                </ng-template>
                <app-table [recievedData]="passData" (groupFormComp)="addNewgroup($event)" id="content" #content>
                </app-table>
            </div>
            
        </mat-drawer-content>
    </mat-drawer-container>
    <!-- <app-footer></app-footer> -->
</div>