<div class="cardContainer" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
    <!-- Card Cantent -->
    <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/inventory/material-in')">
        <mat-card-title class="cardTitle" >
            <span class="highlighter">M</span>aterial In</mat-card-title>
        <mat-card-content class="cardContent">
            <mat-icon (click)="openComponent('dashboard/report/register/material-in')"
            matTooltip="Material In Register">analytics</mat-icon>
        </mat-card-content>
        <mat-card-actions align="end">
        </mat-card-actions>
    </mat-card>
    <!-- Card Cantent -->
    <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/inventory/material-out')">
        <mat-card-title class="cardTitle">
            Material O<span class="highlighter">u</span>t</mat-card-title>
        <mat-card-content class="cardContent">
            <mat-icon (click)="openComponent('dashboard/report/register/material-out')"
            matTooltip="Material Out Register">analytics</mat-icon>
        </mat-card-content>
        <mat-card-actions align="end">
        </mat-card-actions>
    </mat-card>
    <!-- Card Cantent -->
    <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/inventory/production')">
        <mat-card-title class="cardTitle" >
            Product<span class="highlighter">i</span>on
        </mat-card-title>
        <mat-card-content class="cardContent">
            <mat-icon (click)="openComponent('dashboard/report/register/production')"
            matTooltip="Production Register">analytics</mat-icon>
        </mat-card-content>
        <mat-card-actions align="end">
        </mat-card-actions>
    </mat-card>
    <!-- Card Cantent -->
    <mat-card class="cardProduct" (click)="openComponent('dashboard/transactions/inventory/consumption')">
        <mat-card-title class="cardTitle" >
            <span class="highlighter">C</span>onsumption
        </mat-card-title>
        <mat-card-content class="cardContent">
            <mat-icon (click)="openComponent('dashboard/report/register/consumption')"
            matTooltip="Consumption Register">analytics</mat-icon>
        </mat-card-content>
        <mat-card-actions align="end">
        </mat-card-actions>
    </mat-card>
</div>
