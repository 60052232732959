<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh;width: 100vw; padding: 0 0 0 3rem; overflow: hidden;">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous">
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>

            <!-- Head part -->
            <div class="main-containers">
                <mat-toolbar>
                    <nav class="headTitle">Voucher</nav>
                </mat-toolbar>
            </div>
            <div *ngIf="isLoading">
                <button mat-button class="add-btn" (click)="addNewvoucher()">Add New</button>
                <ng-template #newVoucherForm>
                    <mat-dialog-content>
                        <p class="cross" mat-dialog-close>&#10005;</p>
                        <div class="container">
                            <h1 mat-dialog-title class="companytitle">Voucher</h1>

                          <!-- Voucher Form -->
                            <mat-card>
                                <mat-card-content>
                                    <form [formGroup]="voucherForm" class="mainForm">
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="voucherLabel"><span class="required">*</span>Voucher Name </label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input class="form-control" formControlName="voucherLabel"
                                                    placeholder="VoucherName" id="voucherLabel" [readonly]="!readView">
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('voucherLabel').hasError('required')">
                                                    Voucher Name is required </mat-error>
                                            </span>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="prefixLabel"><!-- <span class="required">*</span> -->Prefix </label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" (input)="patchBill()"
                                                    class="form-control" formControlName="prefixLabel"
                                                    placeholder="prefix" id="prefixLabel" [readonly]="!forView">
                                            </div>
                                            <!-- <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('prefixLabel').hasError('required')">
                                                    Prefix is required </mat-error>
                                            </span> -->
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="suffixLabel"><!-- <span class="required">*</span> -->Suffix </label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" (input)="patchBill()"
                                                    class="form-control" formControlName="suffixLabel"
                                                    placeholder="Suffix" id="suffixLabel" [readonly]="!forView">
                                            </div>
                                            <!-- <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('suffixLabel').hasError('required')">
                                                    Suffix is required </mat-error>
                                            </span> -->
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="startLabel"><!-- <span class="required">*</span> -->Start With </label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="number" (keypress)="keyPressNumbers($event)"
                                                    class="form-control" formControlName="startLabel"
                                                    placeholder="Write here" id="startLabel" [readonly]="!forView">
                                            </div>
                                            <!-- <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('startLabel').hasError('required')">
                                                    Start With is required </mat-error>
                                            </span> -->
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="typeLabel"><span class="required">*</span>Voucher Type </label>
                                            </div>
                                            <div class="input-group col-sm-8">
                                                <select class="form-control" formControlName="typeLabel" id="typeLabel" [disabled] ="!readView">
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let typeLabel of voucherTypeData"
                                                        [value]="typeLabel.voucher_Type" [disabled] ="!readView">{{typeLabel.voucher_Type}}
                                                    </option>
                                                </select>
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('typeLabel').hasError('required')">
                                                    Voucher Type is required </mat-error>
                                            </span>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="currentLabel"><!-- <span class="required">*</span> -->Current No. </label>
                                            </div>
                                            <div class="input-group col-sm-8">
                                                <input type="text" (keypress)="keyPressNumbers($event)" (input)="patchBill()"
                                                    class="form-control" formControlName="currentLabel"
                                                    placeholder="enter current number" id="currentLabel" [readonly]="!forView">
                                            </div>
                                            <!-- <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('currentLabel').hasError('required')">
                                                    Current No. is required </mat-error>
                                            </span> -->
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="numberingLabel"><!-- <span class="required">*</span> -->Numbering </label>
                                            </div>
                                            <div class="col-sm-8">
                                                <!--<input class="form-control" formControlName="numberingLabel"
                                                    placeholder="..." id="numberringLabel" [readonly]="!forView"> -->
                                                    <!-- Added by Priyanka for making Numbering as dropdown field-->
                                                    <select class="form-control" formControlName="numberingLabel" id="numberingLabel">
                                                        <option value="A" >Automatic</option>
                                                        <option value="M" >Manual</option>
                                                    </select>  
                                            </div>
                                           <!-- <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('numberingLabel').hasError('required')">
                                                    Numbering is required </mat-error>
                                            </span> -->
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="digitLabel"><!-- <span class="required">*</span> -->Bill Digit </label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" (keypress)="keyPressNumbers($event)" (input)="patchBill()"
                                                    class="form-control" formControlName="digitLabel"
                                                    placeholder="Bill Digit" id="digitLabel" [readonly]="!forView">
                                            </div>
                                            <!-- <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && voucherForm.get('digitLabel').hasError('required')">
                                                    Bill Digit is required </mat-error>
                                            </span> -->
                                        </div>
                                        <div class="form-group row" *ngIf="voucherForm.value.numberingLabel == 'A'">
                                            <div class="col-sm-4">
                                                <label for="printLabel">Bill Print Example </label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input class="form-control" formControlName="printLabel"
                                                    placeholder="Bill Print" id="printLabel" [readonly]="true">
                                            </div>
                                            <!-- <span id="spn">
                                            <mat-error *ngIf="showErrorAlert && voucherForm.get('printLabel').hasError('required')"> Bill Print is required </mat-error>
                                        </span> -->
                                        </div>
                                    </form>
                                </mat-card-content>
                            </mat-card>
                            <mat-dialog-actions align="center" *ngIf="forView">
                                <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addVoucher()">Submit</button>
                                <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                            </mat-dialog-actions>
                        </div>
                    </mat-dialog-content>
                </ng-template>
                <app-table [recievedData]="passData" (voucherFormComp)="addNewvoucher($event)"></app-table>
            </div>
            
        </mat-drawer-content>
    </mat-drawer-container>
    <!-- <app-footer></app-footer> -->
</div>
