import { Component, Inject, OnInit } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tax-invoice',
  templateUrl: './tax-invoice.component.html',
  styleUrls: ['./tax-invoice.component.css'],
})
export class TaxInvoiceComponent implements OnInit {  
  selectedFormat = 'format1';
  not_format5 : boolean = true;
  url: any = window.location.href;
  editData: any;
  companyName: string;
  companyAddress: string;
  bank_name: string;
  branch: string;
  account_no: string;
  IFSC_code: string;
  qrdata: string;
  routerName: any;
  payment_term: any;
  gst_no: string;
  contact_no: string;
  saleData: any;
  ledgerData: any;
  consigneeDetail: any = [];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.bank_name = sessionStorage.getItem('bank_name');
    this.companyAddress = sessionStorage.getItem('companyAddress');
    this.companyName = sessionStorage.getItem('companyName');
    this.branch = sessionStorage.getItem('branch');
    this.account_no = sessionStorage.getItem('account_no');
    this.IFSC_code = sessionStorage.getItem('ifsc_code');
    this.gst_no = sessionStorage.getItem('gst_no');
    this.contact_no = sessionStorage.getItem('contact_no');
    this.route.paramMap.subscribe(() => {
      (this.editData = window.history.state),
      (this.saleData = window.history.state.saleData),
      (this.ledgerData = window.history.state.ledgerData);
      (this.consigneeDetail = this.editData?.data?.Consignee);
    });
    if(this.consigneeDetail == null){
      this.consigneeDetail = this.ledgerData
    }
    // this.ledgerData = this.ledgerData.find((i) => i.isMain === true);
    console.log("ledger",  this.ledgerData);
    console.log("editData",  this.editData);

    // this.payment_term = this.ledger1Data.find((i) => i.name === value).days;
    // console.log("bill", this.bill_data);

    this.qrdata =
    this.editData.data != null ?
      '1. Bill No:' +
      this.editData.data.BillNo +
      '\n' +
      '2. Date: ' +
      this.editData.data.BillDate +
      '\n' +
      // '3. Item: ' + this.editData.data.INVData[0].Description +'\n'+
      // '4. Qty: ' + this.editData.data.INVData[0].ActualQty +'\n'+
      // '5. Rate: ' + this.editData.data.INVData[0].Rate +'\n'+
      // '6. Taxable: 'taxable
      '7. SGST: ' +
      this.editData.data.Sgst +
      '\n' +
      '8. CGST: ' +
      this.editData.data.Cgst +
      '\n' +
      '9. IGST: ' +
      this.editData.data.Igst +
      '\n' +
      '10. Grand Total: ' +
      this.editData.data.BillAmount: 'No data found';
    // '11. Vendor Code: 'alias'

    if (this.url.endsWith('consumption/tax-invoice') || this.url.endsWith('production/tax-invoice')) {
      this.selectedFormat = 'format5';
      this.not_format5 = false
    }
  }

  public exportToPDF(): jsPDF {
    let DATA = document.getElementById('panel');
    let PDF = new jsPDF('p', 'mm', 'a4');
    html2canvas(DATA).then((canvas) => {
      let fileWidth = 208;
      let fileHeight = (canvas.height * fileWidth) / canvas.width;

      const FILEURI = canvas.toDataURL('image/png');
     let position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
      PDF.save('saleBill.pdf');
    });
    return PDF;
  }

  SendMsgWhatsApp(event: any) {
    const pdf = this.exportToPDF();
    const formData = new FormData();
    formData.append('attachment', new Blob([pdf.output('blob')], { type: 'application/pdf' }), 'saleBill.pdf');
    let phoneNumber = "8793012216"; // replace with the phone number of the recipient
    let message = "Please find the attached Sale Bill(Anugata.com)"; // replace with the message you want to send
    let url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    formData.forEach((value, key) => {
      url += `&${key}=${encodeURIComponent(value.toString())}`;
    });
    window.open(url);
  }

  public exportToExcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('panel');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'saleBillExcel.xlsx');
  }
  switchFormat(component: string) {
    // console.log('switch is working');
    this.router.navigate(['/'+component]);
  }
}
