import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  transform(dataSource: any[], filter: { column: string,condition:string, value: string }): any {

    if (filter.column !== undefined && (filter.value !== undefined && filter.value !== '')) {

      const filterData = dataSource.filter(item => {

        if (typeof item[filter.column] !== 'string') {

          if(filter.condition==='Equal'){
            return item[filter.column] === Number(filter.value);
          }
          else if(filter.condition==='Not equal')
          {
            return item[filter.column] !== Number(filter.value);
          }
          else if(filter.condition==='Greater than')
          {
            return item[filter.column] > Number(filter.value);
          }
          else if(filter.condition==='Less than')
          {
            return item[filter.column] < Number(filter.value);
          }
          else if(filter.condition==='Greater than equal to')
          {
            return item[filter.column] >= Number(filter.value);
          }
          else if(filter.condition==='Less than equal to')
          {
            return item[filter.column] <= Number(filter.value);
          }
        }

        else {
          return item[filter.column].toLowerCase().includes(filter.value.toLowerCase());
        }
      });

      return { data: filterData, count: filterData.length };;
    }

    return { data: dataSource, count: 0 };
  }

}
