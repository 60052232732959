<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh;width: 100vw; padding: 0 0 0 3rem; overflow: hidden;">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous">
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
            <div *ngIf="isLoading">

                <!-- Head part -->
                <div class="main-containers">
                <mat-toolbar>
                    <nav class="headTitle">Purchase-Cost</nav>
                </mat-toolbar>
            </div>
            <div *ngIf="isLoading">
                <button mat-button (click)="addNewPurchaseCost()" class="add-btn">Add
                    New</button>
                <ng-template #newPurchaseCostForm>
                    <mat-dialog-content>
                        <p class="actionIcon" mat-dialog-close>
                            <mat-icon class="cross" matTooltip="close">clear</mat-icon>
                        </p>
                        <div class="container">
                            <h1 class="companytitle" mat-dialog-title>Purchase Cost</h1>
                            <mat-card>
                                <mat-card-content>
                                    <form [formGroup]="purchaseCostForm" class="mainForm">
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="partyLabel"><span class="required">*</span>Party</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <!-- <mat-select formControlName="partyName">
                                                    <mat-option [value]=null>Select</mat-option>
                                                    <mat-option *ngFor="let partyLabel of partyData" [value]="partyLabel.name">
                                                        {{partyLabel.name}}</mat-option>
                                                </mat-select> -->
                                                <select class="form-control" formControlName="partyLabel"
                                                    id="partyLabel">
                                                    <option disabled>Select</option>
                                                    <option *ngFor="let partyLabel of partyData|sort:['name']" [value]="partyLabel.code">{{partyLabel.name}}</option>
                                                </select>
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && purchaseCostForm.get('partyLabel').hasError('required')">Party is required
                                                </mat-error>
                                            </span>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="itemNameLabel"><span class="required">*</span>Item Name</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <select class="form-control" formControlName="itemNameLabel" id="itemNameLabel">
                                                <option disabled>Select</option>
                                                <option *ngFor="let itemNameLabel of itemData|sort:['name']" [value]="itemNameLabel.code">{{itemNameLabel.name}}</option>
                                                </select>
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && purchaseCostForm.get('itemNameLabel').hasError('required')">
                                                    Item Name is required
                                                </mat-error>
                                            </span>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="purchaseRateLabel"><span class="required">*</span>Purchase Rate</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input class="form-control" formControlName="purchaseRateLabel" placeholder="purchase rate"
                                                    id="purchaseRateLabel" [readonly]="!forView">
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && purchaseCostForm.get('purchaseRateLabel').hasError('required')">
                                                    Purchase Rate is required
                                                </mat-error>
                                            </span>
                                        </div>
                                        
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="discountLabel"><span class="required">*</span>Discount %</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input class="form-control" formControlName="discountLabel" placeholder="0.00"
                                                    id="discountLabel" [readonly]="!forView">
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && purchaseCostForm.get('discountLabel').hasError('required')">
                                                    Discount is required
                                                </mat-error>
                                            </span>
                                        </div>
    
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="partNoLabel"><span class="required">*</span>Part no.</label>
                                            </div>
                                            <div class="col-sm-8">
                                                <input class="form-control" formControlName="partNoLabel" placeholder="enter part number"
                                                    id="partNoLabel" [readonly]="!forView">
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && purchaseCostForm.get('partNoLabel').hasError('required')">
                                                    Part no. is required
                                                </mat-error>
                                            </span>
                                        </div>
                                    </form>
                                </mat-card-content>
                            </mat-card>
                            <mat-dialog-actions align="center" *ngIf="forView">
                                <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addPurchaseCost()">Submit</button>
                                <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                            </mat-dialog-actions>
                        </div>
                    </mat-dialog-content>
                </ng-template>
                <app-table [recievedData]="passData" (purchaseCostFormComp)="addNewPurchaseCost($event)"></app-table>
            </div>
            </div>
            
        </mat-drawer-content>
    </mat-drawer-container>
    <!-- <app-footer></app-footer> -->
</div>