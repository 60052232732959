import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataUpload } from 'src/app/shared/dataUpload';
import { MasterService } from 'src/services/master.service';
import * as FileSaver from 'file-saver';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-master-dialog',
  templateUrl: './upload-master-dialog.component.html',
  styleUrls: ['./upload-master-dialog.component.css']
})
export class UploadMasterDialogComponent implements OnInit {

  constructor(
    private masterService: MasterService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }
  headerFields: string = "";
  masterName: string = "";

  ngOnInit(): void {
    this.headerFields = this.data.headerFields;
    this.masterName = this.data.masterName;
  }


  upload(event: any, ){
    DataUpload.dataUpload(event, this.toastr, this.masterService, this.masterName);
  }

  downloadFile() {
    let filePath: string = 'assets/files/itemMasterTemplate.csv';
    let fileName: string = 'itemMasterTemplate.csv';

    if(this.masterName == "Item"){
      filePath = 'assets/files/itemMasterTemplate.csv';
      fileName = 'itemMasterTemplate.csv';
    }else if(this.masterName == "Ledger"){
      filePath = 'assets/files/ledgerMasterTemplate.csv';
      fileName = 'ledgerMasterTemplate.csv';
    }
    FileSaver.saveAs(filePath, fileName);
  }
}
