import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bill-adjustment',
  templateUrl: './bill-adjustment.component.html',
  styleUrls: ['./bill-adjustment.component.css']
})
export class BillAdjustmentComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var key=event.key.toUpperCase();
    if(key == 'R'){
      this.openComponent('dashboard/master/accounting/bill-adjustment/receivables')
    }else if(key == 'P'){
      this.openComponent('dashboard/master/accounting/bill-adjustment/payables')
    }else if(event.key=="Escape"){
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }

  constructor(private router: Router,public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openComponent(component: string){
    this.router.navigate(['/'+component]);    
  }
}
