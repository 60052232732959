<div style="min-height: 100vh; padding-left: 0%">
  <app-header (sideNavToggled)="sideNavStatus = $event"></app-header>
  <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>

 <mat-drawer-container class="example-container">
    <mat-drawer-content class="{{customerLogin ? 'full-width': 'added-padding'}}">
      <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
      <div *ngIf="isLoading">
        <div class="main-containers">
          <mat-toolbar style=" display: flex; justify-content: space-between;align-items: center;">
            <div class="center">
              <nav class="headTitle">{{ headTitle }}</nav>
            </div>
            <div class="end">
              <button mat-flat-button color="primary" class="btn" (click)="redirectToTransaction()">New Transaction</button>
            </div>
          </mat-toolbar>

          <div style="float: right ;margin-top: 0.3rem;margin-right: 5px;">
            <mat-form-field [formGroup]="dateRange" appearance="fill" class="dates">
              <mat-label> Enter the start date and end date </mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" formControlName="startDate" />
                <input matEndDate placeholder="End date" formControlName="endDate"
                  (keydown.enter)="filterBasedOnDates()" (dateChange)="filterBasedOnDates()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker">
              </mat-datepicker-toggle>
              <mat-date-range-picker #picker> </mat-date-range-picker>
            </mat-form-field>
          </div>

          <div mat-form-field class="search alignSearch">
            <div class="row" style="display: flex; margin-top: 70px">
              <div>
                <label for="filterOnSelect" style="font-weight: bold">
                  Filter On</label>&nbsp;
                <select class="filterBySelect" [(ngModel)]="selectedColumn"
                  (change)="columnSelectionFn(selectedColumn)">
                  <option *ngFor="let column of columnName" [value]="column.value">
                    {{ column.name }}
                  </option>
                </select>
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div>
                <label for="filterConditions" style="font-weight: bold">
                  Filter By</label>&nbsp;
                <select class="filterBySelect" [(ngModel)]="selectedCondtion" [disabled]="disableSelectionDropDown">
                  <option *ngFor="let condition of filterConditionsList" [value]="condition">
                    {{ condition }}
                  </option>
                </select>
              </div>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <div>
                <input
                  [(ngModel)]="searchValue"
                  class="search-input"
                  (keyup)="valueSearchEvent(searchValue)"
                  placeholder="Search..."
                  #input
                />
              </div>
            </div>
          </div>

          <button class="export-btn" (click)="exportToExcel()" *ngIf="!errorValue">
            Export to Excel
          </button>
          <div id="ttlRow" >
            <p>Total rows: {{ getRowsValue(null) }}</p>
          </div>
          <div id="ttlRow" *ngIf="filterDataCount > 0" >
            <p>{{filterDataCount}} Entries Found</p>
          </div>
          <div>
            <table
              mat-table
              [dataSource]="dataSource"
              class="mat-elevation-z8"
            >
              <!-- bill date Column -->
              <ng-container matColumnDef="billDate">
                <th mat-header-cell *matHeaderCellDef>Bill Date</th>
                <td mat-cell *matCellDef="let transaction">
                  {{ transaction["billDate"] | date : "dd/MM/yyyy" }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <!-- Voucher Name Column -->
              <ng-container matColumnDef="voucherName">
                <th mat-header-cell *matHeaderCellDef>Voucher Name</th>
                <td mat-cell *matCellDef="let transaction">
                  {{ transaction.voucherName }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <!-- Voucher No Column -->
              <ng-container matColumnDef="voucherNo">
                <th mat-header-cell *matHeaderCellDef>Voucher No</th>
                <td mat-cell *matCellDef="let transaction">
                  {{ transaction.voucherNo }}
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <!-- Ledger Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef [hidden]="!showParticularCol">
                  Particulars
                </th>
                <td mat-cell *matCellDef="let transaction" [hidden]="!showParticularCol">
                  {{ transaction.name }}
                </td>
                <td mat-footer-cell *matFooterCellDef [hidden]="!showParticularCol">
                  Total
                </td>
              </ng-container>

              <!-- BillAmount Column -->
              <ng-container matColumnDef="billAmount">
                <th mat-header-cell *matHeaderCellDef>Bill Amount</th>
                <td mat-cell *matCellDef="let transaction">
                  {{ transaction.billAmount | currency : "INR" }}
                </td>
                <td mat-footer-cell *matFooterCellDef>
                  {{ getTotalCost() | currency : "INR" }}
                </td>
              </ng-container>

              <!-- <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row" style="text-align: right; padding-right: 2px;" >
                  <button mat-icon-button (click)="edit(row)"><mat-icon>launch</mat-icon></button>
                  <button mat-icon-button color="warn" (click)="delete(row)" ><mat-icon>delete</mat-icon></button>
                </td>
              </ng-container>  -->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: right; padding-right: 35px">Action</th>
                <td mat-cell *matCellDef="let row" style="text-align: right; padding-right: 2px">
                  <!--<button mat-icon-button color="primary" *ngIf="this.url.includes('sales')">
                  <mat-icon (click)="view(row)">visibility</mat-icon>
                </button>-->
                  <button mat-icon-button color="primary">
                    <mat-icon (click)="viewR(row, 0)">visibility</mat-icon>
                  </button>
                  <button mat-icon-button>
                    <mat-icon (click)="viewR(row, 1)">launch</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="delete(row)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="this.passData[0]"></tr>
              <tr mat-row *matRowDef="let row; columns: this.passData[0]"></tr>
              <tr mat-footer-row *matFooterRowDef="this.passData[0]"></tr>
            </table>
            <table class="errorTable" *ngIf="errorValue">
              <tr>
                Unable to retrieve data.<br /><br />Please refresh your page or
                check your internet connection
              </tr>
            </table>
          </div>
        </div>
      </div>
      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of Data"></mat-paginator>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
