import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'outstanding-balance-graph',
  templateUrl: './outstanding-balance-graph.component.html',
  styleUrls: ['./outstanding-balance-graph.component.css']
})
export class OutstandingBalanceGraphComponent implements OnInit,OnDestroy {
  outstandingBalanceGraph : ApexCharts;

  ngOnDestroy(): void {
    this.outstandingBalanceGraph.destroy();
  }

  ngOnInit(): void {
    this.generateOutstandingBalanceGraph();
  }

  generateOutstandingBalanceGraph():void{

    const chartOptions = {
      chart: {
        type: 'donut',
        width: '100%',
        height: 200
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            size: '70%',
          },
          offsetY: 10,
          offsetX:0,
        },
        stroke: {
          colors: undefined
        }
      },
      colors:['#2643A7', '#F0F8FF'],
      title: {
        text: '',
        style: {
          fontSize: '10px'
        }
      },
      series: [21, 23],
      labels: ['Label-1', 'Label-2'],
      legend: {
        position: 'right',
        offsetY:40,
        offsetX:35,
        style: {
          fontSize: '10px'
        },
      }
    }

    this.outstandingBalanceGraph = new ApexCharts(document.querySelector('#outstandingBalanceGraph'),chartOptions);
    this.outstandingBalanceGraph.render();


  }


}
