import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mis-report',
  templateUrl: './mis-report.component.html',
  styleUrls: ['./mis-report.component.css']
})
export class MisReportComponent implements OnInit {
  sideNavStatus:boolean =false;
  customerLogin: boolean;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var key=event.key.toUpperCase();
    if(key == 'I'){
      this.openComponent('dashboard/report/mis-report/item-wise-sales')
    }else if(key == 'P'){
      this.openComponent('dashboard/report/mis-report/party-wise-sales')
    }else if(key == 'W'){
      this.openComponent('dashboard/report/register/item-wise-columnar')
    }else if(key == 'C'){
      this.openComponent('dashboard/report/register/party-wise-columnar')
    }else if(event.key=="Escape"){
      this.openComponent('dashboard/reports')
    }
  }

  constructor(private router:Router) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }
}
