<div style="height: 100vh; padding-left: 0%">
  <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
  <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus"
    [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
  <div class="HomeMainDiv">
    <mat-drawer-container class="example-container">
      <mat-drawer-content style="min-height: 93vh; width: 97vw; padding: 0 2rem; overflow: hidden">
        <!-- <mat-spinner  mode="indeterminate"
      *ngIf="!isLoading"></mat-spinner> -->

        <mat-toolbar>
          <nav class="headTitle">{{ headTitle }}</nav>
        </mat-toolbar>
        <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
        <div *ngIf="isLoading">
          <!-- <form [formGroup]="searchBar" class="search alignSearch">
                      <input class="search-input" (keypress)="search($event)" formControlName="searchLabel"
                          placeholder="Search...">
                  </form> -->
          <form [formGroup]="itemForm" class="mainform">
            <table>
              <tr>
                <td>
                  <div class="col-sm-2" class="frst">
                    <label for="itemlable1" id="ldg" style="font-weight: bold;">Item :</label>
                  </div>
                </td>
                <td class="scnd">
                  <div class="input-wrapper">
                    <input type="text" placeholder="Pick Item" class="form-control" formControlName="itemlable1"
                      id="ledgerLabel" matInput [matAutocomplete]="auto" />
                    <mat-icon class="clear-icon" (click)="clearInput()">cancel</mat-icon>
                  </div>
                  <!-- autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)" -->
                  <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="tableFill($event.option.value)">
                    <mat-option [value]="" selected>
                      <div style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        ">
                        <div>-Select option-</div>
                        <div style="margin-left: 8px">[Balance]</div>
                      </div>
                    </mat-option>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      <div style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        ">
                        <div>
                          {{ option.iName }}
                        </div>
                        <div style="margin-left: 8px">
                          [{{ option.iBalance }}]
                        </div>
                      </div>
                    </mat-option>
                  </mat-autocomplete>
                </td>
                <td>
                  <div class="col-sm-2">
                    <label id="ldg" style="font-weight: bold; font-size: 20px">Balance: {{ balance }}</label>
                  </div>
                </td>
                <td>
                  <!--div style="position: absolute; top: 5.5rem; right: 2.2rem" class="input-group col-sm-6">
                    <mat-form-field [formGroup]="dateRange" appearance="fill">
                      <mat-label> Enter start and end date </mat-label>
                      <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" formControlName="startDate" />
                        <input matEndDate placeholder="End date" formControlName="endDate"
                          (keydown.enter)="filterBasedOnDates()" (dateChange)="filterBasedOnDates()" />
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="picker">
                      </mat-datepicker-toggle>
                      <mat-date-range-picker #picker> </mat-date-range-picker>
                    </mat-form-field>
                  </div-->
                </td>
              </tr>
            </table>
          </form>
        </div>
        <!-- upper form containing dropdown and date closed  -->
        <div id="ttlRow">
          <p>Total rows: {{totalRowCount}}</p>
        </div>
        <div *ngIf="isLoading" style="width:100vw">
          <table mat-table *ngIf="showLabel" [dataSource]="dataSource" class="mat-elevation-z8">

            <!-- bill date Column -->
            <ng-container matColumnDef="billDate">
              <th mat-header-cell *matHeaderCellDef sticky> Bill Date </th>
              <td mat-cell *matCellDef="let item"> {{item.billDate | date :"dd/MM/YYYY" }} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Voucher Name Column -->
            <ng-container matColumnDef="voucherName">
              <th mat-header-cell *matHeaderCellDef sticky> Voucher Name</th>
              <td mat-cell *matCellDef="let item"> {{item.voucherName}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Voucher No Column -->
            <ng-container matColumnDef="voucherNo">
              <th mat-header-cell *matHeaderCellDef> Voucher No </th>
              <td mat-cell *matCellDef="let item"> {{item.voucherNo}}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- ITEM Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Particulars</th>
              <td mat-cell *matCellDef="let item"> {{item.name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Quantity Column -->
            <ng-container matColumnDef="qty">
              <th mat-header-cell *matHeaderCellDef> Quantity </th>
              <td mat-cell *matCellDef="let item"> {{item.qty}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Rate Column -->
            <ng-container matColumnDef="rate">
              <th mat-header-cell *matHeaderCellDef> Rate </th>
              <td mat-cell *matCellDef="let item"> {{item.rate}}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Amount Column -->
            <ng-container matColumnDef="amount">
              <th mat-header-cell *matHeaderCellDef> Amount </th>
              <td mat-cell *matCellDef="let item"> {{item.amount}}</td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="this.passData[0]"></tr>
            <tr mat-row *matRowDef="let row; columns: this.passData[0]"></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="this.passData[0]"></tr> -->
          </table>

          <table mat-table *ngIf="ledShowLabel" [dataSource]="dataSource" class="mat-elevation-z8">

            <ng-container matColumnDef="Bill Date">
              <th mat-header-cell *matHeaderCellDef> Bill Date </th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]?.date | date : "dd/MM/YYYY" }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Voucher Name Column -->
            <ng-container matColumnDef="Voucher Name">
              <th mat-header-cell *matHeaderCellDef> Voucher Name</th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]?.voucherName || '-' }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="Voucher No.">
              <th mat-header-cell *matHeaderCellDef> Voucher No </th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]['bill No']||'-' }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- ITEM Column -->
            <ng-container matColumnDef="Particulars">
              <th mat-header-cell *matHeaderCellDef> Particulars</th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]?.name || '-' }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Quantity Column -->
            <ng-container matColumnDef="Opening">
              <th mat-header-cell *matHeaderCellDef> Opening </th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0||i==1">
                  {{dataSource.filteredData[0]?.balance||'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0&& i!=1">
                  {{dataSource.filteredData[i-1]?.clcBalance}}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- ITEM Column -->
            <ng-container matColumnDef="In">
              <th mat-header-cell *matHeaderCellDef> In</th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]?.in || '-' }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- ITEM Column -->
            <ng-container matColumnDef="Out">
              <th mat-header-cell *matHeaderCellDef> Out</th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]?.out }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- ITEM Column -->
            <ng-container matColumnDef="Balance">
              <th mat-header-cell *matHeaderCellDef> Balance</th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{(item?.balance)||'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{item?.clcBalance }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Rate Column -->
            <ng-container matColumnDef="Rate">
              <th mat-header-cell *matHeaderCellDef> Rate </th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]?.rate || '-' }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <!-- Amount Column -->
            <ng-container matColumnDef="Amount">
              <th mat-header-cell *matHeaderCellDef> Amount </th>
              <td mat-cell *matCellDef="let item ;let i=index"> 
                <ng-container *ngIf="i==0">
                  {{'-'}}
                </ng-container>
                <ng-container *ngIf="i!=0">
                  {{dataSource.filteredData[i-1]?.amount || '-' }}
                </ng-container>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="this.passData[0]"></tr>
            <tr mat-row *matRowDef="let row; columns: this.passData[0]"></tr>
            <!-- <tr mat-footer-row *matFooterRowDef="this.passData[0]"></tr> -->
          </table>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
          aria-label="Select page of Data"></mat-paginator>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>