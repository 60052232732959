<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh;width: 100vw; padding: 0 0 0 3rem; overflow: hidden;">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous">
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
            <div *ngIf="isLoading">

                <!-- Head part -->
                <div class="main-containers">
        <mat-toolbar>
          <nav class="headTitle">hsn</nav>
        </mat-toolbar>
      </div>
      <div *ngIf="isLoading">
        <button mat-button (click)="addNewhsn()" class="add-btn">Add New</button>
        <ng-template #newHsnForm>
          <mat-dialog-content>
            <div class="container">
              <p class="cross" mat-dialog-close>&#10005;</p>
              <h1 mat-dialog-title class="companytitle">Hsn</h1>
              <mat-card class="mat-card">
                <mat-card-content>
                  <form [formGroup]="hsnForm" class="mainForm">
                    <div class="form-group row">
                      <div class="col-sm-4">
                        <label for="hsnLabel"><span class="required">*</span>H.S.N.</label>

                                            </div>
                                            <div class="col-sm-8">
                                                <input type="text" (keypress)="keyPressNumbersDecimal($event)"
                                                    class="form-control" formControlName="hsnNo" placeholder="HSN"
                                                    id="hsnLabel" [readonly]="!forView">
                                                <mat-error
                                                    *ngIf="showErrorAlert && hsnForm.get('hsnNo').hasError('required')">
                                                    HSN No is required
                                                </mat-error>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="igstLabel"><span class="required">*</span>I.G.S.T. </label>
                                            </div>
                                            <div class="input-group col-sm-8">
                                                <input class="form-control" (change)="setOneNumberDecimalForIGST($event)" formControlName="igst" placeholder="IGST"
                                                    id="igstLabel" [readonly]="!forView">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                            <div class="input-group col-sm-8" class="errormess">
                                                <div *ngIf="igst.touched && igst.invalid" class="alert alert-danger">
                                                    <div *ngIf="igst.errors.pattern">Enter value between 0 - 100</div>
                                                </div>
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && hsnForm.get('igst').hasError('required')">
                                                    IGST is required
                                                </mat-error>
                                            </span>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="cgstLabel"><span class="required">*</span>C.G.S.T. </label>
                                            </div>
                                            <div class=" input-group col-sm-8">
                                                <input type="text" (change)="setOneNumberDecimal($event)"
                                                    class="form-control" formControlName="cgst" placeholder="CGST"
                                                    id="cgstLabel" [readonly]="!forView">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                            <div class="input-group col-sm-8" class="errormess">
                                                <div *ngIf="cgst.touched && cgst.invalid" class="alert alert-danger">
                                                    <div *ngIf="cgst.errors.pattern">Enter value between 0 - 100</div>
                                                </div>
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && hsnForm.get('cgst').hasError('required')">
                                                    CGST is required
                                                </mat-error>
                                            </span>
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-sm-4">
                                                <label for="sgstLabel"><span class="required">*</span>S.G.S.T. </label>
                                            </div>
                                            <div class="input-group col-sm-8">
                                                <input type="text" (change)="setOneNumberDecimal($event)"
                                                    class="form-control" formControlName="sgst" placeholder="SGST"
                                                    id="sgstLabel" [readonly]="!forView">
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                            <div class="input-group col-sm-8" class="errormess">
                                                <div *ngIf="sgst.touched && sgst.invalid" class="alert alert-danger">
                                                    <div *ngIf="sgst.errors.pattern">Enter value between 0 - 100</div>
                                                </div>
                                            </div>
                                            <span id="spn">
                                                <mat-error
                                                    *ngIf="showErrorAlert && hsnForm.get('sgst').hasError('required')">
                                                    SGST is required
                                                </mat-error>
                                            </span>
                                        </div>
                                    </form>
                                </mat-card-content>
                            </mat-card>
                            <mat-dialog-actions align="center" *ngIf="forView">
                                <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addHsn()">Submit</button>
                                <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                            </mat-dialog-actions>
                        </div>
                    </mat-dialog-content>
                </ng-template>
                <!-- Table work -->
                <app-table *ngIf="hsnGroupData?.length != 0" [recievedData]="passData" (hsnFormComp)="addNewhsn($event)"></app-table>
      </div>
      <div *ngIf="hsnGroupData?.length == 0" style="position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);">
        <h1>
          No Items Present
        </h1>
      </div>
      </div>

    </mat-drawer-content>
  </mat-drawer-container>
</div>
  <!-- <app-footer></app-->
