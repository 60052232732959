import { Component} from '@angular/core';

@Component({
  selector: 'user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css']
})
export class UserPermissionComponent{
  data = [
    {
      group: 'Master',
      subGroup: [
        {
          subGroupName: 'Acounting',
          subGroupComponent: [
            'Ledger',
            'Voucher',
            'Group',
            'Cost Center',
            'Sales Person',
            'Bill Adjustment',
          ],
        },
        {
          subGroupName: 'Inventory',
          subGroupComponent: [
            'Item',
            'Stock Group',
            'Godown',
            'Sales Price',
            'Purchase Cost',
            'HSN',
          ],
        },
      ],
    },
    {
      group: 'Transaction',
      subGroup: [
        {
          subGroupName: 'Acounting',
          subGroupComponent: [
            'Sale',
            'Purchase',
            'Sale Order',
            'Purchase Order',
            'Credit Note',
            'Debit Note',
            'Receipt',
            'Payment',
            'Contra',
          ],
        },
        {
          subGroupName: 'Inventory',
          subGroupComponent: [
            'Material In',
            'Material Out',
            'Production',
            'Consumption',
          ],
        },
      ],
    },
    {
      group: 'Report',
      subGroup: [
        {
          subGroupName: 'Stock Item ',
          subGroupComponent: [
            '--'
          ],
        },
        {
          subGroupName: 'Stock Summary',
          subGroupComponent: [
            '--'
          ],
        },
        {
          subGroupName: 'Ledger Report',
          subGroupComponent: [
            '--'
          ],
        },
        {
          subGroupName: 'MIS Reports',
          subGroupComponent: [
            'Item Wise',
            'Party Wise',
            'Item wise Party wise',
            'Party wise Item wise',
          ],
        },
      ],
    },
  ];
}
