<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <mat-drawer-container class="example-container">
        <mat-drawer #drawer mode="push">
        </mat-drawer>
        <mat-drawer-content style="min-height: 90vh;">

            <!-- Head part -->
            <div class="main-containers page-header">
                <mat-toolbar>
                    <nav class="headTitle">Final-Account</nav>
                </mat-toolbar>
            </div>
            <div class="btns-container">
                <div class="btn-container">
                    <button mat-raised-button (click)="InProgressDialog()"><span class="highlighter">T</span>rail Balance</button>
                    <button mat-raised-button (click)="InProgressDialog()"><span class="highlighter">P</span>rofit & Loss</button>
                    <button mat-raised-button (click)="InProgressDialog()"><span class="highlighter">B</span>alance Sheet</button>
                    <button mat-raised-button (click)="InProgressDialog()"><span class="highlighter">A</span>udit Report</button>
                </div>
            </div>
            
        </mat-drawer-content>
    </mat-drawer-container>
    <app-footer></app-footer>
</div>