import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/services/master.service';

@Component({
  selector: 'app-new-unit',
  templateUrl: './new-unit.component.html',
  styleUrls: ['./new-unit.component.css']
})
export class NewUnitComponent implements OnInit {
  hideRequiredMarker = true;
  loader: boolean = false
  new_unit: any;

  constructor(
    private dialogRef: MatDialogRef<NewUnitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private apiService: MasterService,
  ) { }

  ngOnInit(): void { }

  form = new FormGroup({
    new_unit: new FormControl("", [Validators.required, Validators.maxLength(50)]),
  });
  cancel() {
    this.dialogRef.close()
  }

  submit() {
    if (this.form.invalid) {
      this.toast.clear();
      this.toast.error("Please fill in all fields");
    } else {
      this.addNewUnit();

    }
  }

  addNewUnit() {
    this.loader = true
    const data = {
      UnitName: this.form.value.new_unit,
      Serial: sessionStorage.getItem('Serial'),
      CompanyCode: sessionStorage.getItem('CompanyCode'),
      ServerVal: sessionStorage.getItem('ServerVal')
    }
    this.apiService.addUnit(data).subscribe(
      (res) => {
        this.loader = false
        if (res == "Success") {
          this.dialogRef.close(data?.UnitName);
          this.toast.clear();
          this.toast.success("New unit added successfully");
        }
      },
      (error) => {
        this.loader = false
        if (error?.error.error.message) {
          this.toast.clear();
          this.toast.error(error?.error.error.message);
        } else {
          this.toast.clear();
          this.toast.error("Something Went Wrong");
        }
      }
    );
  }
}

