<div style="height: 100vh; padding-left: 0%;">
    <app-header (sideNavToggled)="sideNavStatus = $event;"></app-header>
    <app-side-nav *ngIf="!customerLogin" [sideNavStatus]="sideNavStatus" [ngClass]="{'side-nav-open':sideNavStatus}"></app-side-nav>
    <div class="HomeMainDiv">
        <mat-drawer-container class="example-container">
            <mat-drawer-content style="min-height: 93vh;width: 100vw; padding: 0 0 0 3rem; overflow: hidden;">
                <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
                    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
                    crossorigin="anonymous">
                <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
                    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
                    crossorigin="anonymous"></script>
                <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
                    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
                    crossorigin="anonymous"></script>
                <mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>

                <div class="main-container">
                    <mat-toolbar>
                        <nav class="headTitle">Ledger</nav>
                    </mat-toolbar>
                </div>
                <div *ngIf="isLoading">
                    <button mat-button (click)="addNewledger()" class="add-btn">Add
                        New</button>
                        <button mat-button class="add-btn" (click)="upload()">Upload File</button>

                    <ng-template #newLedgerForm>
                        <mat-dialog-content>
                            <p class="actionIcon" mat-dialog-close>
                                <mat-icon class="cross" matTooltip="close">clear</mat-icon>
                            </p>
                            <div class="container">
                                <h1 class="companytitle" mat-dialog-title>Ledger</h1>
                                <mat-card>
                                    <mat-card-content>
                                        <form [formGroup]="ledgerForm" class="mainForm">
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="nameLabel"><span class="required">*</span>Name </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" formControlName="nameLabel"
                                                        placeholder="Enter Name" id="nameLabel" [readonly]="!forView">
                                                </div>
                                                <span id="spn">
                                                    <mat-error *ngIf="showErrorAlert
                                                    &&
                                                    ledgerForm.get('nameLabel').hasError('required')">
                                                        Name is required
                                                    </mat-error>
                                                </span>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="aliasLabel">Alias </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" (keypress)="keyPressAlphaNumeric($event)"
                                                        class="form-control" formControlName="aliasLabel"
                                                        placeholder="Enter code" id="aliasLabel" [readonly]="!forView">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="addressLabel">Address
                                                    </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <textarea class="form-control" formControlName="addressLabel"
                                                        maxlength="200" placeholder="Address" id="addressLabel"
                                                        [readonly]="!forView"></textarea>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="stateLabel">State </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <select class="form-control" formControlName="stateLabel"
                                                        (change)="setStateCodeValues($event)" id="stateLabel"
                                                        placeholder="Select an Option">
                                                        <option disabled>Select</option>
                                                        <option *ngFor="let stateLabel of stateData|sort:['stateName']"
                                                            [value]="stateLabel.stateName">{{stateLabel.stateName}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="cityLabel">City </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" class="form-control" id="cityLabel"
                                                        formControlName="cityLabel" placeholder="City Name"
                                                        [readonly]="!forView">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="pinLabel">Pin Code </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" (keypress)="keyPressNumbers($event)"
                                                        class="form-control" formControlName="pinLabel"
                                                        placeholder="Enter Pin code" id="pinLabel"
                                                        [readonly]="!forView">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="groupLabel"><span class="required">*</span>Group
                                                    </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <select class="form-control" formControlName="groupLabel"
                                                        id="groupLabel" required>
                                                        <option disabled>Select</option>
                                                        <option *ngFor="let groupLabel of groupData|sort:['name']"
                                                            [value]="groupLabel.code">{{groupLabel.name}}</option>
                                                    </select>
                                                    <div class="input-group-append">
                                                      <span class="input-group-text" (click)="openGroup()">+</span>
                                                  </div>
                                                </div>
                                                <span id="spn">
                                                    <mat-error *ngIf="showErrorAlert
                                                    &&
                                                    ledgerForm.get('groupLabel').hasError('required')">
                                                        Group is required
                                                    </mat-error>
                                                </span>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="contactLabel">Contact
                                                    </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" formControlName="contactLabel"
                                                        maxlength="10" placeholder="eg. 9999999999" id="contactLabel"
                                                        [readonly]="!forView">
                                                </div>
                                                <div class="input-group col-sm-8" id="mblNum">
                                                    <div *ngIf="contactLabel.touched
                                                    &&
                                                    contactLabel.invalid" class="alert
                                                    alert-danger">
                                                        <!-- <div *ngIf="contactLabel.errors.required">Mobile Number is required.</div> -->
                                                        <div *ngIf="contactLabel.errors.pattern">Enter
                                                            a valid Contact Number</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="mobileLabel">Alternate
                                                        Contact </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" formControlName="mobileLabel"
                                                        maxlength="10" placeholder="eg. 9999999999" id="mobileLabel"
                                                        [readonly]="!forView">

                                                </div>
                                                <div class="input-group col-sm-8" id="mblNum">
                                                    <div *ngIf="mobileLabel.touched
                                                    &&
                                                    mobileLabel.invalid" class="alert
                                                    alert-danger">
                                                        <!-- <div *ngIf="mobileLabel.errors.required">Mobile Number is required.</div> -->
                                                        <div *ngIf="mobileLabel.errors.pattern">Enter
                                                            a valid Number</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="emailLabel">Email </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" formControlName="emailLabel"
                                                        placeholder="eg.example@demo.com" id="emailLabel" type="email"
                                                        [readonly]="!forView">
                                                </div>
                                                <div class="input-group col-sm-8" id="eml">
                                                    <div *ngIf="emailLabel.invalid
                                                    &&
                                                    (emailLabel.dirty ||
                                                    emailLabel.touched)" class="alert
                                                    alert-danger">
                                                        <div *ngIf="emailLabel.errors?.email">
                                                            enter valid email
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="openingLabel">Opening
                                                        Balance</label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" (keypress)="keyPressNumbersDecimal($event)"
                                                        class="form-control" formControlName="openingLabel"
                                                        placeholder="Opening" id="openingLabel" [readonly]="!forView">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="dcLabel">Dr/Cr </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <select class="form-control" id="dcLabel" formControlName="dcLabel"
                                                        placeholder="Select
                                                    an Option">
                                                        <option disabled>Select</option>
                                                        <option value="D">Dr</option>
                                                        <option value="C">Cr</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="gstTypeLabel">GST
                                                        Type </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <!-- <select class="form-control" id="gstTypeLabel"
                                                        formControlName="gstTypeLabel" [disabled]="disableSelect.value"
                                                        [(ngModel)]='nrSelect'> -->
                                                        <select class="form-control" id="gstTypeLabel"
                                                            formControlName="gstTypeLabel" [disabled]="disableSelect.value">
                                                        <option value='' selected> -Select option- </option>
                                                        <option *ngFor="let item of gstTypes" [value]="item.value">{{item.name}} </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="stateCodeLabel">State Code </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input class="form-control" formControlName="stateCodeLabel"
                                                        placeholder="Enter State code" id="stateCodeLabel"
                                                        [readonly]="!editable">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="gstNoLabel">GST no.
                                                    </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input class="form-control"
                                                        formControlName="gstNoLabel" maxlength="15"
                                                        placeholder="Enter GST No." id="gstNoLabel"
                                                        [readonly]="!forView">
                                                        <!--<div class="input-group-append">
                                                            <span class="input-group-text" (click)="getCompanyDetails()">+</span>
                                                        </div>-->
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="panNoLabel">PAN no.
                                                    </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input class="form-control"
                                                        formControlName="panNoLabel" maxlength="10"
                                                        placeholder="Enter Pan No." id="panNoLabel"
                                                        [readonly]="!forView">
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="igstLabel">IGST </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" (change)="setOneNumberDecimalForIGST($event)"
                                                        formControlName="igstLabel" placeholder="IGST" id="igstLabel"
                                                        [readonly]="!forView">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                <div class="input-group col-sm-8" class="errormess">
                                                    <div *ngIf="igstLabel.touched && igstLabel.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="igstLabel.errors.pattern">Enter value between 0 -
                                                            100
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="input-group col-sm-8" id="igstlabel">
                                                    <div *ngIf="igstLabel.touched && igstLabel.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="igstLabel.errors.pattern">Enter
                                                            number between 1 to 100</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="cgstLabel">CGST </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" (change)="setOneNumberDecimal($event)"
                                                        formControlName="cgstLabel" placeholder="CGST" id="cgstLabel"
                                                        [readonly]="!forView">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                <div class="input-group col-sm-8" class="errormess">
                                                    <div *ngIf="cgstLabel.touched && cgstLabel.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="cgstLabel.errors.pattern">Enter value between 0 -
                                                            100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="sgstLabel">SGST </label>
                                                </div>
                                                <div class="input-group col-sm-8">
                                                    <input class="form-control" (change)="setOneNumberDecimal($event)"
                                                        formControlName="sgstLabel" placeholder="SGST" id="sgstLabel"
                                                        [readonly]="!forView">
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">%</span>
                                                    </div>
                                                </div>
                                                <div class="input-group col-sm-8" class="errormess">
                                                    <div *ngIf="sgstLabel.touched && sgstLabel.invalid"
                                                        class="alert alert-danger">
                                                        <div *ngIf="sgstLabel.errors.pattern">Enter value between 0 -
                                                            100
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="billLabel">Bill by
                                                        Bill </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <select class="form-control" formControlName="billLabel"
                                                        placeholder="Bill by BIL" id="billLabel">
                                                        <option disabled>Select</option>
                                                        <option value="Y">Yes</option>
                                                        <option value="N">No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-sm-4">
                                                    <label for="dayLabel">Days </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <input type="text" (keypress)="keyPressNumbersDecimal($event)"
                                                        class="form-control" formControlName="dayLabel"
                                                        placeholder="Days" id="dayLabel" [readonly]="!forView">
                                                </div>
                                            </div>
                                        </form>
                                    </mat-card-content>
                                </mat-card>
                                <mat-dialog-actions align="center" *ngIf="forView">
                                    <button class="btn btn-primary col-sm-2 {{isLoading ? '':'loader' }}" [disabled]="!isLoading" (click)="addLedger()">Submit</button>
                                    <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
                                </mat-dialog-actions>
                            </div>
                        </mat-dialog-content>
                    </ng-template>
                    <app-table [recievedData]="passData" (ledgerFormComp)="addNewledger($event)"></app-table>
                </div>

            </mat-drawer-content>
        </mat-drawer-container>
    </div>
    <!-- <app-footer></app-footer> -->
</div>
