import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-inventory-transaction',
  templateUrl: './inventory-transaction.component.html',
  styleUrls: ['./inventory-transaction.component.css']
})
export class InventoryTransactionComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var key=event.key.toUpperCase();
    if(key == 'M'){
      this.openComponent('dashboard/transactions/inventory/material-in')
    }else if(key == 'U'){
      this.openComponent('dashboard/transactions/inventory/material-out')
    }else if(key=='I'){
      this.openComponent('dashboard/transactions/inventory/production')
    }else if(key=='C'){
      this.openComponent('dashboard/transactions/inventory/consumption')
    }
  }


  constructor(private router:Router,private toastr:ToastrService,) { }

  ngOnInit(): void {
  }
  openComponent(component:string){
    this.router.navigate(['/'+component])
  }
  getError(){
    this.toastr.info('This feature will be available soon!','',{
      timeOut:3000
    });
  }
}
