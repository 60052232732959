import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-accounting-transaction',
  templateUrl: './accounting-transaction.component.html',
  styleUrls: ['./accounting-transaction.component.css']
})
export class AccountingTransactionComponent implements OnInit {
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    var key=event.key.toUpperCase();
    if(key == 'O'){
      this.openComponent('dashboard/transactions/accounting/saleOrder')
    }else if(key == 'D'){
      this.openComponent('dashboard/transactions/accounting/purchaseOrder')
    }else if(key=='R'){
      this.openComponent('dashboard/transactions/accounting/receipt')
    }else if(key=='S'){
      this.openComponent('dashboard/transactions/accounting/sale-bill')
    }else if(key=='P'){
      this.openComponent('dashboard/transactions/accounting/purchase')
    }else if(key=='E'){
      this.openComponent('dashboard/transactions/accounting/deposit')
    }else if(key=='W'){
      this.openComponent('dashboard/transactions/accounting/withdraw')
    }else if(key=='B'){
      this.openComponent('dashboard/transactions/accounting/debitNote')
    }else if(key=='N'){
      this.openComponent('dashboard/transactions/accounting/creditNote')
    }else if(key=='A'){
      this.openComponent('dashboard/transactions/accounting/contra')
    }else if(key=='Y'){
      this.openComponent('dashboard/transactions/accounting/payment')
    }
  }

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  constructor(private router:Router,) {}

  ngOnInit(): void {
  }
  openComponent(component: string){
    this.router.navigate(['/'+component]);
  }
}
