<h1 mat-dialog-title class="title">{{title}}</h1>
<hr>
<div mat-dialog-content>
    <p class="content">{{message}}</p>
    <ng-container *ngIf="data?.dialogData">
        <p>
            {{data?.dialogData?.voucherName?"Voucher name":'Name'}} : <b>{{data?.dialogData?.voucherName||data?.dialogData?.name}}</b> <br>
           <ng-container *ngIf="data?.dialogData?.voucherNo">Voucher No. : <b>{{data?.dialogData?.voucherNo}}</b></ng-container></p>
        
    </ng-container>
</div>
<hr>
<mat-dialog-actions align='center'>
    <button *ngIf="title !=='Confirmation'" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial class="yes-btn">Yes</button>
    <button *ngIf="title !=='Confirmation'" mat-raised-button color="primary" mat-dialog-close cdkFocusInitial class="no-btn">NO</button>
    <button *ngIf="title ==='Confirmation'" mat-raised-button color="primary" [mat-dialog-close]="true" cdkFocusInitial
        class="yes-btn">OK</button>
    <button *ngIf="title ==='Confirmation'" [mat-dialog-close]="false" mat-raised-button color="primary" cdkFocusInitial
        class="no-btn">Cancel</button>
</mat-dialog-actions>