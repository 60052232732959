<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
    integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
    integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
    crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
    crossorigin="anonymous"></script>
<div class="container">
    <mat-dialog-content>
        <p class="actionIcon" mat-dialog-close>
            <mat-icon class="cross" matTooltip="close">clear</mat-icon>
        </p>
        <h1 mat-dialog-title align="start">{{inputData.edit?'Edit Item':'Add Item'}}</h1>
        <mat-card>
            <mat-card-content>
                <form [formGroup]="itemForm" class="mainForm">
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="selectItemLabel">Select Item</label>
                        </div>
                        <div class="input-group col-sm-8">
                            <!-- drop down -->
                            <input type="text" placeholder="Pick one" aria-label="Number" class="form-control"
                                id="selectItemLabel" formControlName="selectItemLabel" matInput
                                [matAutocomplete]="auto">
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                (optionSelected)="updateValues($event,'new')">
                                <mat-option [value]="" selected>
                                    <div style="display: flex; align-items: center; justify-content: space-between;">
                                        <div>
                                            -Select option-
                                        </div>
                                        <div style="margin-left: 8px;">
                                            [Balance]
                                        </div>
                                    </div>
                                </mat-option>
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.name">
                                    <div style="display: flex; align-items: center; justify-content: space-between;">
                                        <div>
                                            {{ option.name }}
                                        </div>
                                        <div style="margin-left: 8px;">
                                            [{{ option.balance }}]
                                        </div>
                                    </div>
                                </mat-option>
                            </mat-autocomplete>
                            <!-- drop down ends -->
                            <!-- <select class="form-control" id="selectItemLabel" formControlName="selectItemLabel"
                                placeholder="Select an Option" (change)="updateValues($event)">
                                <option [value]="null">Select</option>
                                <option *ngFor="let item of inputData.itemList" [value]="item.name">{{item.name}}
                                </option>
                            </select> -->
                            <div class="input-group-append">
                                <span class="input-group-text" (click)="openMasterItem()">+</span>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="igstLabel">Description</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="descriptionLabel" placeholder="Description"
                                id="descriptionLabel">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="quantityLabel">Quantity</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="quantityLabel" placeholder="0.000"
                                id="quantityLabel" (blur)="calculateAmount()" ng-disabled="!editMode">
                            <mat-error *ngIf="showErrorAlert &&
                                itemForm.get('quantityLabel').hasError('required')">
                                Quantity is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="rateLabel">Rate</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="rateLabel" placeholder="0.00" id="rateLabel"
                                (blur)="calculateAmount()" (click)="clearRateIfZero()" ng-disabled="!editMode">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="discountLabel">Discount</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="discountLabel" placeholder="0.00"
                                id="discountLabel" (blur)="calculateAmount()" ng-disabled="!editMode">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="hsnLabel">HSN</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="hsnLabel" placeholder="0.00" id="hsnLabel"
                                readonly>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="igstLabel">IGST</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="igstLabel" placeholder="0.00" id="igstLabel"
                                [readonly]="isGst">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="cgstLabel">CGST</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="cgstLabel" placeholder="0.00" id="cgstLabel"
                                [readonly]="isGst">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="sgstLabel">SGST</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="sgstLabel" placeholder="0.00" id="sgstLabel"
                                [readonly]="isGst">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-sm-4">
                            <label for="amountLabel">Amount</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" formControlName="amountLabel" placeholder="0.00"
                                id="amountLabel" readonly>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </mat-dialog-content>

    <mat-dialog-actions align='center'>
        <button class="btn btn-primary col-sm-2" (click)="addItem()" [disabled]="!editMode">Add</button>
        <button class="btn btn-primary col-sm-2" mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
</div>