<link href="https://fonts.googleapis.com/css2?family=Muli:wght@200;300;400;500;700&display=swap" rel="stylesheet">
<div class="pdf-action">
  <button (click)="exportToPDF()">Export to PDF</button>
  <button (click)="exportToExcel()">Export to Excel</button>
  <button (click)="SendMsgWhatsApp($event)">Send to Whatsapp</button>
  <!-- <button (click)="switchFormat('dashboard/transactions/accounting/sale-bill/tax-invoice2')">Change Format</button> -->
  <mat-select class="change-format" [(ngModel)]="selectedFormat" *ngIf="not_format5">
    <mat-option class="option" value="format1">Format 1</mat-option>
    <mat-option class="option" value="format2">Format 2</mat-option>
    <mat-option class="option" value="format3">Format 3</mat-option>
    <mat-option class="option" value="format4">Format 4</mat-option>
  </mat-select>
  <!-- <mat-option class="option"  value="format5">Format 5</mat-option> -->
</div>
<div id="panel" class="invoice-box">

  <!--++++++++++++++++++++++++++ format 0ne starts+++++++++++++++++++++++++++-->
  <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb" *ngIf="selectedFormat =='format1'">
    <tbody>
      <!-- title -->
      <tr>
        <td height="20" colspan="4" align="center" class="txt">GST TAX INVOICE</td>
      </tr>
      <tr height="65">
        <!-- qr code starts -->
        <td align="start" class="txt" id="qrCode">
          <qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode>
        </td>
        <!-- qr code ends -->
        <td align="start" class="txt cName">{{companyName}}<br><span
            class="tdData address-block">{{companyAddress}}</span>
        </td>
      </tr>
      <tr>
        <!-- <td width="3%">&nbsp;</td> -->
        <td colspan="12">
          <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
            <tbody>
              <!-- first table box starts -->
              <tr class="outerTable bigLineHeight">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="50%"><strong><span class="tdData">INVOICE NO.</span></strong>
                      </td>
                      <td width="50%"><span class="tdData colon">{{editData?.data?.BillNo}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">DATE</span></strong></td>
                      <td width="50%"><span class="tdData colon">{{editData?.data?.BillDate | date:'dd/MM/yyyy'
                          }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">PAYMENT TRM</span></strong>
                      </td>
                      <td width="50%"><span class="tdData colon">{{ledgerData?.days}}</span></td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">PLACE OF SUPPLY</span></strong>
                      </td>
                      <td width="50%"><span class="tdData colon">{{ledgerData?.state}}({{ledgerData?.stateCode}})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="40%"><strong><span class="tdData">GST NO.</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{ledgerData?.gstNo}}</span></td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">STATE</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{ledgerData?.state}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">STATE CODE</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{ledgerData?.stateCode}}</span></td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">PAN NO.</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.pan}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- first table box ends -->
              <!-- second table box starts -->
              <tr class="outerTable">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td><span class="tdData">Details of Receiver(Billed To)</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.name}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.address}}, {{ledgerData?.city}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.state}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.stateCode}}</span></td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody style="display: block;">
                    <tr>
                      <td><span class="tdData">Details of Consignee(Shipped To)</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.name}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.address}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.state}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.stateCode}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- second table box ends -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="3%" height="20" align="center" class="borderLeft borderBottom">
                        <strong>NO.</strong>
                      </td>
                      <td width="30%" align="center" class="borderLeft borderBottom">
                        <strong>Item</strong>
                      </td>
                      <td width="9%" align="center" class="borderLeft borderBottom">
                        <strong>HSN/SAC</strong>
                      </td>
                      <td width="8%" align="center" class="borderLeft borderBottom">
                        <strong>GST%</strong>
                      </td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Qty
                        </strong></td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Unit
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom"><strong>Rate
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom">
                        <strong>Amount</strong>
                      </td>
                    </tr>
                    <tr class="tdData" *ngFor="let item of saleData; let i =index">
                      <td height="30" align="center" class="borderLeft">{{i+1}}.</td>
                      <td style="width: 220px;display: block;" align="start" class="borderLeft">
                        {{item.selectItemLabel}} <br><span
                          style="font-size: 11px; display: block; word-wrap: break-word; width: 100%;">{{item?.description}}</span></td>
                      <td align="center" class="borderLeft">{{item?.hsnLabel}}</td>
                      <td align="center" class="borderLeft">{{item.igstLabel}}</td>
                      <td align="center" class="borderLeft">{{item.quantityLabel}}
                      </td>
                      <td align="center" class="borderLeft">{{item.unit}}</td>
                      <td align="center" class="borderLeft">{{item.rateLabel}}</td>
                      <td align="center" class="borderLeft">{{item.amountLabel}}
                      </td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </tr>


              <!-- third table box starts -->
              <tr class="outerTable">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr class="thirdTableData">
                      <td><strong><span class="tdData">BILL</span></strong>
                      </td>
                      <td><span class="tdData colon">{{ledgerData?.billBy}}</span>
                      </td>
                    </tr>
                    <tr class="thirdTableData">
                      <td><strong><span class="tdData">SGST</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.Sgst}}</span>
                      </td>
                    </tr>
                    <tr class="thirdTableData">
                      <td class="borderBottom"><strong><span class="tdData">CGST</span></strong>
                      </td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.Cgst}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">Bank Name</span></strong></td>
                      <td><span class="tdData colon">{{bank_name}}</span></td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">Branch</span></strong></td>
                      <td><span class="tdData colon">{{branch}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">A/C No</span></strong></td>
                      <td><span class="tdData colon">{{account_no}}</span></td>
                    </tr>
                    <tr>
                      <td class="borderBottom"><strong><span class="tdData">IFSC</span></strong>
                      </td>
                      <td class="borderBottom"><span class="tdData colon">{{IFSC_code}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="lineHeight"><strong><span class="tdData">
                            1)Tax is Payble On
                            Reverse Charge Basis <br> 2) Subject to Pune Jurisdiction only
                            <br> 3) Once Goods Sold Will not be taken Back</span></strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr height="30">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">CGST</span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData colon">{{editData?.data?.Cgst}}</span>
                      </td>
                    </tr>
                    <tr height="30">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">SGST</span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData colon">{{editData?.data?.Sgst}}</span>
                      </td>
                    </tr>
                    <tr height="30">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">IGST</span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData colon">{{editData?.data?.Igst}}</span>
                      </td>
                    </tr>
                    <tr height="48">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">
                          </span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData"></span></td>
                    </tr>
                    <tr height="48">
                      <td width="40%"><strong><span class="tdData">Grand Total</span></strong>
                      </td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.BillAmount}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- third table box ends -->


              <!-- fourth table box starts -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="45%" height="100" align="left" class="borderLeft lineHeight">
                        <strong>I/We hereby certify that my/our registration certificate under
                          the goods and sercive tax act, 2017 is in force on the date on which
                          the sale of goods specified in this tax invoice made by me / us and
                          it will shall be accounted for in the turnover of sales while fiting
                          and retum and the due tax If any, payable on the sale has been paid
                          or shall be paid.</strong>
                      </td>
                      <td width="20%" align="center" class="borderLeft">
                        <strong>Receiver's Sign</strong>
                      </td>
                      <td width="35%" align="center" class="borderLeft">
                        <strong>For Anu Computer Consultancy <br><br>Authorised Signature</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- fourth table box ends -->

              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <!--++++++++++++++++++++++++++ format 0ne ends +++++++++++++++++++++++++++-->

  <!--++++++++++++++++++++++++++ format two starts +++++++++++++++++++++++++++-->
  <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb" *ngIf="selectedFormat =='format2'">
    <tbody>
      <!-- title -->
      <tr>
        <td height="20" colspan="4" align="center" class="txt">TAX INVOICE</td>
      </tr>
      <tr height="65">
        <!-- qr code starts -->
        <td align="start" class="txt" id="company_logo">
          <img src="#" alt="company_logo">
        </td>
        <td align="start" class="txt cName">{{companyName}}<br><span class="tdData">{{companyAddress}}</span>
        </td>

        <table width="100%" border="0" class="borderLeft_light" cellspacing="0" cellpadding="0">
          <tbody>
            <th width="100%" class="table_head">Original</th>
            <tr>
              <td width="30%"><strong><span class="tdData">PAN NO.</span></strong>
              </td>
              <td><span class="tdData colon">{{editData?.data?.pan}}</span>
              </td>
            </tr>
            <tr>
              <td width="30%"><strong><span class="tdData">GSTin No.</span></strong></td>
              <td><span class="tdData colon">{{ledgerData?.gstNo}}</span>
              </td>
            </tr>
            <tr>
              <td width="30%"><strong><span class="tdData">State</span></strong>
              </td>
              <td><span class="tdData colon">{{ledgerData?.state}}</span></td>
            </tr>
            <tr>
              <td width="30%"><strong><span class="tdData">State Code</span></strong>
              </td>
              <td><span class="tdData colon">{{ledgerData?.stateCode}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </tr>
      <tr>
        <!-- <td width="3%">&nbsp;</td> -->
        <td colspan="12">
          <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
            <tbody>
              <!-- first table box starts -->
              <tr class="outerTable bigLineHeight">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="50%"><strong><span class="tdData">L.R. No</span></strong>
                      </td>
                      <td width="50%"><span class="tdData colon">{{editData?.data?.BillNo}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">Transport Name</span></strong></td>
                      <td width="50%"><span class="tdData colon">{{editData?.data?.Transport}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">Date & Time</span></strong>
                      </td>
                      <td width="50%"><span class="tdData colon">{{editData?.data?.BillDate | date:'dd/MM/yyyy'}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">Place of Supply</span></strong>
                      </td>
                      <td width="50%"><span class="tdData colon">{{ledgerData?.state}}({{ledgerData?.stateCode}})</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">Payment Due</span></strong>
                      </td>
                      <td width="50%"><span class="tdData colon">{{editData?.data?.Due}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="40%"><strong><span class="tdData">Invoice No</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.BillNo}}</span></td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">Date</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.BillDate | date:'dd/MM/yyyy'}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">P.O. No</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.Pono}}</span></td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">P.O. Date</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.Podate | date:'dd/MM/yyyy'}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- first table box ends -->
              <!-- second table box starts -->
              <tr class="outerTable">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody style="display: grid;">
                    <tr width="100%">
                      <td><span class="tdData">Details of Receiver(Billed To)</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.name}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.address}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.state}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.stateCode}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">GSTin No.</span></strong></td>
                      <td><span class="tdData colon">{{ledgerData?.gstNo}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">Vendor Code</span></strong></td>
                      <td><span class="tdData colon">{{ledgerData?.code}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">PAN No.</span></strong></td>
                      <td><span class="tdData colon">{{editData?.data?.pan}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody style="display: block;">
                    <tr>
                      <td width="100%"><span class="tdData">Details of Consignee(Shipped To)</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.name}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.address}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.state}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.stateCode}}</span></td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">GSTin No.</span></strong></td>
                      <td width="50%"><span class="tdData colon">{{consigneeDetail?.gstNo}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- second table box ends -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="3%" height="20" align="center" class="borderLeft borderBottom">
                        <strong>NO.</strong>
                      </td>
                      <td width="30%" align="center" class="borderLeft borderBottom">
                        <strong>Item</strong>
                      </td>
                      <td width="9%" align="center" class="borderLeft borderBottom">
                        <strong>Package</strong>
                      </td>
                      <td width="9%" align="center" class="borderLeft borderBottom">
                        <strong>HSN/SAC</strong>
                      </td>
                      <td width="8%" align="center" class="borderLeft borderBottom">
                        <strong>GST%</strong>
                      </td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Qty
                        </strong></td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Unit
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom"><strong>Rate
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom">
                        <strong>Amount</strong>
                      </td>
                    </tr>
                    <tr class="tdData" *ngFor="let item of saleData; let i =index">
                      <td height="30" align="center" class="borderLeft">{{i+1}}.</td>
                      <td style="width: 220px;display: block;" align="start" class="borderLeft">
                        {{item?.selectItemLabel}} <br><span style="font-size: 11px; display: block; word-wrap: break-word; width: 100%;">{{item?.description}}</span></td>
                      <td align="center" class="borderLeft">{{item?.package}}</td>
                      <td align="center" class="borderLeft">{{item?.hsnLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.igstLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.quantityLabel}}
                      </td>
                      <td align="center" class="borderLeft">{{item?.unit}}</td>
                      <td align="center" class="borderLeft">{{item?.rateLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.amountLabel}}
                      </td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </tr>


              <!-- third table box starts -->
              <tr class="outerTable">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr class="thirdTableData">
                      <td><strong><span class="tdData">BILL</span></strong>
                      </td>
                      <td><span class="tdData colon">{{ledgerData?.billBy}}</span>
                      </td>
                    </tr>
                    <tr class="thirdTableData">
                      <td><strong><span class="tdData">SGST</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.Sgst}}</span>
                      </td>
                    </tr>
                    <tr class="thirdTableData">
                      <td class="borderBottom"><strong><span class="tdData">CGST</span></strong>
                      </td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.Cgst}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr height="25">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">CGST</span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData colon">{{editData?.data?.Cgst}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">SGST</span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData colon">{{editData?.data?.Sgst}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">
                          </span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData">{{editData?.data?.roundOff}}</span></td>
                    </tr>
                    <tr height="25">
                      <td width="40%"><strong><span class="tdData">Grand Total</span></strong>
                      </td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.BillAmount}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- third table box ends -->


              <!-- fourth table box starts -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="45%" height="100" align="left" class="borderLeft lineHeight">
                        <strong>I/We hereby certify that my/our registration certificate under
                          the goods and sercive tax act, 2017 is in force on the date on which
                          the sale of goods specified in this tax invoice made by me / us and
                          it will shall be accounted for in the turnover of sales while fiting
                          and retum and the due tax If any, payable on the sale has been paid
                          or shall be paid.</strong>
                      </td>
                      <td width="20%" align="center" class="borderLeft">
                        <strong>Receiver's Sign</strong>
                      </td>
                      <td width="35%" align="center" class="borderLeft">
                        <strong>For Anu Computer Consultancy <br><br>Authorised
                          Signature</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- fourth table box ends -->
              <!-- Fifth table box starts -->
              <tr>
                <!-- qr code starts -->
                <td align="start" class="txt" id="qrCode">
                  <qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode>
                </td>
                <!-- qr code ends -->

              </tr>
              <!-- fifth table box ends -->

              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <!--++++++++++++++++++++++++++ format two ends +++++++++++++++++++++++++++-->


  <!--++++++++++++++++++++++++++ format THREE STARTS +++++++++++++++++++++++++++-->
  <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb" *ngIf="selectedFormat =='format3'">
    <tbody>
      <tr height="65">
        <td id="company_logo" width="20%">
          <img src="assets/img/dummy_logo.png" alt="company_logo">
        </td>
        <td class="cName" width="40%">{{companyName}}<br><span class="tdData">{{companyAddress}}</span>
        </td>
        <td style="text-align: end;">
          <span class="tdData"><strong>Name</strong></span><span class="tdData colon">{{editData?.data?.name || 'Lorem
            ipsum'}}</span><br>
          <span class="tdData"><strong>Phone</strong></span><span class="tdData colon">{{editData?.data?.phone || 'Lorem
            ipsum'}}</span><br>
          <span class="tdData"><strong>E-mail</strong></span><span class="tdData colon">{{editData?.data?.email ||
            'Lorem ipsum'}}</span>
        </td>
      </tr>
      <tr>
        <td colspan="12">
      <tr class="outerTable bigLineHeight">
        <table width="33%" border="0" class="sub-title-box" cellspacing="0" cellpadding="0">
          <td width="20%"><strong><span class="tdData">GSTIN</span></strong>
          </td>
          <td width="80%"><span class="tdData colon">{{editData?.data?.gstIn}}</span>
          </td>
        </table>
        <table width="33%" border="0" class="sub-title-box" cellspacing="0" cellpadding="0">
          <td height="20" colspan="4" align="center" class="txt">TAX INVOICE</td>
        </table>
        <table width="33%" style="text-align: right;" border="0" class="sub-title-box" cellspacing="0" cellpadding="0">
          <td width="100%"><strong><span class="tdData">ORIGINAL FOR RECIPIENT</span></strong>
          </td>
        </table>
      </tr>
      </td>
      </tr>


      <tr>
        <td colspan="12">
          <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
            <tbody>
              <!-- first table box starts -->
              <tr class="outerTable bigLineHeight">
                <table width="40%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="20%"><strong><span class="tdData">M/S</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.name}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">Address</span></strong></td>
                      <td width="80%"><span class="tdData colon long">{{ledgerData?.address}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">PHONE</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.mobile}}</span></td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">GSTIN</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.gstNo}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">Place of Supply</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.state}}({{ledgerData?.stateCode}})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="60%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody style="display: block;">
                    <tr width="100%">
                      <td width="20%"><strong><span class="tdData">Invoice No.</span></strong></td>
                      <td width="30%"><span class="tdData colon">{{editData?.data?.BillNo}}</span></td>
                      <td width="20%"><strong><span class="tdData">Invoice Date</span></strong></td>
                      <td width="30%"><span class="tdData colon">{{editData?.data?.BillDate | date:'dd/MM/yyyy'}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">Due Date</span></strong></td>
                      <td width="30%"><span class="tdData colon">{{ledgerData?.billBy | date:'dd/MM/yyyy'}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- first table box ends -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="3%" height="20" align="center" class="borderLeft borderBottom">
                        <strong>Sr.No</strong>
                      </td>
                      <td width="30%" align="center" class="borderLeft borderBottom">
                        <strong>Description of Goods</strong>
                      </td>
                      <td width="9%" align="center" class="borderLeft borderBottom">
                        <strong>HSN</strong>
                      </td>
                      <td width="8%" align="center" class="borderLeft borderBottom">
                        <strong>GST%</strong>
                      </td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Qty</strong></td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Rate
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom"><strong>Unit
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom">
                        <strong>Amount</strong>
                      </td>
                    </tr>
                    <tr class="tdData" *ngFor="let item of saleData; let i =index">
                      <td height="30" align="center" class="borderLeft">{{i+1}}.</td>
                      <td style="width: 220px;display: block;" align="start" class="borderLeft">
                        {{item.selectItemLabel}} <br><span style="font-size: 11px; display: block; word-wrap: break-word; width: 100%;">{{item?.description}}</span></td>
                      <td align="center" class="borderLeft">{{item?.hsnLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.igstLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.quantityLabel}}
                      </td>
                      <td align="center" class="borderLeft">{{item?.rateLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.unit}}</td>
                      <td align="center" class="borderLeft">{{item?.amountLabel}}
                      </td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>

                    </tr>

                  </tbody>
                </table>
              </tr>


              <!-- third table box starts -->
              <tr class="outerTable">
                <table width="60%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">Amount in word</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.BillAmount}}</span>
                      </td>
                    </tr><br>
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">Bank name</span></strong>
                      </td>
                      <td><span class="tdData colon">{{bank_name}}</span>
                      </td>
                    </tr>
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">Branch</span></strong>
                      </td>
                      <td><span class="tdData colon">{{branch}}</span>
                      </td>
                    </tr>
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">Account No</span></strong>
                      </td>
                      <td><span class="tdData colon">{{account_no}}</span>
                      </td>
                    </tr>
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">IFSC Code</span></strong>
                      </td>
                      <td><span class="tdData colon">{{IFSC_code}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="40%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">Total</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.BillAmount}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">CGST</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.Cgst}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">SGST</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.Sgst}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">IGST</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.Igst}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">
                            Grand Total</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.BillAmount}}</span></td>
                    </tr>
                    <tr height="25">
                      <td width="30%"><strong><span class="tdData">E. & O.E.</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.BillAmount}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- third table box ends -->


              <!-- fourth table box starts -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="45%" height="100" align="left" class="borderLeft lineHeight">
                        <strong>I/We hereby certify that my/our registration certificate under
                          the goods and sercive tax act, 2017 is in force on the date on which
                          the sale of goods specified in this tax invoice made by me / us and
                          it will shall be accounted for in the turnover of sales while fiting
                          and retum and the due tax If any, payable on the sale has been paid
                          or shall be paid.</strong>
                      </td>
                      <td width="20%" align="center" class="borderLeft">
                        <strong>Receiver's Sign</strong>
                      </td>
                      <td width="35%" align="center" class="borderLeft">
                        <strong>For Anu Computer Consultancy <br><br>Authorised
                          Signature</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- fourth table box ends -->
              <!-- Fifth table box starts -->
              <tr>
                <!-- qr code starts -->
                <td align="start" class="txt" id="qrCode">
                  <!--qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode-->
                </td>
                <!-- qr code ends -->

              </tr>
              <!-- fifth table box ends -->

              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <!--++++++++++++++++++++++++++ format THREE ENDS +++++++++++++++++++++++++++-->


  <!--++++++++++++++++++++++++++ format FOUR STARTS +++++++++++++++++++++++++++-->
  <table border="0" cellpadding="0" cellspacing="0" class="tb" *ngIf="selectedFormat =='format4'">
    <tbody>
      <tr height="65">
        <td id="company_logo" width="20%">
          <img src="assets/img/dummy_logo.png" alt="company_logo">
        </td>
        <td class="cName">{{companyName}}<br><span class="tdData">{{companyAddress}}</span>
        </td>

        <table>
          <tbody>
            <tr>
              <td width="30%"><strong><span class="tdData">Name</span></strong>
              </td>
              <td width="70%"><span class="tdData colon">{{editData?.data?.name}}</span>
              </td>
            </tr>
            <tr>
              <td width="30%"><strong><span class="tdData">Phone</span></strong></td>
              <td width="70%"><span class="tdData colon">{{editData?.data?.phone}}</span>
              </td>
            </tr>
            <tr>
              <td width="20%"><strong><span class="tdData">Email</span></strong>
              </td>
              <td width="80%"><span class="tdData colon">{{editData?.data?.email}}</span></td>
            </tr>
          </tbody>
        </table>
      </tr>
      <tr>
        <td colspan="12">
      <tr class="outerTable bigLineHeight">
        <table width="33%" border="0" class="sub-title-box" cellspacing="0" cellpadding="0">
          <td width="20%"><strong><span class="tdData">GSTIN</span></strong>
          </td>
          <td width="80%"><span class="tdData colon">{{editData?.data?.gstIn}}</span>
          </td>
        </table>
        <table width="33%" border="0" class="sub-title-box" cellspacing="0" cellpadding="0">
          <td height="20" colspan="4" align="center" class="txt">TAX INVOICE</td>
        </table>
        <table width="33%" style="text-align: right;" border="0" class="sub-title-box" cellspacing="0" cellpadding="0">
          <td width="100%"><strong><span class="tdData">ORIGINAL FOR RECIPIENT</span></strong>
          </td>
        </table>
      </tr>
      </td>
      </tr>

      <tr>
        <td colspan="12">
          <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
            <tbody>
              <!-- first table box starts -->
              <tr class="outerTable bigLineHeight">
                <table width="40%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td colspan="2" style="border-bottom: 1px solid #999999;">Customer Detail</td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">Name</span></strong></td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.name}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">Address</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.address}}</span></td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">PHONE</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.contactNo}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">GSTIN</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.gstNo}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">Place of
                            Supply</span></strong>
                      </td>
                      <td width="80%"><span class="tdData colon">{{ledgerData?.state}}({{ledgerData?.stateCode}})</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="60%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody style="display: block;">
                    <tr width="100%">
                      <td width="20%"><strong><span class="tdData">Invoice No.</span></strong></td>
                      <td width="30%"><span class="tdData colon">{{editData?.data?.BillNo}}</span></td>
                      <td width="20%"><strong><span class="tdData">Invoice Date</span></strong></td>
                      <td width="30%"><span class="tdData colon">{{editData?.data?.BillDate | date:'dd/MM/yyyy'}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="20%"><strong><span class="tdData">Due Date</span></strong></td>
                      <td width="30%"><span class="tdData colon">{{ledgerData?.billBy | date:'dd/MM/yyyy'}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- first table box ends -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="3%" height="30" align="center" class="borderLeft borderBottom"><strong>S no.</strong>
                      </td>
                      <td width="30%" align="center" class="borderLeft borderBottom"><strong>Name of Product</strong>
                      </td>
                      <td width="9%" align="center" class="borderLeft borderBottom"><strong>HSN/SAC</strong>
                      </td>
                      <td width="9%" align="center" class="borderLeft borderBottom"><strong>Qty</strong>
                      </td>
                      <td width="8%" align="center" class="borderLeft borderBottom"><strong>GST %</strong>
                      </td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Dis %</strong></td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Taxable</strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom"><strong>CGST %</strong></td>
                      <td width="7.5%" align="center" class="borderLeft borderBottom"><strong>SGST</strong>
                      </td>
                      <td width="7.5%" align="center" class="borderLeft borderBottom"><strong>Total</strong>
                      </td>

                    </tr>
                    <tr class="tdData" *ngFor="let item of saleData; let i =index">
                      <td height="30" align="center" class="borderLeft">{{i+1}}.</td>
                      <td style="width: 220px; display: block;" align="start" class="borderLeft">
                        {{item.selectItemLabel}} <br><span style="font-size: 11px; display: block; word-wrap: break-word; width: 100%;">{{item?.description}}</span></td>
                      <td align="center" class="borderLeft">{{item?.hsnLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.quantityLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.igstLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.discount}}</td>
                      <td align="center" class="borderLeft">{{item?.taxable}}</td>
                      <td align="center" class="borderLeft">{{item?.CgstLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.SgstLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.total}}</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- third table box starts -->
              <tr class="outerTable">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody style="display:block">
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">Total in words</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.BillAmount}}</span>
                      </td>
                    </tr><br>
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">SGST</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.Sgst}}</span>
                      </td>
                    </tr>
                    <tr class="borderBottom">
                      <td width="25%"><strong><span class="tdData">CGST</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.Cgst}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">Taxable Amount</span></strong>
                      </td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.SubTot}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">CGST</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.Cgst}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">SGST</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.Sgst}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">Total Tax</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.total_tax}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="30%" class="borderBottom"><strong><span class="tdData">Total Amount After
                            Tax</span></strong></td>
                      <td class="borderBottom"><span class="tdData colon">{{editData?.data?.BillAmount}}</span></td>
                    </tr>
                    <tr height="25">
                      <td width="30%"><strong><span class="tdData">E. & O.E.</span></strong>
                      </td>
                      <td><span class="tdData colon">{{editData?.data?.BillAmount}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- third table box ends -->


              <!-- fourth table box starts -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="45%" align="left" class="borderLeft lineHeight">
                        <strong>Subject to our home Jurisdiction.
                          Our Responsibility Ceases as soon as goods leaves our Premises.
                          Goods once sold will not taken back.
                          Delivery Ex-Premises.</strong>
                      </td>
                      <td width="20%" align="center" class="borderLeft">
                        <strong>Receiver's Sign</strong>
                      </td>
                      <td width="35%" align="center" class="borderLeft">
                        <strong>For Anu Computer Consultancy <br><br>Authorised
                          Signature</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- fourth table box ends -->
              <!-- Fifth table box starts -->
              <tr>
                <!-- qr code starts -->
                <td align="start" class="txt" id="qrCode">
                  <!--qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode-->
                </td>
                <!-- qr code ends -->

              </tr>
              <!-- fifth table box ends -->

              <tr>
                <td colspan="4">&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <!--++++++++++++++++++++++++++ format FOUR ENDS +++++++++++++++++++++++++++-->


  <!--++++++++++++++++++++++++++ format FIVE STARTS +++++++++++++++++++++++++++-->
  <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb" *ngIf="selectedFormat =='format5'">
    <tbody>
      <!-- title -->
      <tr height="65">
        <!-- qr code starts -->
        <td align="center" class="txt cName">{{companyName}}<br><span class="tdData">{{companyAddress}}</span>
        </td>
      <tr>
        <td height="20" colspan="4" align="center" class="txt">TAX INVOICE</td>
      </tr>

      <tr>
        <!-- <td width="3%">&nbsp;</td> -->
        <td colspan="12">
          <table width="100%" border="0" cellpadding="0" cellspacing="0" class="tb1">
            <tbody>
              <!-- first table box starts -->
              <tr class="outerTable bigLineHeight">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td><strong><span class="tdData">To,</span></strong></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.name}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.address}}, {{ledgerData?.city}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.state}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{ledgerData?.stateCode}}</span></td>
                    </tr>
                  </tbody>
                </table>
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="40%"><strong><span class="tdData">BILL NO</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.BillNo}}</span></td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">Date</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.BillDate | date:'dd/MM/yyyy'
                          }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">Ch.No</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.stateCode}}</span></td>
                    </tr>
                    <tr>
                      <td width="40%"><strong><span class="tdData">P.O.No</span></strong></td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.Pono}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- first table box ends -->
              <!-- second table box starts -->

              <!-- second table box ends -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td width="3%" height="20" align="center" class="borderLeft borderBottom">
                        <strong>NO.</strong>
                      </td>
                      <td width="47%" align="center" class="borderLeft borderBottom">
                        <strong>Description</strong>
                      </td>

                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Qty
                        </strong></td>
                      <td width="10%" align="center" class="borderLeft borderBottom"><strong>Unit
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom"><strong>Rate
                        </strong></td>
                      <td width="15%" align="center" class="borderLeft borderBottom">
                        <strong>Amount</strong>
                      </td>
                    </tr>
                    <tr class="tdData" *ngFor="let item of saleData; let i =index">
                      <td height="30" align="center" class="borderLeft">{{i+1}}.</td>
                      <td style="width: 220px;display: block;" align="start" class="borderLeft">
                        {{item.selectItemLabel}} <br><span style="font-size: 11px; display: block; word-wrap: break-word; width: 100%;">{{item?.description}}</span></td>
                      <td align="center" class="borderLeft">{{item?.quantityLabel}}
                      </td>
                      <td align="center" class="borderLeft">{{item?.unit}}</td>
                      <td align="center" class="borderLeft">{{item?.rateLabel}}</td>
                      <td align="center" class="borderLeft">{{item?.amountLabel}}
                      </td>
                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>

                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>

                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>

                    </tr>
                    <tr class="tdData">
                      <td height="30" align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                      <td align="center" class="borderLeft">&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </tr>

              <tr class="outerTable">
                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr>
                      <td width="100%"><span class="tdData">Narration : {{editData?.data?.Narration}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.name}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.address}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.state}}</span></td>
                    </tr>
                    <tr>
                      <td><span class="tdData">{{consigneeDetail?.stateCode}}</span></td>
                    </tr>
                    <tr>
                      <td width="50%"><strong><span class="tdData">Amount in Words</span></strong></td>
                      <td width="50%"><span class="tdData colon">{{editData?.data?.BillAmount}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table width="50%" border="0" class="innerTable" cellspacing="0" cellpadding="0">
                  <tbody>
                    <tr height="25">
                      <td width="40%" class="borderBottom"><strong><span class="tdData">Sub Total</span></strong></td>
                      <td width="60%" class="borderBottom"><span class="tdData colon">{{editData?.data?.SubTot}}</span>
                      </td>
                    </tr>
                    <tr height="25">
                      <td width="40%"><strong><span class="tdData">Round Off</span></strong>
                      </td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.roundOff}}</span></td>
                    </tr>
                    <tr height="25">
                      <td width="40%"><strong><span class="tdData">Grand Total</span></strong>
                      </td>
                      <td width="60%"><span class="tdData colon">{{editData?.data?.BillAmount}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </tr>
              <!-- third table box ends -->


              <!-- fourth table box starts -->
              <tr>
                <table width="100%" cellpadding="0" cellspacing="0" class="tb2">
                  <tbody>
                    <tr class="tdData">
                      <td>
                    <tr>
                      <td><strong><span class="tdData">BANK NAME</span></strong>
                      </td>
                      <td><span class="tdData colon">{{bank_name}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">BRANCH</span></strong>
                      </td>
                      <td><span class="tdData colon">{{branch}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">ACCOUNT NO</span></strong>
                      </td>
                      <td><span class="tdData colon">{{account_no}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td><strong><span class="tdData">IFSC</span></strong>
                      </td>
                      <td><span class="tdData colon">{{IFSC_code}}</span>
                      </td>
                    </tr>
        </td>
        <td width="20%" align="center" class="borderLeft">
          <strong>Receiver's Sign</strong>
        </td>
        <td width="35%" align="center" class="borderLeft">
          <strong>For Anu Computer Consultancy <br><br>Authorised
            Signature</strong>
        </td>
      </tr>
    </tbody>
  </table>
  </tr>
  <!-- fourth table box ends -->
  <!-- Fifth table box starts -->
  <tr>
    <!-- qr code starts -->
    <td align="start" class="txt" id="qrCode">
      <qrcode [qrdata]="qrdata" [width]="100" [level]="'M'"></qrcode>
    </td>
    <!-- qr code ends -->

  </tr>
  <!-- fifth table box ends -->

  <tr>
    <td colspan="4">&nbsp;</td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  <!--++++++++++++++++++++++++++ format FIVE ENDS +++++++++++++++++++++++++++-->

</div>