import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers;
    if(headers.get('Authorization'))
      headers = headers.delete('Authorization');
    let token = sessionStorage.getItem('Token');
    if(token)
      headers = headers.append('Authorization', 'Bearer ' + (sessionStorage.getItem('Token') ?? ''));
    const customReq = request
                      .clone(
                        {
                          headers : headers
                        }
                      );
    return next.handle(customReq);
  }
}
