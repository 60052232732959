import { Component, HostListener, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/services/AppConstants';
import { MasterService } from 'src/services/master.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-client',
  templateUrl: './register-client.component.html',
  styleUrls: ['./register-client.component.css'],
})
export class RegisterClientComponent implements OnInit {
  sideNavStatus:boolean =false;
  cLedgerData: any;
  companyCode: any;
  selectCustomer: any;
  customerLogin: boolean;
  
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=="Escape"){
      this.dialog.closeAll();
      this.openComponent('dashboard/master')
    }
  }
  openComponent(component: string){
    this.router.navigate(['/'+component]);    
  }
  @ViewChild('newRegisterClientForm') newRegisterClientForm! : TemplateRef<any>;
  @Output() public emitData = new EventEmitter();
  showErrorAlert: boolean = false;
  isLoading: boolean = false;
  isLoading2: boolean = true;
  passData!: any;
  data!:any;
  clientData : any;
  isEditFlow: boolean = false;
  isDeleteFlow: boolean = false;
  forView:boolean=true;
  selectedLevel: any;
  code: any;
  checked=false;
  hide = true;

  registerClientForm= this.fb.group({
    cledgerLabel: ['', Validators.required],
    customerLabel: ['', Validators.required],
    fNameLabel: ['', Validators.required],
    lNameLabel: ['', Validators.required],
    mailLabel: ['', Validators.required],
    passwordLabel: ['', Validators.required],
  });
  registerEditClientForm= this.fb.group({
    companyNameLabel:['',Validators.required],
    mailLabel: ['', Validators.required],
  });
  registerChangePasswordClientForm= this.fb.group({
    changePasswordLabel: ['', Validators.required],
  });
  constructor(private fb: FormBuilder,private router: Router,
              private masterService: MasterService,
              private dialog: MatDialog,
              private appConstant:AppConstants,
              private toastr:ToastrService) { }

  ngOnInit() {
    this.getData()
    this.resetClientForm();
    this.resetClientEditForm();
    this.resetClientPasswordForm();
    this.getCledgerData();
    // console.clear()
    if(sessionStorage.getItem('customerLogin')=="true"){
      this.customerLogin=true;
    }else{
      this.customerLogin=false;
    }
  }
  
  getCledgerData() {
    this.isLoading = false;
    let listOfItems = ['Ledger'];
    let optionValList = ['1'];
    this.masterService.getCledgerData(listOfItems, optionValList).subscribe((response) => {
        this.cLedgerData = response;
      },
      (error=>{
        this.isLoading=true;
        this.toastr.error("Unable To Retrieve Data","Data Retrieve Error");
      })
      );
  }

  customerDropDown(){
    this.isLoading2=false;
    this.companyCode=this.cLedgerData.find(i => i.soCode === this.selectedLevel).companyCode;
    let listOfItems = ['Ledger'];
    let optionValList = ['1'];
    this.masterService.getSelectCustomer(listOfItems,optionValList,this.companyCode).subscribe((response)=>{
      this.selectCustomer=response.ledger1
      this.isLoading2=true
    })
  }

    getData(){
      this.isLoading = false;
      let listOfItems = ['Client'];
      let optionValList = ['0']
      this.masterService.getClient(listOfItems, optionValList).subscribe(response =>{
        this.toastr.info("Data Retrived Successfully","Data Retrive Message")
        this.clientData = response;
        this.passData=[this.appConstant.clientFormColumnNames, this.clientData,'clientForm',this.appConstant.clientExcelColumnNames];
        this.isLoading = true;
        },
      (error => {
        this.passData=[this.appConstant.clientFormColumnNames, false,'clientForm'];
        this.isLoading=true;
        this.toastr.error("Unable To Retrieve Data","Data Retrieve Error");
      })
      );
    }

  resetClientForm(){
    this.showErrorAlert = false;
      this.registerClientForm = this.fb.group({
        cledgerLabel: ['', Validators.required],
        customerLabel: ['', Validators.required],
        fNameLabel: ['', Validators.required],
        lNameLabel: ['', Validators.required],
        mailLabel: ['', Validators.required],
        passwordLabel: ['', Validators.required],
    });
  }
  resetClientEditForm(){
    this.showErrorAlert = false;
      this.registerEditClientForm = this.fb.group({
        companyNameLabel:['',Validators.required],
        mailLabel: ['', Validators.required],
    });
  }
  resetClientPasswordForm(){
    this.showErrorAlert = false;
      this.registerChangePasswordClientForm = this.fb.group({
        changePasswordLabel: ['', Validators.required],
    });
  }

  addClient(){
    if(this.registerClientForm.invalid && (this.registerEditClientForm.invalid || this.registerChangePasswordClientForm.invalid==this.checked)){
      this.showErrorAlert = true;
    }else{
      this.showErrorAlert = false;
      this.isLoading=false
      if(this.isEditFlow){
        const generatedData = {
          Serial:this.code,
          Email:this.registerEditClientForm.controls['mailLabel'].value,
          LedgerName:this.registerEditClientForm.controls['companyNameLabel'].value,
          isPasswordChanged:this.checked,
          Password:!this.checked ? '' : this.registerChangePasswordClientForm.controls['changePasswordLabel'].value,
          ServerVal:sessionStorage.getItem('ServerVal'),
        }
        this.dialog.closeAll();
        this.masterService.updateClient(generatedData).subscribe(response => {
          this.getData();
          this.toastr.success("Data Updated Successful","Update Success", {
            closeButton: true,
            
          });
        },
        (error=>{
          this.isLoading=true;
          this.toastr.error("Unable To Update Data","Update Error", {
            closeButton: true,
            
          });
        })
        );
      }else{
        const generatedData = {
          Serial:sessionStorage.getItem('Serial'),
          CompanyCode:this.companyCode,
          LedAcode:this.registerClientForm.controls['customerLabel'].value,
          SOCodeofSelectedLedger:this.registerClientForm.controls['cledgerLabel'].value,
          Email:this.registerClientForm.controls['mailLabel'].value,
          Password:this.registerClientForm.controls['passwordLabel'].value,
          FirstName: this.registerClientForm.controls['fNameLabel'].value,
          LastName:this.registerClientForm.controls['lNameLabel'].value,
          ServerVal:sessionStorage.getItem('ServerVal'),
        }
        this.dialog.closeAll();
        this.masterService.addNewClient(generatedData).subscribe(response => {
          this.getData();
            this.toastr.success("Data Submitted Successful","Add Success", {
              closeButton: true,
              
            })
          },
          (error=>{
            this.isLoading=true;
            this.toastr.error("Unable To Submit New Data","Add Error", {
              closeButton: true,
              
            });
          })
          );
      }
      }
  }

  addNewClient(rowData?: any){
    this.resetClientForm();
    if(rowData === null || rowData === undefined){
      this.code='';
      this.forView=true;
      this.checked=false;
      this.isEditFlow=false;
        let dialogRef = this.dialog.open(this.newRegisterClientForm,{
          disableClose: true,
          width: '60%'
        });
      dialogRef.afterClosed().subscribe(result => {});
    }else if((rowData != null || rowData != undefined) && rowData.editFlow){
      this.isEditFlow =  rowData.editFlow ? true : false;
      this.forView=true
      this.checked=false;
      this.patchRegisterClientForm(rowData.row);
      this.code=rowData.row.sr_No;
        let dialogRef = this.dialog.open(this.newRegisterClientForm,{
          disableClose: true,
          width: '60%'
        });
      dialogRef.afterClosed().subscribe(result => {});
    } else if((rowData != null || rowData != undefined) && rowData.deleteFlow){
      this.isDeleteFlow = rowData.deleteFlow ? true : false;
      this.isLoading=false;
      const generatedData = {
        SerialNo:rowData.row.sr_No
      }
      this.masterService.deleteClient(generatedData).subscribe(response => {
        this.isLoading=true;
        this.toastr.success("Deleted Successfully", "Delete Success", {
          closeButton: true,
          
        });
      },
      (error=>{
        this.isLoading=true;
        this.toastr.error("Unable to Delete Data", "Delete Unsuccess",{
          closeButton: true,
          
        });
      })
      );
    }else if((rowData != null || rowData != undefined) && rowData.viewFlow){
      this.forView=false
      this.patchRegisterClientForm(rowData.row);
      this.code=rowData.row.code;
        let dialogRef = this.dialog.open(this.newRegisterClientForm,{
          disableClose: true,
          minWidth: '60%'
      });
        dialogRef.afterClosed().subscribe(result => {});
    }
  }

  patchRegisterClientForm(row){
    this.registerEditClientForm.patchValue({
      'companyNameLabel':row.urLedgerCodeName,
      'mailLabel': row.emailId,
    });
}
}
