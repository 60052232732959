export class MostLeastSoldItemData {

  static generateDataForTable(data: any): any {

    const soldItem : { name: string, qtySold: number }|{} = {}

    for (let i = 0; i < data?.length; i++) {
      var name = data[i].name;
      if (soldItem[name] === undefined) {
        soldItem[name] = data[i].qtySold;
      } else {
        soldItem[name] += data[i].qtySold;
      }
    }

    const result : { name: string, qtySold: number }[] = [];
    for (const item in soldItem) {
      result.push({ name: item, qtySold: soldItem[item] });
    }
    return result;
  }

}
