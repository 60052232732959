<mat-progress-bar mode="indeterminate" *ngIf="!isLoading"></mat-progress-bar>
<div *ngIf="isLoading">
    <div class="container" fxLayoutAlign="center center" fxFlexFill>
        <mat-card fxFlex='25'>
            <mat-toolbar color="primary">Register Page</mat-toolbar>
            <form fxLayoutAlign="Strech" fxLayout="column" class="form-class" [formGroup]="registerForm">
                <p style="color: red;">*All fields are required</p>
                <!-- <mat-form-field>
                <input matInput placeholder="Serial"
                    formControlName="serialLabel" id="serialLabel">
            </mat-form-field> -->

                <mat-form-field>
                    <input matInput placeholder="Company Name" formControlName="cNameLabel" id="cNameLabel">
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="mailLabel" id="mailLabel">
                </mat-form-field>

                <mat-form-field>
                    <input type="password" matInput placeholder="Password" formControlName="passwordLabel"
                        id="passwordLabel">
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="First Name" formControlName="fNameLabel" id="fNameLabel">
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="Last Name" formControlName="lNameLabel" id="lNameLabel">
                </mat-form-field>

                <button mat-raised-button color="primary" type="submit" (click)="register('login')">Register</button>
            </form>
        </mat-card>
    </div>
</div>