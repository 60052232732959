import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-in-progress-box',
  templateUrl: './in-progress-box.component.html',
  styleUrls: ['./in-progress-box.component.css']
})
export class InProgressBoxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
