<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
  integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous">
<script src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
  integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
  integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
<mat-dialog-content>
    <mat-icon class="cross" mat-dialog-close>close</mat-icon>
    <div class="container">
        <h1 mat-dialog-title class="companytitle">Setting</h1>
    </div>
    <mat-card>
        <mat-card-content>
            <form class="mainForm">
                <div class="form-group row">
                    <div class="col-sm-4">
                        <label>Font configuration</label>
                    </div>
                    <div class="col-sm-8">
                        <input class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-4">
                        <label>Printer Name</label>
                    </div>
                    <div class="col-sm-8">
                        <input class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-4">
                        <label> Page Size</label>
                    </div>
                    <div class="col-sm-8">
                        <input class="form-control">
                    </div>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>